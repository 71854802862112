import axios from "axios";
import Cookies from 'js-cookie';

import {mobXStore} from "../../store";

// const debug = false;

const version = "2025-01-23-002";

const endPoints = {
    "userProfile": "/user_profile",
    "userLogin": "/user_login",
    "userSendOTP": "/user_send_otp",
    "getStrategy": "/get_strategy",
    "getUserProfile": "/user_profile",
    "changeDiscordName": "/change_discord_name",
    "changeTelegramName": "/change_telegram_name",
    "changeTelegramAI_ID": "/change_telegram_ai_id",
    "createExchangeConnector": "/create_exchange_connector",
    "getUserExchange": "/get_user_exchange",
    "deleteUserExchange": "/delete_user_exchange",
    "changeNameExchangeConnector": "/change_name_exchange_connector",
    "changeActiveExchangeConnector": "/change_active_exchange_connector",
    "setLimitExchangeConnector": "/set_limit_exchange_connector",
    "getUsersWebhooks": "/get_users_webhooks",
    "changeActiveWebhookConnector": "/change_active_webhook_connector",
    "deleteUserWebhook": "/delete_user_webhook",
    "createNewWebhook": "/create_new_webhook",
    "updateWebhook": "/update_webhook",
    "getBots": "/get_bots",
    "addNewBot": "/add_new_bot",
    "updateBot": "/update_bot",
    "deleteBot": "/delete_bot",
    "changeActiveBot": "/change_active_bot",
    "getNewBotsWebhook": "/get_new_bots_webhook",
    "addNewBotWebhook": "/add_new_bot_webhook",
    "updateBotWebhook": "/update_bot_webhook",
    "deleteBotWebhook": "/delete_bot_webhook",
    "changeActiveBotWebhook": "/change_active_bot_webhook",
    "getStrategyStats": "/get_strategy_stats",
    "getAllStrategiesStats": "/get_all_strategies_stats",
    "getTradesByStrategyID": "/get_trades_by_strategy_id",
    "getLogsSignals": "/get_logs_signals",
    "getLogsSignalsWebhook": "/get_logs_signals_wh",
    "getEquity": "/get_equity",
    "getEquityStats": "/get_equity_stats",
    "getMaxOpenTrades": "/get_max_open_trades",
    "getConnectedStrategies": "/get_connected_strategies",
    "getNotConnectedStrategies": "/get_not_connected_strategies",
}

const connector_test = {
    "testConnection": "/test_connection",
}

export default class API {
    constructor() {
        this.api_token = null;
        this.client = null;
        this.api_url = process.env.REACT_APP_API_SERVER;
        this.webhook_url = process.env.REACT_APP_WEBHOOK_SERVER;
        // console.log(this.api_url);
    }

    init = () => {
        this.api_token = Cookies.get("ACCESS_TOKEN");

        let headers = {
            Accept: "application/json",
        };

        this.client = axios.create({
            baseURL: this.api_url,
            timeout: 31000,
            headers: headers,
        });

        return this.client;
    };

    initWebHook = () => {
        let headers = {
            Accept: "application/json",
        };

        this.client = axios.create({
            baseURL: this.webhook_url,
            timeout: 31000,
            headers: headers,
        });

        return this.client;
    };

    async_sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    logout = () => {
        Cookies.set('ACCESS_TOKEN', '');
        Cookies.remove('ACCESS_TOKEN');
        mobXStore.setKey('login', false);
        mobXStore.setKey('demo_mode', false);
        mobXStore.setKey('free_mode', false);
        mobXStore.delete();
        this.api_token = '';
    };

    userSendOTP = async (email) => {

        this.logout();

        return this.init().post(endPoints.userSendOTP, {email: email}).then((response) => {
            // console.log(response);
            if (response.data.result === false) {
                // console.log('User not found: ' + email);
                return {result: false, error: 'User not found'};
            }

            return {result: true};
        }).catch((error) => {
            // console.log(error.message);
            return {result: false, error: error.message};
        });
    };

    userLogin = async (email, otp, is_demo = false) => {

        return await this.init().post(endPoints.userLogin, {
            email: email,
            otp: otp,
            v: version
        }).then((response) => {
            // console.log(response);
            if (response.data.login === 0 && is_demo === false) {
                return {result: false, error: 'Incorrect login details'};
            }

            Cookies.set('ACCESS_TOKEN', response.data.token);
            return {result: true};
        }).catch((error) => {
            // console.log(error.message);
            return {result: false, error: error.message};
        });
    };

    getStrategy = async (data) => {

        return this.init().post(endPoints.getStrategy, {
            data: data,
            token: this.api_token,
            v: version
        }).then((response) => {
            // console.log(response);
            if (response.data.login === 0 || response.data.token !== this.api_token || response.data.result === false) {
                // this.logout();
                return {result: false, error: 'User Session expired', action: 'logout'};
            }

            return {result: true, data: response.data.strategy_list}
        }).catch((error) => {
            console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    };

    getUserProfile = async (data) => {

        return this.init().post(endPoints.getUserProfile, {
            data: data,
            token: this.api_token,
            v: version
        }).then((response) => {
            // console.log(response);
            if (response.data.login === 0 || response.data.token !== this.api_token || response.data.result === false) {
                // this.logout();
                return {result: false, error: 'User Session expired', action: 'logout'};
            }

            return {result: true, data: response.data.profile}
        }).catch((error) => {
            // console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    };

    changeDiscordName = async (data) => {
        return this.init().post(endPoints.changeDiscordName, {
            data: data,
            token: this.api_token,
            v: version
        }).then((response) => {
            // console.log(response);
            if (response.data.login === 0 || response.data.token !== this.api_token) {
                // this.logout();
                return {result: false, error: 'User Session expired', action: 'logout'};
            }

            return {result: true, data: {}}
        }).catch((error) => {
            // console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    }

    changeTelegramName = async (data) => {
        return this.init().post(endPoints.changeTelegramName, {
            data: data,
            token: this.api_token,
            v: version
        }).then((response) => {
            // console.log(response);
            if (response.data.login === 0 || response.data.token !== this.api_token) {
                // this.logout();
                return {result: false, error: 'User Session expired', action: 'logout'};
            }

            return {result: true, data: {}}
        }).catch((error) => {
            console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    }

    changeTelegramAI_ID = async (data) => {
        return this.init().post(endPoints.changeTelegramAI_ID, {
            data: data,
            token: this.api_token,
            v: version
        }).then((response) => {
            // console.log(response);
            if (response.data.login === 0 || response.data.token !== this.api_token) {
                // this.logout();
                return {result: false, error: 'User Session expired', action: 'logout'};
            }

            return {result: true, data: {}}
        }).catch((error) => {
            console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    }

    getUserExchange = async (data) => {
        return this.init().post(endPoints.getUserExchange, {
            data: data,
            token: this.api_token,
            v: version
        }).then((response) => {
            // console.log(response);
            if (response.data.login === 0 || response.data.token !== this.api_token || response.data.result === false) {
                // this.logout();
                return {result: false, error: 'User Session expired', action: 'logout'};
            }

            return {result: true, data: response.data.user_exchange}
        }).catch((error) => {
            console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    }

    deleteUserExchange = async (data) => {
        return this.init().post(endPoints.deleteUserExchange, {
            data: data,
            token: this.api_token,
            v: version
        }).then((response) => {
            // console.log(response);
            if (response.data.login === 0 || response.data.token !== this.api_token) {
                // this.logout();
                return {result: false, error: 'User Session expired', action: 'logout'};
            }

            return {result: true, data: {}}
        }).catch((error) => {
            console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    }

    changeNameExchangeConnector = async (data) => {
        // console.log(data);
        return this.init().post(endPoints.changeNameExchangeConnector, {
            data: data,
            token: this.api_token,
            v: version
        }).then((response) => {
            // console.log(response);
            if (response.data.login === 0 || response.data.token !== this.api_token) {
                // this.logout();
                return {result: false, error: 'User Session expired', action: 'logout'};
            }

            return {result: true, data: {}}
        }).catch((error) => {
            console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    }

    changeActiveExchangeConnector = async (data) => {
        return this.init().post(endPoints.changeActiveExchangeConnector, {
            data: data,
            token: this.api_token,
            v: version
        }).then((response) => {
            // console.log(response);
            if (response.data.login === 0 || response.data.token !== this.api_token) {
                // this.logout();
                return {result: false, error: 'User Session expired', action: 'logout'};
            }

            return {result: true, data: {}}
        }).catch((error) => {
            console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    }

    setLimitExchangeConnector = async (data) => {
        return this.init().post(endPoints.setLimitExchangeConnector, {
            data: data,
            token: this.api_token,
            v: version
        }).then((response) => {
            // console.log(response);
            if (response.data.login === 0 || response.data.token !== this.api_token) {
                return {result: false, error: 'User Session expired', action: 'logout'};
            }

            return {result: true, data: {}}
        }).catch((error) => {
            console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    }

    createExchangeConnector = async (data) => {
        return this.init().post(endPoints.createExchangeConnector, {
            data: data,
            token: this.api_token,
            v: version
        }).then((response) => {
            // console.log(response);
            if (response.data.login === 0 || response.data.token !== this.api_token) {
                // this.logout();
                return {result: false, error: 'User Session expired', action: 'logout'};
            }

            return {result: true, data: {}}
        }).catch((error) => {
            console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    }

    getUsersWebhooks = async (data) => {
        return this.init().post(endPoints.getUsersWebhooks, {
            data: data,
            token: this.api_token,
            v: version
        }).then((response) => {
            // console.log(response);
            if (response.data.login === 0 || response.data.token !== this.api_token) {
                // this.logout();
                return {result: false, error: 'User Session expired', action: 'logout'};
            }

            return {result: true, data: response.data.user_webhooks}
        }).catch((error) => {
            console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    }

    changeActiveWebhookConnector = async (data) => {
        return this.init().post(endPoints.changeActiveWebhookConnector, {
            data: data,
            token: this.api_token,
            v: version
        }).then((response) => {
            // console.log(response);
            if (response.data.login === 0 || response.data.token !== this.api_token) {
                // this.logout();
                return {result: false, error: 'User Session expired', action: 'logout'};
            }

            return {result: true, data: {}}
        }).catch((error) => {
            console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    }

    // deleteUserWebhook
    deleteUserWebhook = async (data) => {
        return this.init().post(endPoints.deleteUserWebhook, {
            data: data,
            token: this.api_token,
            v: version
        }).then((response) => {
            // console.log(response);
            if (response.data.login === 0 || response.data.token !== this.api_token) {
                // this.logout();
                return {result: false, error: 'User Session expired', action: 'logout'};
            }

            return {result: true, data: {}}
        }).catch((error) => {
            console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    }

    // createNewWebhook
    createNewWebhook = async (data) => {
        return this.init().post(endPoints.createNewWebhook, {
            data: data,
            token: this.api_token,
            v: version
        }).then((response) => {
            // console.log(response);
            if (response.data.login === 0 || response.data.token !== this.api_token) {
                // this.logout();
                return {result: false, error: 'User Session expired', action: 'logout'};
            }

            return {result: true, data: {}}
        }).catch((error) => {
            console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    }

    // updateWebhook
    updateWebhook = async (data) => {
        return this.init().post(endPoints.updateWebhook, {
            data: data,
            token: this.api_token,
            v: version
        }).then((response) => {
            // console.log(response);
            if (response.data.login === 0 || response.data.token !== this.api_token) {
                // this.logout();
                return {result: false, error: 'User Session expired', action: 'logout'};
            }

            return {result: true, data: {}}
        }).catch((error) => {
            console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    }

    // getBots
    getBots = async (data) => {
        return this.init().post(endPoints.getBots, {
            data: data,
            token: this.api_token,
            v: version
        }).then((response) => {
            // console.log(response);
            if (response.data.login === 0 || response.data.token !== this.api_token || response.data.result === false) {
                // this.logout();
                return {result: false, error: 'User Session expired', action: 'logout'};
            }

            return {result: true, data: response.data.bots}
        }).catch((error) => {
            console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    }

    // addNewBot
    addNewBot = async (data) => {
        return this.init().post(endPoints.addNewBot, {
            data: data,
            token: this.api_token,
            v: version
        }).then((response) => {
            // console.log(response);
            if (response.data.login === 0 || response.data.token !== this.api_token || response.data.result === false) {
                // this.logout();
                return {result: false, error: 'User Session expired', action: 'logout'};
            }

            return {result: true, data: response.data.bot}
        }).catch((error) => {
            console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    }

    // updateBot
    updateBot = async (data) => {
        return this.init().post(endPoints.updateBot, {
            data: data,
            token: this.api_token,
            v: version
        }).then((response) => {
            // console.log(response);
            if (response.data.login === 0 || response.data.token !== this.api_token || response.data.result === false) {
                // this.logout();
                return {result: false, error: 'User Session expired', action: 'logout'};
            }

            return {result: true, data: response.data.bot}
        }).catch((error) => {
            console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    }

    // deleteBot
    deleteBot = async (data) => {
        return this.init().post(endPoints.deleteBot, {
            data: data,
            token: this.api_token,
            v: version
        }).then((response) => {
            // console.log(response);
            if (response.data.login === 0 || response.data.token !== this.api_token || response.data.result === false) {
                // this.logout();
                return {result: false, error: 'User Session expired', action: 'logout'};
            }

            return {result: true}
        }).catch((error) => {
            console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    }

    // changeActiveBot
    changeActiveBot = async (data) => {
        return this.init().post(endPoints.changeActiveBot, {
            data: data,
            token: this.api_token,
            v: version
        }).then((response) => {
            // console.log(response);
            if (response.data.login === 0 || response.data.token !== this.api_token || response.data.result === false) {
                // this.logout();
                return {result: false, error: 'User Session expired', action: 'logout'};
            }

            return {result: true}
        }).catch((error) => {
            console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    }

    // testConnection
    testConnection = async (data) => {
        return this.initWebHook().post(connector_test.testConnection, {
            data,
        }).then((response) => {
            // console.log(response);
            if (response.data.code === false || response.data.code === 'error') {
                return {result: false};
            }

            return {result: true}
        }).catch((error) => {
            console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    }

    // getNewBotsWebhook
    getNewBotsWebhook = async (data) => {
        return this.init().post(endPoints.getNewBotsWebhook, {
            data: data,
            token: this.api_token,
            v: version
        }).then((response) => {
            // console.log(response);
            if (response.data.login === 0 || response.data.token !== this.api_token || response.data.result === false) {
                // this.logout();
                return {result: false, error: 'User Session expired', action: 'logout'};
            }

            return {result: true, data: response.data.bots_webhook}
        }).catch((error) => {
            console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    }

    // addNewBotWebhook
    addNewBotWebhook = async (data) => {
        return this.init().post(endPoints.addNewBotWebhook, {
            data: data,
            token: this.api_token,
            v: version
        }).then((response) => {
            // console.log(response);
            if (response.data.login === 0 || response.data.token !== this.api_token || response.data.result === false) {
                // this.logout();
                return {result: false, error: 'User Session expired', action: 'logout'};
            }

            return {result: true, data: response.data.bot_webhook}
        }).catch((error) => {
            console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    }

    // updateBotWebhook
    updateBotWebhook = async (data) => {
        return this.init().post(endPoints.updateBotWebhook, {
            data: data,
            token: this.api_token,
            v: version
        }).then((response) => {
            // console.log(response);
            if (response.data.login === 0 || response.data.token !== this.api_token || response.data.result === false) {
                // this.logout();
                return {result: false, error: 'User Session expired', action: 'logout'};
            }

            return {result: true, data: response.data.bot_webhook}
        }).catch((error) => {
            console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    }

    // deleteBotWebhook
    deleteBotWebhook = async (data) => {
        return this.init().post(endPoints.deleteBotWebhook, {
            data: data,
            token: this.api_token,
            v: version
        }).then((response) => {
            // console.log(response);
            if (response.data.login === 0 || response.data.token !== this.api_token || response.data.result === false) {
                // this.logout();
                return {result: false, error: 'User Session expired', action: 'logout'};
            }

            return {result: true}
        }).catch((error) => {
            console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    }

    // changeActiveBotWebhook
    changeActiveBotWebhook = async (data) => {
        return this.init().post(endPoints.changeActiveBotWebhook, {
            data: data,
            token: this.api_token,
            v: version
        }).then((response) => {
            // console.log(response);
            if (response.data.login === 0 || response.data.token !== this.api_token || response.data.result === false) {
                // this.logout();
                return {result: false, error: 'User Session expired', action: 'logout'};
            }

            return {result: true}
        }).catch((error) => {
            console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    }

    // getStrategyStats
    getStrategyStats = async (data) => {
        return this.init().post(endPoints.getStrategyStats, {
            data: data,
            token: this.api_token,
            v: version
        }).then((response) => {
            // console.log(response);
            if (response.data.result === false) {
                // this.logout();
                return {result: false, error: 'Server returns empty data', action: 'logout'};
            }

            return {result: true, data: response.data.strategy_stats}
        }).catch((error) => {
            console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    }

    // getAllStrategiesStats
    getAllStrategiesStats = async (data) => {
        return this.init().post(endPoints.getAllStrategiesStats, {
            data: data,
            token: this.api_token,
            v: version
        }).then((response) => {
            // console.log(response);
            if (response.data.result === false) {
                // this.logout();
                return {result: false, error: 'Server returns empty data', action: 'logout'};
            }

            return {result: true, data: response.data.strategies_stats}
        }).catch((error) => {
            console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    }

    // getTradesByStrategyID
    getTradesByStrategyID = async (data) => {
        return this.init().post(endPoints.getTradesByStrategyID, {
            data: data,
            token: this.api_token,
            v: version
        }).then((response) => {
            // console.log(response);
            if (response.data.login === 0 || response.data.token !== this.api_token || response.data.result === false) {
                // this.logout();
                return {result: false, error: 'User Session expired', action: 'logout'};
            }

            return {result: true, data: response.data.trades}
        }).catch((error) => {
            console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    }

    // getLogsSignals Strategy
    getLogsSignals = async (data) => {
        return this.init().post(endPoints.getLogsSignals, {
            data: data,
            token: this.api_token,
            v: version
        }).then((response) => {
            // console.log(response);
            // alert(JSON.stringify(response.data));
            if (response.data.login === 0 || response.data.token !== this.api_token || response.data.result === false) {
                // this.logout();
                return {result: false, error: 'User Session expired', action: 'logout'};
            }

            return {result: true, data: response.data.logs_signals}
        }).catch((error) => {
            console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    }

    // getLogsSignals Strategy
    getLogsSignalsWebhook = async (data) => {
        return this.init().post(endPoints.getLogsSignalsWebhook, {
            data: data,
            token: this.api_token,
            v: version
        }).then((response) => {
            // console.log(response);
            // alert(JSON.stringify(response.data));
            if (response.data.login === 0 || response.data.token !== this.api_token || response.data.result === false) {
                // this.logout();
                return {result: false, error: 'User Session expired', action: 'logout'};
            }

            return {result: true, data: response.data.logs_signals}
        }).catch((error) => {
            console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    }

    // getEquity
    getEquity = async (data) => {
        return this.init().post(endPoints.getEquity, {
            data: data,
            token: this.api_token,
            v: version
        }).then((response) => {
            // console.log(response);
            // alert(JSON.stringify(response.data));
            if (response.data.result === false) {
                // this.logout();
                return {result: false, error: 'User Session expired', action: 'logout'};
            }

            return {result: true, data: response.data.equity}
        }).catch((error) => {
            console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    }

    // getEquityStats
    getEquityStats = async (data) => {
        return this.init().post(endPoints.getEquityStats, {
            data: data,
            token: this.api_token,
            v: version
        }).then((response) => {
            // console.log(response);
            // alert(JSON.stringify(response.data));
            if (response.data.result === false) {
                // this.logout();
                return {result: false, error: 'User Session expired', action: 'logout'};
            }

            return {result: true, data: response.data.equity_stats}
        }).catch((error) => {
            console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    }

    // getMaxOpenTrades
    getMaxOpenTrades = async (data) => {
        return this.init().post(endPoints.getMaxOpenTrades, {
            data: data,
            token: this.api_token,
            v: version
        }).then((response) => {
            // console.log(response);
            // alert(JSON.stringify(response.data));
            if (response.data.result === false) {
                // this.logout();
                return {result: false, error: 'User Session expired', action: 'logout'};
            }

            return {result: true, data: response.data.max_open_trades}
        }).catch((error) => {
            console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    }

    // getConnectionStrategies
    getConnectedStrategies = async (data) => {
        return this.init().post(endPoints.getConnectedStrategies, {
            data: data,
            token: this.api_token,
            v: version
        }).then((response) => {
            // console.log(response);
            // alert(JSON.stringify(response.data));
            if (response.data.result === false) {
                // this.logout();
                return {result: false, error: 'User Session expired', action: 'logout'};
            }

            return {result: true, data: response.data.strategies}
        }).catch((error) => {
            console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    }

    // getNotConnectedStrategies
    getNotConnectedStrategies = async (data) => {
        return this.init().post(endPoints.getNotConnectedStrategies, {
            data: data,
            token: this.api_token,
            v: version
        }).then((response) => {
            // console.log(response);
            // alert(JSON.stringify(response.data));
            if (response.data.result === false) {
                // this.logout();
                return {result: false, error: 'User Session expired', action: 'logout'};
            }

            return {result: true, data: response.data.strategies}
        }).catch((error) => {
            console.log(error.message);
            return {result: false, error: error.message, action: 'error'};
        });
    }

}