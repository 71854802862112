import Search from "antd/es/input/Search";
import {Badge, Card, Col, Divider, Row, Skeleton, Space, Tag} from "antd";
import React, {useEffect, useState} from "react";

import gateways_list from "./gateways_list";
import {GatewayOutlined} from "@ant-design/icons";

function Gateways(props) {

    const [search, setSearch] = useState(gateways_list);
    const [searchValue, setSearchValue] = useState('')
    const [loading, setLoading] = useState(false);

    const onSearch = e => {
        // console.log(e.target.value);
        setSearchValue(e.target.value);
        setSearch(gateways_list.filter(module => {
            let add_filter_text = '';
            if (module.crypto) add_filter_text += 'Crypto';
            if (module.stocks) add_filter_text += 'Stocks';
            return (
                module.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
                module.description.toLowerCase().includes(e.target.value.toLowerCase()) ||
                module.brokers.some(broker => broker.toLowerCase().includes(e.target.value.toLowerCase())) ||
                add_filter_text.toLowerCase().includes(e.target.value.toLowerCase())
            )
        }));
    }

    useEffect(() => {
        setLoading(true);
        setSearch(gateways_list);
        setLoading(false);
    }, []);

    return (
        <>
            <span style={{
                fontFamily: 'Courier New, monospace',
                fontSize: '2em',
                fontWeight: 700,
            }}>
                    <GatewayOutlined/> Services for trading via Webhook
            </span>
            <br/>
            <Skeleton loading={loading}>
                <Search
                    size="large"
                    allowClear
                    style={{marginTop: 0, marginBottom: 0, width: "600px"}}
                    placeholder="enter the name of your broker"
                    onChange={onSearch}
                    enterButton
                    value={searchValue}
                />
                <Row
                    gutter={[16, 16]}
                    align={"middle"}
                    justify={"center"}
                    style={{marginBottom: '30px'}}
                >
                    {search.map((module, index) => {
                        return (
                            <Col span={18}>
                                <Badge.Ribbon
                                    text={module.free ? "FREE" : "PAID"}
                                    color={module.free ? "green" : "blue"}
                                >
                                    <Card
                                        type="inner"
                                        bordered={true}
                                        // hoverable
                                        // onClick={() => {
                                        //     window.open(module.url[0], '_blank');
                                        // }}
                                        title={<>
                                            <h2 style={{color: '#b21a2d'}}>
                                                <Space direction={"horizontal"} wrap>
                                                    {module.name}
                                                    {module.crypto &&
                                                        <Tag color={"geekblue"} bordered={false}>
                                                            Crypto
                                                        </Tag>
                                                    }
                                                    {module.stocks &&
                                                        <Tag color={"green"} bordered={false}>
                                                            Stocks
                                                        </Tag>
                                                    }
                                                </Space>
                                            </h2>
                                        </>}
                                        style={{width: "100%"}}>
                                        {/*<Divider*/}
                                        {/*    style={{marginTop: "10px"}}*/}
                                        {/*>Description:</Divider>*/}
                                        <p style={{fontSize: '16px'}}>
                                            {module.description}
                                        </p>
                                        {/*<Divider>URL:</Divider>*/}
                                        <a href={module.url} target="_blank" rel="noreferrer">🔗 Go to site</a>
                                        <Divider>Brokers:</Divider>
                                        <Space direction={"horizontal"} wrap>
                                            {module.brokers.map((broker, index) => {
                                                return (
                                                    <Tag key={index}
                                                         onClick={() => {
                                                             onSearch({target: {value: broker}})
                                                         }}
                                                    >
                                                        {broker}
                                                    </Tag>
                                                )
                                            })}
                                        </Space>
                                    </Card>
                                </Badge.Ribbon>
                            </Col>
                        )
                    })}
                </Row>
            </Skeleton>
        </>
    );
}

export default Gateways;