import React, {useEffect, useRef, useState} from 'react';
import {Button, ConfigProvider, Input, notification, Space, Table, Tooltip,} from 'antd';

import {Light as SyntaxHighlighter} from 'react-syntax-highlighter';
import json from 'react-syntax-highlighter/dist/esm/languages/hljs/json';
import {githubGist} from 'react-syntax-highlighter/dist/esm/styles/hljs';

import {
    CaretDownOutlined,
    CaretUpOutlined,
    ClearOutlined,
    CloseOutlined,
    CopyOutlined,
    FullscreenExitOutlined,
    SearchOutlined
} from "@ant-design/icons";
import API from "../../../api/user";
import {mobXStore} from "../../../store";
import {CopyToClipboard} from "react-copy-to-clipboard";

SyntaxHighlighter.registerLanguage('json', json);

const filterOption = (input, option) =>
    (option?.search_label ?? '').toLowerCase().includes(input.toLowerCase());

// SignalLogs component
function SignalLogs({strategy_id, connection_id, is_webhook}) {

    const language = mobXStore.getKey('language');

    const apiUser = new API();

    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState(false);

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const [apiNotification, contextHolder] = notification.useNotification();

    const openErrorNotification = (message) => {
        apiNotification.error({
            type: 'error',
            message: 'Error',
            description: message,
            placement: 'topRight',
            duration: 3,
        });
    };

    const openSuccessNotification = (message) => {
        apiNotification.open({
            type: 'success',
            message: 'Success',
            description: message,
            placement: 'topRight',
            duration: 3,
        });
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const sortIcon = (sorted) => {
        // alert(JSON.stringify(sorted))
        if (sorted['sortOrder'] === 'descend') {
            return (<CaretDownOutlined
                style={{
                    color: '#1677ff'
                }}
            />)
        } else if (sorted['sortOrder'] === 'ascend') {
            return (<CaretUpOutlined
                style={{
                    color: '#1677ff'
                }}
            />)
        } else {
            return (<FullscreenExitOutlined
                style={{
                    color: 'white',
                }}
            />)
        }
    }

    const getColumnSearchProps = (dataIndex, tooltip_text = '') => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (
            <div
                style={{
                    // backgroundColor: '#0f0fa8',
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Space direction={'horizontal'}>
                    <Input
                        ref={searchInput}
                        placeholder={tooltip_text}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{
                            marginBottom: 8,
                            width: 200,
                            display: 'block',
                        }}
                    />
                    <Button
                        type="button"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        <CloseOutlined/>
                    </Button>
                </Space>
                <br/>
                <Space direction={'horizontal'}>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined/>}
                        size="small"
                        style={{
                            width: 115,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && (handleReset(clearFilters) || handleSearch(selectedKeys, confirm, dataIndex) || close())}
                        size="small"
                        style={{
                            width: 115,
                        }}
                    >
                        <ClearOutlined/> Reset
                    </Button>
                    {/*<Button*/}
                    {/*    type="link"*/}
                    {/*    size="small"*/}
                    {/*    onClick={() => {*/}
                    {/*        confirm({*/}
                    {/*            closeDropdown: false,*/}
                    {/*        });*/}
                    {/*        setSearchText(selectedKeys[0]);*/}
                    {/*        setSearchedColumn(dataIndex);*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    Filter*/}
                    {/*</Button>*/}
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#f6adb5' : 'white',
                }}
            />
        ),
        sortIcon: (sorted) => sortIcon(sorted),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        // render: (text) =>
        //     searchedColumn === dataIndex ? (
        //         <Highlighter
        //             highlightStyle={{
        //                 backgroundColor: '#ffc069',
        //                 padding: 0,
        //             }}
        //             searchWords={[searchText]}
        //             autoEscape
        //             textToHighlight={text ? text.toString() : ''}
        //         />
        //     ) : (
        //         text
        //     ),
    });

    function getSignalLogs(strategy_id, connection_id) {
        setLoading(true);
        apiUser.getLogsSignals({strategy_id: strategy_id, connector_id: connection_id}).then((response) => {
            // alert(JSON.stringify(response));
            if (response.result) {
                // console.log(response);
                setDataSource(JSON.parse(response.data));
            }
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
        });
    }

    function getSignalLogsWebhook(webhook_id, connection_id) {
        setLoading(true);
        apiUser.getLogsSignalsWebhook({webhook_id: webhook_id, connector_id: connection_id}).then((response) => {
            if (response.result) {
                // console.log(response);
                setDataSource(JSON.parse(response.data));
            }
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
        });
    }

    useEffect(() => {
        if (is_webhook) getSignalLogsWebhook(strategy_id, connection_id);
        else getSignalLogs(strategy_id, connection_id);
    }, [strategy_id, connection_id]);

    const columns = [
        {
            title: 'Date/Time',
            dataIndex: 'date_time',
            align: 'center',
            width: '170px',
            // fixed: 'left',
            // defaultSortOrder: 'descend',
            sortIcon: (sorted) => sortIcon(sorted),
            sorter: (a, b) => a.date_time - b.date_time,
        },
        {
            title: 'Level',
            dataIndex: 'error_level',
            // width: '15%',
            align: 'center',
            render: (text) => {
                if (text.toLowerCase().includes('error')) {
                    //  red
                    return <><CaretDownOutlined style={{color: 'red', fontSize: 18}}/> {text}</>
                } else {
                    //  green
                    return <><CaretUpOutlined style={{color: 'green', fontSize: 18}}/> {text}</>
                }
            },
            // fixed: 'left',
        }, {
            title: 'Signal',
            dataIndex: 'signal',
            // width: '15%',
            align: 'center',
            render: (text) => {
                // Show only first 100 characters

                if(!text) return <>signal is empty</>;

                text = text.replace(/"secret": ".+?"/g, '"secret": "********"');

                const jsonStr = text
                    .replace(/'/g, '"')
                    .replace(/True/g, 'true')
                    .replace(/False/g, 'false')
                    .replace(/None/g, 'null');

                let json_beautiful = '';
                try {
                    json_beautiful = JSON.stringify(JSON.parse(jsonStr), null, 2);
                } catch (e) {
                    json_beautiful = jsonStr;
                }

                return <>
                    <Tooltip
                        title={
                            <SyntaxHighlighter
                                language="json"
                                style={githubGist}
                                customStyle={{
                                    margin: 0,
                                    padding: '8px',
                                    maxHeight: '400px',
                                    // backgroundColor: 'transparent'
                                }}
                            >
                                {json_beautiful}
                            </SyntaxHighlighter>
                        }
                        overlayStyle={{
                            maxHeight: '300px',
                            maxWidth: '30%',
                            minWidth: '30%',
                            // width: 'auto',
                            fontSize: '14px',
                            // backgroundColor: 'white',
                            // color: 'black',
                        }}
                        overlayInnerStyle={{
                            padding: '10px',
                            // fontSize: '12px',
                            // backgroundColor: 'white',
                        }}
                    >
    <span>
        {text.length > 50
            ? (typeof text === 'string' ? text.substring(0, 70) : JSON.stringify(text).substring(0, 70)) + '...'
            : (typeof text === 'string' ? text : JSON.stringify(text, null, 2))
        }
    </span>
                    </Tooltip> <Tooltip
                    title={'Click to copy'}>
                    <CopyToClipboard text={json_beautiful}><Button
                        type="primary"
                        size="small"
                        icon={<CopyOutlined/>}
                        onClick={() => {
                            openErrorNotification('Copied to clipboard');
                            // Modal.success({
                            //     title: 'Success',
                            //     content: 'Copied to clipboard',
                            // })
                        }}
                    /></CopyToClipboard>
                </Tooltip>
                </>
            }
        },
        {
            title: 'API Response',
            dataIndex: 'message',
            // width: '15%',
            align: 'center',
            render: (text) => {
                // Show only first 100 characters
                const jsonStr = text
                    .replace(/'/g, '"')
                    .replace(/True/g, 'true')
                    .replace(/False/g, 'false')
                    .replace(/None/g, 'null');

                let json_beautiful = '';
                try {
                    json_beautiful = JSON.stringify(JSON.parse(jsonStr), null, 2);
                } catch (e) {
                    json_beautiful = jsonStr;
                }

                return <>
                    <Tooltip
                        title={
                            <SyntaxHighlighter
                                language="json"
                                style={githubGist}
                                customStyle={{
                                    margin: 0,
                                    padding: '8px',
                                    maxHeight: '400px',
                                    // backgroundColor: 'transparent'
                                }}
                            >
                                {json_beautiful}
                            </SyntaxHighlighter>
                        }
                        overlayStyle={{
                            maxHeight: '300px',
                            maxWidth: '30%',
                            minWidth: '30%',
                            // width: 'auto',
                            fontSize: '14px',
                            // backgroundColor: 'white',
                            // color: 'black',
                        }}
                        overlayInnerStyle={{
                            padding: '10px',
                            // fontSize: '12px',
                            // backgroundColor: 'white',
                        }}
                    >
    <span>
        {text.length > 50
            ? (typeof text === 'string' ? text.substring(0, 70) : JSON.stringify(text).substring(0, 70)) + '...'
            : (typeof text === 'string' ? text : JSON.stringify(text, null, 2))
        }
    </span>
                    </Tooltip> <Tooltip
                    title={'Click to copy'}>
                    <CopyToClipboard text={json_beautiful}><Button
                        type="primary"
                        size="small"
                        icon={<CopyOutlined/>}
                        onClick={() => {
                            openErrorNotification('Copied to clipboard');
                            // Modal.success({
                            //     title: 'Success',
                            //     content: 'Copied to clipboard',
                            // })
                        }}
                    /></CopyToClipboard>
                </Tooltip>
                </>
            }
        },
    ];

    return (
        <ConfigProvider
            theme={{
                components: {
                    Table: {
                        // fontSize: "16px",
                        // fontFamily: 'Courier New',
                        headerBorderRadius: '10px',
                        headerColor: '#ffffff',
                        headerBg: '#272762',
                        bodySortBg: '#ffffff',
                        headerSortHoverBg: '#272762',
                        headerSortActiveBg: '#272762',
                        cellFontSizeSM: "14px",
                        cellPaddingInline: '10px',
                        cellPaddingBlockSM: '5px',
                        cellPaddingInlineSM: '5px',
                        colorLinkActive: '#b21a2d',
                    },
                },
            }}
        >
            <Table
                scroll={{x: 'max-content'}}
                rowKey={record => record.key}
                size="small"
                pagination={{
                    pageSizeOptions: [9999],
                    hideOnSinglePage: true,
                    defaultPageSize: 9999,
                }}
                loading={loading}
                // title={() => {
                //     return (
                //         <h1>
                //             <RobotOutlined/> {strategy_name} - {strategy.name} | {strategy.symbol.replace('BINANCE:', '').replace('.P', '').replace('USDT', '/USDT')} |
                //             TF: {strategy.timeframe}
                //         </h1>
                //     )
                // }}
                style={{
                    marginTop: '0%',
                    marginLeft: '0%',
                    marginRight: '0%',
                }}
                bordered={true}
                dataSource={dataSource}
                columns={columns}
                onRow={(record) => {

                    return {
                        // style: {
                        //     backgroundColor: record.profit_percent > 0 ? '#d9f6d7' : '#f5dfdf'
                        // }
                        // onClick: () => handleEdit(record.key), // click row
                        // onDoubleClick: () => alert(record.id), // double click row
                        // onContextMenu: event => {
                        // }, // right button click row
                        // onMouseEnter: event => {
                        // }, // mouse enter row
                        // onMouseLeave: event => {
                        // }, // mouse leave row
                    };
                }}
            />
        </ConfigProvider>
    );
}

export default SignalLogs;