import {Image, Menu, Modal} from 'antd';
import React, {useEffect, useState} from "react";
import {
    AndroidOutlined,
    ApartmentOutlined,
    AppstoreOutlined,
    ControlOutlined,
    DiscordOutlined,
    FullscreenExitOutlined,
    GatewayOutlined,
    LineChartOutlined,
    LogoutOutlined,
    MailOutlined,
    RobotOutlined,
    SettingOutlined,
    SlidersOutlined,
    WechatWorkOutlined
} from "@ant-design/icons";

import './index.css';
import MobXStore from "../../../store/MobXStore";
import {mobXStore} from "../../../store";

const items = [
    {
        // title: (<Tooltip>Ready-made strategies</Tooltip>),
        label: 'Strategies',
        key: 'SubMenuSL',
        icon: <AppstoreOutlined/>,
        disabled: false,
        children: [
            {
                label: 'Ready-made',
                key: 'strategy_stats',
                icon: <RobotOutlined/>,
                disabled: false
            },
            {
                label: 'Free',
                key: 'strategy',
                icon: <LineChartOutlined/>,
                disabled: false
            },
            // {
            //     label: 'Set: U-0002-C',
            //     key: 'multi_strategy_u_0002_c',
            //     icon: <AppstoreOutlined/>,
            //     disabled: false
            // },
            // {
            //     label: 'Set: U-0002-S',
            //     key: 'multi_strategy_u_0002_s',
            //     icon: <AppstoreOutlined/>,
            //     disabled: false
            // },
        ]
    },
    {
        label: 'Exchanges',
        key: 'connectors',
        icon: <ControlOutlined/>,
        disabled: false,
    },
    {
        label: 'Copy Bots',
        key: 'bots',
        icon: <AndroidOutlined/>,
        disabled: false,
        // children: [
        //     {
        //         label: 'to Exchange',
        //         key: 'bots',
        //         icon: <ApiOutlined/>,
        //         disabled: false
        //     },
        // {
        //     label: 'to Webhook',
        //     key: 'bots_webhook',
        //     icon: <ApartmentOutlined/>,
        //     disabled: false
        // },
        // ]
    },
    {
        label: 'Webhooks servers',
        key: 'SubMenuWH',
        icon: <ApartmentOutlined/>,
        disabled: false,
        children: [
            {
                label: 'CDZV server',
                key: 'webhooks',
                icon: <FullscreenExitOutlined/>,
                disabled: false
            },
            {
                label: 'Other servers',
                key: 'gateways',
                icon: <GatewayOutlined/>,
                disabled: false,
            }
        ]
    },
    {
        label: 'CDZV Toolkit',
        key: 'indicators',
        icon: <SlidersOutlined/>,
        disabled: false,
    },
    // {
    //     label: (<>
    //         <Tooltip placement="right" overlayInnerStyle={{
    //             width: '500px',
    //             backgroundColor: '#d1d9da',
    //             color: 'black',
    //         }}
    //                  title={<div>
    //                      <h3>
    //                          How to get full access:<br/><br/>
    //
    //                          1. Follow the link and launch the bot.<br/>
    //                          2. Call the <span style={{color: 'red'}}>/info</span> command<br/>
    //                          3. The ChatID will be listed at the bottom of the message.<br/>
    //                          4. Enter the ChatID in the user panel under Profile: @AnySearchBot_bot
    //                          ChatId.<br/><br/>
    //
    //                          Enjoy!
    //                      </h3>
    //                      <Image width={480}
    //                             src={"https://cdzv.com/strategy_img/Telegram_2024-06-02_19-02-16.png"}/>
    //                  </div>}>
    //             Financial AI <QuestionCircleOutlined/>
    //         </Tooltip>
    //     </>),
    //     key: 'ai-anysearchbot',
    //     icon: (<>
    //         <RobotOutlined/>
    //     </>),
    //     disabled: false
    // },
    // {
    //     label: "",
    //     key: 'empty1',
    //     disabled: true
    // },
    {
        label: 'Profile',
        key: 'profile',
        icon: <SettingOutlined/>,
        disabled: false
    },
    {
        label: <span style={{color: 'red', fontWeight: 700}}>Support</span>,
        key: 'support_menu',
        icon: <WechatWorkOutlined style={{color: 'red'}}/>,
        disabled: false,
        children: [
            {
                label: 'Discord',
                key: 'support_discord',
                icon: <DiscordOutlined/>,
                disabled: false
            },
            {
                label: 'Telegram',
                key: 'support_tg',
                icon: <GatewayOutlined/>,
                disabled: false,
            },
            {
                label: 'Email',
                key: 'support_email',
                icon: <MailOutlined/>,
                disabled: false,
            }
        ]
    },
    // {
    //     label: "Trading courses",
    //     icon: <DollarOutlined/>,
    //     key: 'academy',
    //     disabled: false
    // },
    // {
    //     label: 'Language',
    //     key: 'language',
    //     icon: <ReadOutlined/>,
    //     disabled: false,
    //     children: [
    //         {
    //             label: 'English',
    //             key: 'lang_en',
    //             icon: <><strong>E</strong> &nbsp;</>,
    //             disabled: false
    //         },
    //         {
    //             label: 'Ukraine',
    //             key: 'lang_ua',
    //             icon: <><strong>U</strong> &nbsp;</>,
    //             disabled: false,
    //         },
    //         {
    //             label: 'Russian',
    //             key: 'lang_ru',
    //             icon: <><strong>R</strong> &nbsp;</>,
    //             disabled: false,
    //         }
    //     ]
    // },
    {
        label: "LogOut",
        icon: <LogoutOutlined/>,
        key: 'logout',
        disabled: false
    },
];

const HeaderMenu = (props) => {
    const language = mobXStore.getKey('language');

    const {onMenuChange, defaultKey} = props;
    const [current, setCurrent] = useState('strategy_stats');

    const [showZoomModal, setShowZoomModal] = useState(false);

    useEffect(() => {
        if (language !== 'en' && language !== '' && Math.random() < 0.1) {
            setShowZoomModal(true);
        }
    }, [language]);

    useEffect(() => {
        setCurrent(defaultKey)
    }, [defaultKey]);

    const onClick = (e) => {
        if (e.key === 'lang_ru') {
            MobXStore.setKey('language', 'ru');
            e.key = 'multi_strategy_u_0002'
            window.location = '/';
        } else if (e.key === 'lang_ua') {
            MobXStore.setKey('language', 'ua');
            e.key = 'multi_strategy_u_0002'
            window.location = '/';
        } else if (e.key === 'lang_en') {
            MobXStore.setKey('language', 'en');
            e.key = 'multi_strategy_u_0002_c'
            window.location = '/';
        }
        setCurrent(e.key);
        onMenuChange(e.key)
    };

    return (
        <>
            <Modal
                title="Мы ответим на все твои вопросы"
                open={showZoomModal}
                // footer={null}
                width={600}
                onOk={() => {
                    setShowZoomModal(false);
                    window.open('https://t.me/cdzv_com_support_bot', '_blank');
                }}
                onCancel={() => {
                    setShowZoomModal(false);
                }}
            >
                <Image
                    src={'https://cdzv.com/strategy_img/Nichego_ne_ponyatno_Togda_zapisyvaisya_na_besplatnuyu_Zoom_konsultatsiyu_ot_CDZV_-_Recraft_2024-11-19_23-27-27.jpg'}
                    preview={false}/>
            </Modal>
            <Menu
                theme={"light"}
                className={"menu"}
                onClick={onClick}
                // disabledOverflow={true}
                selectedKeys={[current]}
                mode="inline"
                items={items}
                defaultOpenKeys={['SubMenuSL', 'support_menu']}
            />
        </>
    );
}

export default HeaderMenu;
