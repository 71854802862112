import {useEffect, useState} from "react";
import API from "../../../api/user";
import CustomerProfileCard from "../../components/CustomerProfileCard";
import {notification, Skeleton} from "antd";

function Profile(props) {

    const apiUser = new API();

    const [userProfile, setUserProfile] = useState({});
    const [loading, setLoading] = useState(false);

    const [apiNotification, contextHolder] = notification.useNotification();

    const openErrorNotification = (message) => {
        apiNotification.error({
            type: 'error',
            message: 'Error',
            description: message,
            placement: 'topRight',
            duration: 3,
        });
    };

    const openSuccessNotification = (message) => {
        apiNotification.open({
            type: 'success',
            message: 'Success',
            description: message,
            placement: 'topRight',
            duration: 3,
        });
    };

    useEffect(() => {
        setLoading(true);
        apiUser.getUserProfile().then((response) => {
            if (response.result) {
                // console.log(response);
                setUserProfile(JSON.parse(response.data));
                // console.log(response.data);
            } else {
                // console.log(response.error);
                if (response.error !== undefined) {
                    openErrorNotification(response.error);
                } else {
                    openErrorNotification("Connection error. You must log in again.");
                }
                apiUser.logout();
            }
            setLoading(false);
        });
    }, []);

    /*
    {"ID":1,"is_active":1,"is_admin":1,"is_send_mail":1,"discord":"cdzv.com","tv_user":"CDZV","date_subscription_start":"2099-02-28T23:00:00.000Z","date_subscription_end":"2099-02-28T23:00:00.000Z","first_name":"Yev","last_name":"Gen","phone":null,"email":"lexus@cdzv.com","name":"Life time","code":"LIFETIME","days":9999,"date_end":"2126-07-16T22:00:00.000Z","days_left":1020301}
     */
    return (
        <>
            {contextHolder}
            <Skeleton loading={loading}>
                <CustomerProfileCard
                    customerData={userProfile}
                    openSuccessNotification={openSuccessNotification}
                />
            </Skeleton>
        </>
    );
}

export default Profile;