import React, {memo, useEffect, useState} from "react";

import Chart from "react-apexcharts";

import "./AreaChart.css";

function AreaChart(props) {

    const {value} = props;

    // console.log('value', value);

    const colorGreen = "#09a362";
    const colorRed = "#dc1414";

    let maxEquity = 0;
    let minEquity = 0;

    // const generateColors = (data) => {
    //     return data.map((d, idx) => {
    //         const color = d > 1000 ? colorGreen : colorRed;
    //
    //         return {
    //             offset: idx / data.length * 100,
    //             color,
    //             opacity: 0.5
    //         }
    //     })
    // }

    const InitData = {
        series: [{
            name: "",
            // data: [0]
        }],
        options: {
            // title: {
            //     text: 'Negative color for values less than 0',
            //     align: 'left',
            // },
            chart: {
                // fontFamily: "Manrope, sans-serif",
                type: "area",

                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: false,
                },
            },
            plotOptions: {
                line: {
                    colors: {
                        threshold: 0,
                        colorAboveThreshold: '#a1e198',
                        colorBelowThreshold: '#ff0000',
                    },
                },
            },
            annotations: {
                // yaxis: [{
                //     y: 30,
                //     borderColor: '#999',
                //     label: {
                //         show: true,
                //         text: 'start',
                //         style: {
                //             color: "#fff",
                //             background: '#0f0fa8'
                //         }
                //     }
                // }],
                xaxis: [{
                    x: new Date('01 Jan 2020').getTime(),
                    borderColor: '#999',
                    yAxisIndex: 0,
                    label: {
                        show: true,
                        text: '2020',
                        style: {
                            color: "#fff",
                            background: '#0f0fa8'
                        }
                    }
                },
                    {
                        x: new Date('01 Jan 2021').getTime(),
                        borderColor: '#999',
                        yAxisIndex: 0,
                        label: {
                            show: true,
                            text: '2021',
                            style: {
                                color: "#fff",
                                background: '#0f0fa8'
                            }
                        }
                    },
                    {
                        x: new Date('01 Jan 2022').getTime(),
                        borderColor: '#999',
                        yAxisIndex: 0,
                        label: {
                            show: true,
                            text: '2022',
                            style: {
                                color: "#fff",
                                background: '#0f0fa8'
                            }
                        }
                    },
                    {
                        x: new Date('01 Jan 2023').getTime(),
                        borderColor: '#999',
                        yAxisIndex: 0,
                        label: {
                            show: true,
                            text: '2023',
                            style: {
                                color: "#fff",
                                background: '#0f0fa8'
                            }
                        }
                    },
                    {
                        x: new Date('01 Jan 2024').getTime(),
                        borderColor: '#999',
                        yAxisIndex: 0,
                        label: {
                            show: true,
                            text: '2024',
                            style: {
                                color: "#fff",
                                background: '#0f0fa8'
                            }
                        }
                    },
                    {
                        x: new Date('01 Jan 2025').getTime(),
                        borderColor: '#999',
                        yAxisIndex: 0,
                        label: {
                            show: true,
                            text: '2025',
                            style: {
                                color: "#fff",
                                background: '#0f0fa8'
                            }
                        }
                    },
                    {
                        x: new Date('01 Jan 2026').getTime(),
                        borderColor: '#999',
                        yAxisIndex: 0,
                        label: {
                            show: true,
                            text: '2026',
                            style: {
                                color: "#fff",
                                background: '#0f0fa8'
                            }
                        }
                    }]
            },
            // annotations: {
            //     yaxis: [
            //         {
            //             y: 0,
            //             y2: -500,
            //             fillColor: '#e88894',
            //             // label: {
            //             //     borderColor: '#dc1414',
            //             //     style: {
            //             //         color: '#fff',
            //             //         background: '#dc1414'
            //             //     },
            //             //     text: '0%'
            //             // }
            //         },
            //         {
            //             y: 0,
            //             // y2: 20000,
            //             fillColor: '#ffffff',
            //         }
            //     ]
            // },

            stroke: {
                width: 2,
                curve: "smooth",
                // dashArray: 2
            },

            labels: {
                style: {
                    fontSize: "10px",
                },
            },

            dataLabels: {
                enabled: false,
            },

            // grid: {
            //     borderColor: "#d1d9da"
            // },

            // markers: {
            //     strokeWidth: 1,
            //     size: 2,
            //     colors: [colorGreen],
            //     hover: {
            //         sizeOffset: 1,
            //     },
            // },
            xaxis: {
                type: 'datetime',
                // min: new Date('01 Mar 2020').getTime(),
                // axisTicks: {
                //     // show: true,
                //     borderType: "solid",
                //     color: "#d1d9da",
                //     height: 1,
                //     offsetX: 0,
                //     offsetY: 0,
                // },

                // tickPlacement: "between",
                tickAmount: 6,
                // labels: {
                //     style: {
                //         colors: ["#636E72"],
                //         fontSize: "10px",
                //     },
                // },
                categories: [],
            },
            // legend: {
            //     horizontalAlign: "right",
            //     offsetX: 40,
            //     position: "top",
            // },
            yaxis: {
                // labels: {
                //     style: {
                //         colors: ["#636E72"],
                //         fontSize: "10px",
                //     },
                // },
                tickAmount: 50,
            },
            tooltip: {
                enabled: true,
                shared: true,
                followCursor: true,
                intersect: false,
                theme: 'dark',
                x: {
                    formatter: function (val) {
                        return new Date(val).toLocaleDateString();
                    }
                },
                y: {
                    formatter: function (val) {
                        return `${val.toLocaleString('uk-UA')} %`;
                    },
                }
            }
        },
    };

    const [data, setData] = useState(InitData);

    // Refresh chart when data changes
    const refreshChart = () => {

        if (value && value.length > 0) {
            // console.log('value', value);
            const seriesData = [];
            const categories = [];

            const dateTimeData = [];

            maxEquity = 0;
            minEquity = 0;
            for (let i = 0; i < value.length; i++) {
                seriesData.push(value[i].equity);
                categories.push(value[i].exit_datetime_days);
                dateTimeData.push({x: new Date(value[i].exit_datetime_days).getTime(), y: value[i].equity});
                maxEquity = Math.max(maxEquity, value[i].equity);
                minEquity = Math.min(minEquity, value[i].equity);
            }

            // console.log('seriesData', seriesData);
            // console.log('categories', categories);

            setData(InitData);

            setData({
                // ...data.series,
                series: [{
                    // name: "%",
                    data: dateTimeData
                }],
                options: {
                    ...data.options,
                    xaxis: {
                        ...data.options.xaxis,
                        categories: categories
                    },
                    yaxis: {
                        ...data.options.yaxis,
                        max: Math.round(maxEquity + 1),
                        min: Math.round(minEquity - 1),
                        tickAmount: 10,
                    }
                }
            });
        }
    }

// First render
    useEffect(() => {
        refreshChart();
    }, []);

    // Refresh chart when data changes
    useEffect(() => {
        refreshChart();
    }, [value]);

    return (
        <div id="chart">
            <Chart
                loading={value === null}
                options={data.options}
                series={data.series}
                type="area"
                height={450}
                // legend="legend"
            />
        </div>
    );
}

export default memo(AreaChart);