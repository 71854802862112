// isLogin
async function isLogin(connection, email, otp) {
    const [rows] = await connection.query(
        'select 1 as result\n' +
        'from one_time_passwords otp,\n' +
        '     users u\n' +
        'where u.id = otp.user_id and\n' +
        '      u.email = ? and\n' +
        '      otp.code = ? and\n' +
        '      otp.is_active = 1 and\n' +
        '      u.is_active = 1 and\n' +
        '      u.is_deleted = 0 and\n' +
        '      otp.date_end > now()',
        [email, otp]
    );
    return rows[0];
}

// get userId by Email
async function getUserIdByEmail(connection, email) {
    const [rows] = await connection.query(
        'select u.ID\n' +
        'from `users` u\n' +
        'WHERE u.email = ?\n' +
        '  AND u.is_deleted = 0\n' +
        '  AND u.is_active = 1',
        [email]
    );
    return rows[0];
}

// clear OTP by email
async function clearOTP(connection, email) {
    const [rows] = await connection.query(
        'delete from one_time_passwords where email = ?',
        [email]
    );
    return rows[0];
}

// clear expired OTP
async function clearOTPExpired(connection) {
    const [rows] = await connection.query(
        'delete from one_time_passwords where date_end < now()',
        []
    );
    return rows[0];
}

// Write OTP
async function writeOTP(connection, user_id, email, otp) {
    await connection.query(
        'insert into one_time_passwords (user_id, email, code, date_end)\n' +
        'values (?, ?, ?, now() + interval 5 minute)',
        [user_id, email, otp]
    );
    return true;
}

// get all strategies from DB
async function getStrategies(connection) {
    const [rows] = await connection.query(
        "select *\n" +
        "from strategy_stats s\n" +
        "where s.is_public = 1\n" +
        "  and s.is_active = 1\n" +
        "order by s.name, s.symbol, s.timeframe desc");
    return rows;
}

// get user profile
async function getUserProfile(connection, email) {
    const [rows] = await connection.query(
        'select u.ID,\n' +
        '       u.is_active,\n' +
        '       u.is_admin,\n' +
        '       u.ref_user_id,\n' +
        '       u.is_send_mail,\n' +
        '       u.discord,\n' +
        '       u.telegram_name,\n' +
        '       u.telegram_ai_id,\n' +
        '       u.tv_user,\n' +
        '       u.date_subscription_start,\n' +
        '       u.date_subscription_end,\n' +
        '       u.first_name,\n' +
        '       u.last_name,\n' +
        '       u.language,\n' +
        '       u.phone,\n' +
        '       u.email,\n' +
        '       sl.name,\n' +
        '       sl.code,\n' +
        '       sl.days,\n' +
        '       (DATE(u.date_subscription_start) + INTERVAL sl.days DAY)               as date_end,\n' +
        '       (DATEDIFF(u.date_subscription_end, NOW())) as days_left,\n' +
        '       ref_code\n' +
        'from users u,\n' +
        '     subscription_levels sl\n' +
        'where u.subscription_level_id = sl.id\n' +
        '  and u.email = ?\n' +
        '  and u.is_deleted = 0',
        [email]
    );
    return rows[0];
}

// change Discord name
async function changeDiscordName(connection, data) {
    // console.log(data);
    await connection.query(
        'update users\n' +
        'set discord = ?\n' +
        'where email = ?',
        [data.discord, data.email]
    );
    return true;
}

// change Telegram name
async function changeTelegramName(connection, data) {
    // console.log(data);
    await connection.query(
        'update users\n' +
        'set telegram_name = ?\n' +
        'where email = ?',
        [data.telegram, data.email]
    );
    return true;
}

// change Telegram AI Chat ID
async function changeTelegramAIChatID(connection, data) {
    // console.log(data);
    await connection.query(
        'update users\n' +
        'set telegram_ai_id = ?\n' +
        'where email = ?',
        [data.telegram_ai_id, data.email]
    );
    return true;
}

// create email in DB
async function createEmail(connection, email, subject, body) {
    await connection.query(
        'insert into email_queue (email_to, subject, body)\n' +
        'values (?, ?, ?)',
        [email, subject, Buffer.from(body).toString('base64')]
    );
    return true;
}

// create new exchange connector
async function createExchangeConnector(connection, email, exchange, market, name, api_key, api_secret, password, max_long_positions_count, max_short_positions_count, only_one_asset) {

    if (!max_long_positions_count || max_long_positions_count === '' || max_long_positions_count === undefined) {
        max_long_positions_count = -1;
    }
    if (!max_short_positions_count || max_short_positions_count === '' || max_short_positions_count === undefined) {
        max_short_positions_count = -1;
    }

    if (max_long_positions_count < -1) {
        max_long_positions_count = -1;
    }

    if (max_short_positions_count < -1) {
        max_short_positions_count = -1;
    }

    if (!only_one_asset || only_one_asset < -1) {
        only_one_asset = 0;
    }

    try {
        await connection.query(
            'insert into exchange_connectors (user_id, exchange, market, name, api_key, api_secret_key, is_active, password, max_long_positions_count, max_short_positions_count, only_one_asset)\n' +
            'values ((select ID from users where email = ?), ?, ?, ?, ?, ?, 1, ?, ?, ?, ?)',
            [email, exchange, market, name, api_key, api_secret, password, max_long_positions_count, max_short_positions_count, only_one_asset]
        );
        return true;
    } catch (e) {
        console.log(e);
        return false;
    }
}

// get user exchange
async function getUserExchange(connection, email) {
    const [rows] = await connection.query(
        'select ec.ID as \'key\', ec.name, ec.exchange, ec.market as type, ec.is_active, CONCAT(LEFT(ec.api_key, 5), \'.........\', RIGHT(ec.api_key, 5)) as pub_key, max_long_positions_count, max_short_positions_count, only_one_asset\n' +
        'from users u,\n' +
        'exchange_connectors ec\n' +
        'where u.ID = ec.user_id\n' +
        '  and u.is_active = 1\n' +
        '  and u.email = ?\n',
        [email]
    );
    return rows;
}

// delete user exchange by ID and email
async function deleteUserExchange(connection, email, id) {
    await connection.query(
        'delete from exchange_connectors\n' +
        'where user_id = (select ID from users where email = ?) and\n' +
        '      ID = ?',
        [email, id]
    );
    return true;
}

// change name of exchange connector
async function changeNameExchangeConnector(connection, email, id, name) {
    try {
        await connection.query(
            'update exchange_connectors\n' +
            'set name = ?\n' +
            'where user_id = (select ID from users where email = ?) and\n' +
            '      ID = ?',
            [name, email, id]
        );
        return true;
    } catch (e) {
        console.log(e);
        return false;
    }
}

// change limits of exchange connector
async function changeLimitExchangeConnector(connection, email, id, max_long_positions_count, max_short_positions_count, only_one_asset) {

    if (!max_long_positions_count || max_long_positions_count === '' || max_long_positions_count === undefined) {
        max_long_positions_count = 0;
    }

    if (!max_short_positions_count || max_short_positions_count === '' || max_short_positions_count === undefined) {
        max_short_positions_count = 0;
    }

    if (max_long_positions_count < -1) {
        max_long_positions_count = -1;
    }

    if (max_short_positions_count < -1) {
        max_short_positions_count = -1;
    }

    if (!only_one_asset || only_one_asset < -1) {
        only_one_asset = 0;
    }

    try {
        await connection.query(
            'update exchange_connectors\n' +
            'set max_long_positions_count = ?,\n' +
            '    max_short_positions_count = ?,\n' +
            '    only_one_asset = ?\n' +
            'where user_id = (select ID from users where email = ?) and\n' +
            '      ID = ?',
            [max_long_positions_count, max_short_positions_count, only_one_asset, email, id]
        );
        return true;
    } catch (e) {
        console.log(e);
        return false;
    }
}

// change is_active of exchange connector
async function changeActiveExchangeConnector(connection, email, id, is_active) {
    await connection.query(
        'update exchange_connectors\n' +
        'set is_active = ?\n' +
        'where user_id = (select ID from users where email = ?) and\n' +
        '      ID = ?',
        [is_active, email, id]
    );
    return true;
}

// get webhook properties by user email
async function getUsersWebhooks(connection, email) {
    const [rows] = await connection.query(
        'select w.ID         as \'key\',\n' +
        '       ec.ID        as \'exchange_key\',\n' +
        '       w.name,\n' +
        '       w.secret,\n' +
        '       ec.exchange,\n' +
        '       ec.market,\n' +
        '       ec.name as exchange_name,\n' +
        '       w.order_amount_usd,\n' +
        '       w.leverage,\n' +
        '       w.margin_mode,\n' +
        '       IF(w.is_active = 1 and ec.is_active = 1, 1, 0) as \'is_active\'\n' +
        'from users u,\n' +
        '     webhooks w,\n' +
        '     exchange_connectors ec\n' +
        'where w.user_id = u.ID\n' +
        '  and w.exchange_connectors_id = ec.ID\n' +
        '  and u.is_active = 1\n' +
        '  and u.email = ?',
        [email]
    );
    return rows;
}

// changeActiveWebhookConnector
async function changeActiveWebhookConnector(connection, email, id, is_active) {
    await connection.query(
        'update webhooks w\n' +
        'set is_active = ?\n' +
        'where user_id = (select ID from users where email = ?) and\n' +
        '      ID = ?',
        [is_active, email, id]
    );
    return true;
}

// deleteUserWebhook
async function deleteUserWebhook(connection, email, id) {
    await connection.query(
        'delete from webhooks\n' +
        'where user_id = (select ID from users where email = ?) and\n' +
        '      ID = ?',
        [email, id]
    );
    return true;
}

// createNewWebhook
async function createNewWebhook(connection, email, secret, exchange_key, name, order_amount_usd, leverage, margin_mode) {
    try {
        await connection.query(
            'insert into webhooks (user_id, exchange_connectors_id, name, secret, order_amount_usd, leverage, margin_mode, is_active)\n' +
            'values ((select ID from users where email = ?), ?, ?, ?, ?, ?, ?, 0)',
            [email, exchange_key, name, secret, order_amount_usd, leverage, margin_mode]
        );
        return true;
    } catch (e) {
        console.log(e);
        return false;
    }
}

// update Webhook
async function updateWebhook(connection, email, id, name, order_amount_usd, leverage, margin_mode) {
    try {
        await connection.query(
            'update webhooks w\n' +
            'set name = ?,\n' +
            '    order_amount_usd = ?,\n' +
            '    leverage = ?,\n' +
            '    margin_mode = ?\n' +
            'where user_id = (select ID from users where email = ?) and\n' +
            '      ID = ?',
            [name, order_amount_usd, leverage, margin_mode, email, id]
        );
        return true;
    } catch (e) {
        console.log(e);
        return false;
    }
}

// get BotsExchange
async function getBots(connection, email, connector_id = 0) {

    let condition = '1 = 1'; // Условие, которое всегда истинно
    if (connector_id > 0) {
        condition = `ec.id = ${connector_id}`;
    }

    const [rows] = await connection.query(
        'select ste.ID,\n' +
        '       ste.name,\n' +
        '       ste.order_amount_usd,\n' +
        '       ste.leverage,\n' +
        '       ste.margin_mode,\n' +
        '       ste.close_position_mode,\n' +
        '       s.id      as strategy_id,\n' +
        '       s.name    as strategy_name,\n' +
        '       s.symbol,\n' +
        '       s.tv_url,\n' +
        '       s.equity_curve_url,\n' +
        '       s.loss_series_flag,\n' +
        '       ec.ID     as exchange_id,\n' +
        '       ec.name   as exchange_name,\n' +
        '       ec.exchange,\n' +
        '       ec.market as exchange_market,\n' +
        '       IF(ste.is_active = 1 and s.is_active = 1 and ec.is_active = 1, 1, 0) as is_active\n' +
        'from strategy_to_exchange ste,\n' +
        '     users u,\n' +
        '     strategy_stats s,\n' +
        '     exchange_connectors ec\n' +
        'where ste.user_id = u.ID\n' +
        '  and ste.strategy_id = s.id\n' +
        '  and ste.connector_id = ec.ID\n' +
        '  and u.is_active = 1\n' +
        '  and ' + condition + '\n' +
        '  and u.email = ?',
        [email]
    );
    return rows;
}

// add new Bot
async function addNewBot(connection, email, name, strategy_id, exchange_id, order_amount_usd, leverage, close_position_mode, margin_mode) {
    try {
        await connection.query(
            'insert into strategy_to_exchange (user_id, name, strategy_id, connector_id, order_amount_usd, leverage, margin_mode, close_position_mode, is_active)\n' +
            'values ((select ID from users where email = ?), ? ,?, ?, ?, ?, ?, ?, 0)',
            [email, name, strategy_id, exchange_id, order_amount_usd, leverage, margin_mode, close_position_mode]
        );
        return true;
    } catch (e) {
        console.log(e);
        return false;
    }
}

// update Bot
async function updateBot(connection, email, id, name, order_amount_usd, leverage, close_position_mode, margin_mode) {
    try {
        await connection.query(
            'update strategy_to_exchange\n' +
            'set name = ?,\n' +
            '    order_amount_usd = ?,\n' +
            '    leverage = ?,\n' +
            '    margin_mode = ?,\n' +
            '    close_position_mode = ?\n' +
            'where user_id = (select ID from users where email = ?) and\n' +
            '      ID = ?',
            [name, order_amount_usd, leverage, margin_mode, close_position_mode, email, id]
        );
        return true;
    } catch (e) {
        console.log(e);
        return false;
    }
}

// delete Bot
async function deleteBot(connection, email, id) {
    await connection.query(
        'delete from strategy_to_exchange\n' +
        'where user_id = (select ID from users where email = ?) and\n' +
        '      ID = ?',
        [email, id]
    );
    return true;
}

// changeActiveBot
async function changeActiveBot(connection, email, id, is_active) {
    await connection.query(
        'update strategy_to_exchange ste\n' +
        'set is_active = ?\n' +
        'where user_id = (select ID from users where email = ?) and\n' +
        '      ID = ?',
        [is_active, email, id]
    );
    return true;
}

// getNewBotsWebhook
// get BotsExchange
async function getNewBotsWebhook(connection, email) {
    const [rows] = await connection.query(
        'select ste.ID,\n' +
        '       ste.name,\n' +
        '       ste.order_amount_usd,\n' +
        '       ste.webhook_url,\n' +
        '       ste.message,\n' +
        '       ste.close_position_mode,\n' +
        '       s.id      as strategy_id,\n' +
        '       s.name    as strategy_name,\n' +
        '       IF(ste.is_active = 1 and s.is_active = 1, 1, 0) as is_active\n' +
        'from strategy_to_webhook ste,\n' +
        '     users u,\n' +
        '     strategy_stats s\n' +
        'where ste.user_id = u.ID\n' +
        '  and ste.strategy_id = s.id\n' +
        '  and u.is_active = 1\n' +
        '  and u.email = ?',
        [email]
    );
    return rows;
}

// addNewBotWebhook
async function addNewBotWebhook(connection, email, name, strategy_id, webhook_url, message, close_position_mode, order_amount_usd) {
    try {
        await connection.query(
            'insert into strategy_to_webhook (user_id, name, strategy_id, webhook_url, message, close_position_mode, order_amount_usd, is_active)\n' +
            'values ((select ID from users where email = ?), ? ,?, ?, ?, ?, ?, 0)',
            [email, name, strategy_id, webhook_url, message, close_position_mode, order_amount_usd]
        );
        return true;
    } catch (e) {
        console.log(e);
        return false;
    }
}

// updateBotWebhook
async function updateBotWebhook(connection, email, id, name, order_amount_usd, webhook_url, message, close_position_mode) {
    try {
        await connection.query(
            'update strategy_to_webhook\n' +
            'set name = ?,\n' +
            '    order_amount_usd = ?,\n' +
            '    webhook_url = ?,\n' +
            '    message = ?,\n' +
            '    close_position_mode = ?\n' +
            'where user_id = (select ID from users where email = ?) and\n' +
            '      ID = ?',
            [name, order_amount_usd, webhook_url, message, close_position_mode, email, id]
        );
        return true;
    } catch (e) {
        console.log(e);
        return false;
    }
}

// deleteBotWebhook
async function deleteBotWebhook(connection, email, id) {
    await connection.query(
        'delete from strategy_to_webhook\n' +
        'where user_id = (select ID from users where email = ?) and\n' +
        '      ID = ?',
        [email, id]
    );
    return true;
}

// changeActiveBotWebhook
async function changeActiveBotWebhook(connection, email, id, is_active) {
    await connection.query(
        'update strategy_to_webhook ste\n' +
        'set is_active = ?\n' +
        'where user_id = (select ID from users where email = ?) and\n' +
        '      ID = ?',
        [is_active, email, id]
    );
    return true;
}

// getStrategyStats
async function getStrategyStats(connection, is_active = 1) {
    const [rows] = await connection.query(
        'select st.*\n' +
        'from strategy_stats st\n' +
        'where st.is_active = ?\n' +
        '  and st.is_public = 1\n' +
        '  and st.is_free = 0\n' +
        'order by symbol, timeframe desc',
        [is_active]
    );
    return rows;
}

// get stats for all strategies
async function getAllStrategiesStats(connection, is_active = 1) {
    const [rows] = await connection.query(
        'WITH loss_streaks AS (SELECT td.strategy_id,\n' +
        '                             td.profit_usd,\n' +
        '                             CASE\n' +
        '                                 WHEN td.profit_usd < 0 THEN @loss_streak := @loss_streak + 1\n' +
        '                                 ELSE @loss_streak := 0\n' +
        '                                 END AS loss_streak\n' +
        '                      FROM trade_data td\n' +
        '                               JOIN strategy_stats ss ON td.strategy_id = ss.id,\n' +
        '                           (SELECT @loss_streak := 0) AS vars\n' +
        '                      ORDER BY td.strategy_id, td.trade_number),\n' +
        '     profit_calculations AS (SELECT td.strategy_id,\n' +
        '                                    SUM(CASE\n' +
        '                                            WHEN td.exit_datetime >= NOW() - INTERVAL 7 DAY THEN td.profit_percent\n' +
        '                                            ELSE 0 END)                                             AS profit_last_7_days,\n' +
        '                                    SUM(CASE\n' +
        '                                            WHEN td.exit_datetime >= NOW() - INTERVAL 30 DAY THEN td.profit_percent\n' +
        '                                            ELSE 0 END)                                             AS profit_last_30_days,\n' +
        '                                    SUM(CASE\n' +
        '                                            WHEN td.exit_datetime >= NOW() - INTERVAL 90 DAY THEN td.profit_percent\n' +
        '                                            ELSE 0 END)                                             AS profit_last_90_days,\n' +
        '                                    COUNT(td.strategy_id) /\n' +
        '                                    DATEDIFF(MAX(td.entry_datetime), MIN(td.entry_datetime))        AS avg_trades_per_day,\n' +
        '                                    COUNT(td.strategy_id) /\n' +
        '                                    (DATEDIFF(MAX(td.entry_datetime), MIN(td.entry_datetime)) / 7)  AS avg_trades_per_week,\n' +
        '                                    COUNT(td.strategy_id) /\n' +
        '                                    (DATEDIFF(MAX(td.entry_datetime), MIN(td.entry_datetime)) / 30) AS avg_trades_per_month,\n' +
        '                                    ROUND((EXP(SUM(LOG(1 + td.profit_percent / 100)) /\n' +
        '                                               DATEDIFF(NOW(), MIN(td.entry_datetime)) * 365) - 1) * 100, 2)          AS APY,\n' +
        '                                    ROUND(SUM(td.profit_percent) / DATEDIFF(NOW(), MIN(td.entry_datetime)) * 365, 2)   AS APR\n' +
        '                             FROM trade_data td\n' +
        '                                      JOIN strategy_stats ss ON td.strategy_id = ss.id AND ss.is_active = ? AND ss.is_public = 1 AND ss.is_free = 0\n' +
        '                             WHERE td.profit_percent IS NOT NULL\n' +
        '                             GROUP BY td.strategy_id)\n' +
        'SELECT pc.strategy_id,\n' +
        '       pc.profit_last_7_days,\n' +
        '       pc.profit_last_30_days,\n' +
        '       pc.profit_last_90_days,\n' +
        '       pc.avg_trades_per_day,\n' +
        '       pc.avg_trades_per_week,\n' +
        '       pc.avg_trades_per_month,\n' +
        '       pc.APY,\n' +
        '       pc.APR,\n' +
        '       MAX(ls.loss_streak) AS max_loss_streak\n' +
        'FROM profit_calculations pc\n' +
        '         JOIN loss_streaks ls ON pc.strategy_id = ls.strategy_id\n' +
        'GROUP BY pc.strategy_id;',
        [is_active]
    );
    return rows;
}

// get trades by strategy ID
async function getTradesByStrategyID(connection, strategyIds = [], period_in_days = '1825') {

    let condition = `AND (td.exit_datetime >= NOW() - INTERVAL ${parseInt(period_in_days)} DAY OR td.exit_datetime = "0000-00-00 00:00:00")`; // Условие, которое всегда ложно
    if (strategyIds.length > 0) {
        // convert array [1,2,3,4] - to string 1,2,3,4
        const placeholders = strategyIds.join(',');
        condition += ` AND td.strategy_id IN (${placeholders})`;
    }

    const [rows] = await connection.query(
        'select ss.id as strategy_id, ss.name as strategy_name, ss.symbol, td.trade_number, td.entry_type, DATE_FORMAT(td.entry_datetime, \'%Y-%m-%d %H:%i\') entry_datetime, td.entry_price, DATE_FORMAT(td.exit_datetime, \'%Y-%m-%d %H:%i\') exit_datetime, td.exit_price, td.profit_percent, td.drawdown_percent\n' +
        'from trade_data td,\n' +
        '     strategy_stats ss\n' +
        'where ss.id = td.strategy_id\n' +
        condition + '\n' +
        'order by td.entry_datetime desc',
        []
    );
    return rows;
}

// get logs signals Strategy
async function getLogsSignals(connection, user_id, connector_id, strategy_id) {
    const [rows] = await connection.query(
        'select DATE_FORMAT(s.date_time, \'%Y-%m-%d %H:%i\') as date_time, s.error_level, s.signal, s.message\n' +
        'from logs_signal s\n' +
        'where s.user_id = ?\n' +
        '  and connector_id = ?\n' +
        '  and strategy_id = ?\n' +
        'order by s.ID desc\n' +
        'LIMIT 100',
        [user_id, connector_id, strategy_id]
    );
    return rows;
}

// get logs signals Webhook
async function getLogsSignalsWebhook(connection, user_id, connector_id, webhook_id) {
    const [rows] = await connection.query(
        'select DATE_FORMAT(s.date_time, \'%Y-%m-%d %H:%i\') as date_time, s.error_level, s.signal, s.message\n' +
        'from logs_signal s\n' +
        'where s.user_id = ?\n' +
        '  and connector_id = ?\n' +
        '  and webhook_id = ?\n' +
        'order by s.ID desc\n' +
        'LIMIT 100',
        [user_id, connector_id, webhook_id]
    );
    return rows;
}

// get ref count and strategy count
async function getRefAndStrategyCount(connection, user_id) {
    const [rows] = await connection.query(
        'select count(u.id)                                        ref_count,\n' +
        '       count(distinct ss.id) - 3                          strategy_count,\n' +
        '       IF(count(u.id) >= count(distinct ss.id) - 3, 1, 0) is_have_free_slot\n' +
        'from users u\n' +
        '         left join strategy_to_exchange ss on ss.user_id = ? and ss.is_active = 1\n' +
        'where u.ref_user_id = ?\n' +
        '  and u.is_active = 1\n' +
        '  and u.date_registration >= NOW() - INTERVAL 30 DAY\n',
        [user_id, user_id]
    );
    return rows[0];
}

// getEquity
async function getEquity(connection, strategyIds = [], period_in_days = '1825', is_active = 1) {
    // Формирование условия для ss.id
    let condition = `td.exit_datetime >= NOW() - INTERVAL ${parseInt(period_in_days)} DAY`; // Условие, которое всегда ложно
    if (strategyIds.length > 0) {
        // convert array [1,2,3,4] - to string 1,2,3,4
        const placeholders = strategyIds.join(',');
        condition += ` AND ss.id IN (${placeholders})`;
    }

    // console.log(condition);

    // SQL-запрос
    const query_str = `
        WITH equity_calculation AS (SELECT DATE(td.exit_datetime)                                             AS exit_datetime_days,
                                           SUM(td.profit_percent)                                             AS profit_percent,
                                           SUM(SUM(td.profit_percent)) OVER (ORDER BY DATE(td.exit_datetime)) AS equity
                                    FROM trade_data td
                                             JOIN strategy_stats ss ON td.strategy_id = ss.id
                                    WHERE ss.is_active = ${is_active}
                                      AND ss.is_public = 1
                                      AND ss.is_free = 0
                                      AND td.exit_datetime <> '0000-00-00 00:00:00'
                                      AND td.profit_percent IS NOT NULL
                                      AND ${condition}
                                    GROUP BY DATE(td.exit_datetime)
                                    ORDER BY DATE(td.exit_datetime))
        SELECT DATE_FORMAT(exit_datetime_days, '%Y-%m-%d') AS exit_datetime_days,
               equity
        #                profit_percent,
#                equity - LAG(equity, 1, 0) OVER (ORDER BY exit_datetime_days) AS change_from_previous
        FROM equity_calculation
        ORDER BY exit_datetime_days;
    `;

    try {
        const [rows] = await connection.query(query_str, []);
        return rows;
    } catch (e) {
        console.log(e);
        return false;
    }
}

// getEquityStats
async function getEquityStats(connection, strategyIds = [], period_in_days = '1825', is_active = 1) {
    // Формирование условия для ss.id
    let condition = `td.exit_datetime >= NOW() - INTERVAL ${parseInt(period_in_days)} DAY`; // Условие, которое всегда ложно
    if (strategyIds.length > 0) {
        // convert array [1,2,3,4] - to string 1,2,3,4
        const placeholders = strategyIds.join(',');
        condition += ` AND ss.id IN (${placeholders})`;
    }

    // SQL-запрос
    const query_str = `
        WITH RECURSIVE
            trade_sequences AS (SELECT td.profit_percent,
                                       IF(profit_percent < 0, 1, 0)              as loss_streak,
                                       IF(profit_percent < 0, profit_percent, 0) as single_trade_loss
                                FROM trade_data td
                                         JOIN strategy_stats ss ON td.strategy_id = ss.id
                                WHERE ${condition}
                                  AND ss.is_active = ${is_active}
                                  AND ss.is_public = 1
                                  AND ss.is_free = 0
                                  AND td.exit_datetime <> '0000-00-00 00:00:00'
                                ORDER BY td.exit_datetime, td.profit_percent DESC),
            consecutive_losses AS (SELECT profit_percent,
                                          @streak := IF(loss_streak > 0, @streak + 1, 0)                 as streak_length,
                                          @running_loss :=
                                                  IF(loss_streak > 0, @running_loss + profit_percent, 0) as series_loss,
                                          single_trade_loss
                                   FROM trade_sequences
                                            CROSS JOIN (SELECT @streak := 0, @running_loss := 0) AS vars)
        SELECT MAX(streak_length)     as max_loss_streak_count,
               MIN(series_loss)       as max_drawdown_in_series,
               MIN(single_trade_loss) as max_single_trade_loss
        FROM consecutive_losses;
    `;

    try {
        const [rows] = await connection.query(query_str, []);
        return rows;
    } catch (e) {
        console.log(e);
        return false;
    }
}

// getMaxOpenTrades
async function getMaxOpenTrades(connection, strategyIds = [], period_in_days = '1825', is_active = 1) {
    // Формирование условия для ss.id
    let condition = `td.exit_datetime >= NOW() - INTERVAL ${parseInt(period_in_days)} DAY`; // Условие, которое всегда ложно
    if (strategyIds.length > 0) {
        // convert array [1,2,3,4] - to string 1,2,3,4
        const placeholders = strategyIds.join(',');
        condition += ` AND ss.id IN (${placeholders})`;
    }

    // SQL-запрос
    const query_str = `
        WITH position_changes AS (SELECT CAST(datetime AS DATETIME) as datetime,
                                         direction,
                                         SUM(position_change)       as total_change
                                  FROM (
                                           -- Входы в позицию (+1)
                                           SELECT td.entry_datetime as datetime,
                                                  td.direction,
                                                  1                 as position_change
                                           FROM trade_data td
                                                    JOIN strategy_stats ss ON td.strategy_id = ss.id
                                           WHERE ${condition}
                                             AND td.strategy_id IS NOT NULL
                                             AND td.entry_datetime IS NOT NULL
                                             AND ss.is_active = ${is_active}
                                             AND ss.is_public = 1
                                             AND ss.is_free = 0
                                           UNION ALL
                                           -- Выходы из позиции (-1)
                                           SELECT td.exit_datetime as datetime,
                                                  td.direction,
                                                  -1               as position_change
                                           FROM trade_data td
                                                    JOIN strategy_stats ss ON td.strategy_id = ss.id
                                           WHERE ${condition}
                                             AND td.strategy_id IS NOT NULL
                                             AND td.exit_datetime IS NOT NULL
                                             AND ss.is_active = ${is_active}
                                             AND ss.is_public = 1
                                             AND ss.is_free = 0) changes
                                  GROUP BY CAST(datetime AS DATETIME), direction),
             running_totals AS (SELECT datetime,
                                       direction,
                                       SUM(total_change) OVER (
                                           PARTITION BY direction
                                           ORDER BY datetime
                                           ROWS BETWEEN UNBOUNDED PRECEDING AND CURRENT ROW
                                           ) as concurrent_positions
                                FROM position_changes)
        SELECT MAX(CASE WHEN direction = 'Long' THEN concurrent_positions ELSE 0 END)  as max_concurrent_long,
               MAX(CASE WHEN direction = 'Short' THEN concurrent_positions ELSE 0 END) as max_concurrent_short,
               MAX(concurrent_positions)                                               as max_concurrent_both
        FROM running_totals;
    `;

    try {
        const [rows] = await connection.query(query_str, []);
        return rows;
    } catch (e) {
        console.log(e);
        return false;
    }
}

// getConnectedStrategies
async function getConnectedStrategies(connection, connector_id) {
    const [rows] = await connection.query(
        'select ss.id, ec.id, ec.name\n' +
        'from strategy_stats ss,\n' +
        '     exchange_connectors ec,\n' +
        '     strategy_to_exchange ste\n' +
        'where ss.id = ste.strategy_id\n' +
        '  and ec.id = ste.connector_id\n' +
        '  and ss.is_active = 1\n' +
        '  and ss.id_public = 1\n' +
        '  and ss.is_free = 0\n' +
        '  and ec.id = ?',
        [connector_id]
    );
    return rows;
}

// getNotConnectedStrategies
async function getNotConnectedStrategies(connection, connector_id) {
    const [rows] = await connection.query(
        'select ss.id, ec.id, ec.name\n' +
        'from strategy_stats ss,\n' +
        '     exchange_connectors ec,\n' +
        '     strategy_to_exchange ste\n' +
        'where ss.id = ste.strategy_id\n' +
        '  and ec.id = ste.connector_id\n' +
        '  and ss.is_active = 1\n' +
        '  and ss.id_public = 1\n' +
        '  and ss.is_free = 0\n' +
        '  and ec.id <> ?',
        [connector_id]
    );
    return rows;
}

module.exports = {
    addNewBot,
    addNewBotWebhook,
    changeActiveBot,
    changeActiveBotWebhook,
    changeActiveExchangeConnector,
    changeActiveWebhookConnector,
    changeDiscordName,
    changeLimitExchangeConnector,
    changeNameExchangeConnector,
    changeTelegramAIChatID,
    changeTelegramName,
    clearOTP,
    clearOTPExpired,
    createEmail,
    createExchangeConnector,
    createNewWebhook,
    deleteBot,
    deleteBotWebhook,
    deleteUserExchange,
    deleteUserWebhook,
    getAllStrategiesStats,
    getBots,
    getConnectedStrategies,
    getEquity,
    getEquityStats,
    getLogsSignals,
    getLogsSignalsWebhook,
    getMaxOpenTrades,
    getNewBotsWebhook,
    getNotConnectedStrategies,
    getRefAndStrategyCount,
    getStrategies,
    getStrategyStats,
    getTradesByStrategyID,
    getUserExchange,
    getUserIdByEmail,
    getUserProfile,
    getUsersWebhooks,
    isLogin,
    updateBot,
    updateBotWebhook,
    updateWebhook,
    writeOTP
}