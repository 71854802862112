import React, {useEffect, useState} from 'react';
import {Button, ConfigProvider, Dropdown, Table} from 'antd';
import {SettingOutlined} from '@ant-design/icons';

const formatNumber = (value, column) => {
    if (typeof value !== 'number') return value;

    const formattedValue = Number(Number(value).toFixed(2)).toString();
    const prefix = column.summaryPrefix || '';
    const suffix = column.summarySuffix || '';

    return `${prefix}${formattedValue}${suffix}`;
};

const CustomTable = ({columns: defaultColumns, dataSource, ...props}) => {
    const [visibleColumns, setVisibleColumns] = useState(() => {
        const initial = {};
        defaultColumns.forEach(col => {
            initial[col.key] = true;
        });
        return initial;
    });

    const [filteredData, setFilteredData] = useState([]);
    const [activeFilters, setActiveFilters] = useState({});

    useEffect(() => {
        setFilteredData(dataSource || []);
    }, [dataSource]);

    const handleMenuClick = (columnKey) => {
        setVisibleColumns(prev => ({
            ...prev,
            [columnKey]: !prev[columnKey]
        }));
    };

    const calculateAggregates = (data, column) => {
        if (!column.aggregate || !data?.length) return '-';

        let result;
        switch (column.aggregate) {
            case 'sum':
                result = data.reduce((sum, record) => sum + (Number(record[column.dataIndex]) || 0), 0);
                return formatNumber(result, column);

            case 'avg':
                const sum = data.reduce((acc, record) => acc + (Number(record[column.dataIndex]) || 0), 0);
                result = data.length ? sum / data.length : 0;
                return formatNumber(result, column);

            case 'min':
                result = Math.min(...data.map(record => Number(record[column.dataIndex]) || 0));
                return formatNumber(result, column);

            case 'max':
                result = Math.max(...data.map(record => Number(record[column.dataIndex]) || 0));
                return formatNumber(result, column);

            case 'custom':
                result = column.aggregateFunction?.(data);
                return typeof result === 'number' ? formatNumber(result, column) : result;

            case 'count':
                return `${column.summaryPrefix || ''}${data.length}${column.summarySuffix || ''}`;

            case 'none':
                return '-';

            default:
                return '-';
        }
    };

    const handleTableChange = (pagination, filters, sorter, {currentDataSource}) => {
        setFilteredData(currentDataSource);

        // Оновлюємо активні фільтри
        const newFilters = {};
        Object.entries(filters).forEach(([key, values]) => {
            if (values && values.length > 0) {
                const column = defaultColumns.find(col => col.dataIndex === key || col.key === key);
                newFilters[column?.title || key] = values.map(value => {
                    const filterOption = column?.filters?.find(f => f.value === value);
                    return filterOption?.text || value;
                });
            }
        });
        setActiveFilters(newFilters);

        props.onChange?.(pagination, filters, sorter, {currentDataSource});
    };

    const visibleColumnsList = defaultColumns.filter(col =>
        !col.hideable || visibleColumns[col.key]
    );

    const menuItems = {
        items: defaultColumns
            .filter(col => col.hideable)
            .map(col => ({
                key: col.key,
                label: (
                    <div onClick={(e) => {
                        e.stopPropagation();
                        handleMenuClick(col.key);
                    }}>
                        {visibleColumns[col.key] ? '✅' : '❌'} {col.title}
                    </div>
                )
            }))
    };

    const summaryRow = () => (
        <Table.Summary fixed>
            <Table.Summary.Row>
                {visibleColumnsList.map((column, index) => (
                    <Table.Summary.Cell
                        index={index}
                        key={column.key || column.dataIndex}
                        align={column.align}
                    >
                        <div style={{
                            backgroundColor: '#272762',
                            color: '#ffffff',
                            // padding: '8px 16px',
                            margin: '0px -16px',  // компенсуємо внутрішні відступи комірки
                            // height: '100%',
                        }}>
                            {column.aggregate ? (
                                <strong>
                                    {calculateAggregates(filteredData, column)}
                                </strong>
                            ) : index === 0 ? (
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                                >
                                    <strong>{`Total (${filteredData?.length || 0})`}</strong>
                                </div>
                            ) : '-'}
                        </div>
                    </Table.Summary.Cell>
                ))}
            </Table.Summary.Row>
        </Table.Summary>
    );

    const titleContent = (
        <>
            {props.title && <div>{props.title()}</div>}
            <div style={{
                display: 'flex',
                fontSize: 14,
                color: 'red',
                justifyContent: 'center',
                fontWeight: 700,
                margin: '0px 0px -30px 0px'
            }}
            >
                Use horizontal scrolling to view all data
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                // padding: '0px 0'
            }}>
                <Dropdown
                    menu={menuItems}
                    trigger={['click']}
                >
                    <Button icon={<SettingOutlined/>}>
                        Columns
                    </Button>
                </Dropdown>
            </div>
        </>
    );

    return (
        <ConfigProvider
            theme={{
                components: {
                    Table: {
                        fontFamily: 'Courier New',
                        // headerBorderRadius: '10px',
                        headerColor: '#ffffff',
                        headerBg: '#272762',
                        bodySortBg: '#ffffff',
                        headerSortHoverBg: '#272762',
                        headerSortActiveBg: '#272762',
                        cellFontSizeSM: "14px",
                        cellPaddingInline: '14px',
                        cellPaddingBlockSM: '2px',
                        cellPaddingInlineSM: '5px',
                        colorLinkActive: '#b21a2d',
                    },
                },
            }}
        >
            <Table
                columns={visibleColumnsList}
                dataSource={dataSource}
                {...props}
                onChange={handleTableChange}
                summary={summaryRow}
                title={() => titleContent}
            />
        </ConfigProvider>
    );
};

export default CustomTable;