import {Button, Modal} from "antd";
import React from "react";

function showModalDemoWarning() {
    return Modal.warning({
            title: 'This is FREE User Mode with limited access',
            width: 800,
            content: <>
                <h3>
                    This feature requires a subscription.<br/>
                    Unlock full access to all features and trading tools with a premium subscription.
                </h3>
            </>,
            footer: (_, {OkBtn, CancelBtn}) => (
                <>
                    <Button
                        style={{marginRight: 10, backgroundColor: '#e1f3f6', borderColor: '#82d976'}}
                        onClick={(e) => {
                            e.preventDefault();
                            window.location.href = 'https://cdzv.com/#pricing';
                        }}><strong>I want to pay and get FULL access</strong></Button>
                    <OkBtn/>
                </>
            ),
        },
    );
}

function showModalLimitWarning() {
    return Modal.warning({
            title: 'This is FREE User Mode with limited access',
            width: 800,
            content: <>
                <h3>
                    You have reached the limit of the free version.<br/>
                    Unlock full access to all features and trading tools with a premium subscription.
                </h3>
            </>,
            footer: (_, {OkBtn, CancelBtn}) => (
                <>
                    <Button
                        style={{marginRight: 10, backgroundColor: '#e1f3f6', borderColor: '#82d976'}}
                        onClick={(e) => {
                            e.preventDefault();
                            window.location.href = 'https://cdzv.com/#pricing';
                        }}><strong>I want to pay and get FULL access</strong></Button>
                    <OkBtn/>
                </>
            ),
        },
    );
}

export {
    showModalDemoWarning,
    showModalLimitWarning
}