import "./index.css"

function StrategyCard({strategy}) {

    if (!strategy) return null;

    const pnl_with_leverage = strategy.pnl_annual / strategy.leverage * strategy.max_leverage;

    const format_text = (text) => {
        // 1 -> 0001
        // 10 -> 0010
        // 100 -> 0100
        // 1000 -> 1000
        return text.toString().padStart(4, '0');
    }

    return (
        <div id={"reset-this-root"}>
            <div id="mainbanner">
                <div className="inside">
                    <div className="line1">
                        <span className="line1n0">#ID_{format_text(strategy.ID)}</span> | <span
                        className="line1n1">{strategy.market}</span> | <span
                        className="line1n2">{strategy.asset_type}</span>
                    </div>
                    <div className="line2">
                        {strategy.direction === 'LONG' && <div className="long">LONG</div>}
                        {strategy.direction === 'SHORT' && <div className="short">SHORT</div>}
                        {strategy.direction === 'BOTH' && <div className="both">BOTH</div>}
                        <div className="line8">
                            {/*<div className="line1n2"> | x{strategy.leverage} |</div>*/}
                            <div className="line1n2">|</div>
                            {/*<div className="line3">Leverage</div>*/}
                        </div>
                        <div className="line2n3">{strategy.symbol}</div>
                    </div>
                    <div className="line4"><span className="line1n2">Max Leverage</span> <span
                        className="line3n2">x{strategy.max_leverage}:</span> <span
                        className="line3n3">+{pnl_with_leverage}%</span>
                    </div>
                    <div className="line5"><span className="line5n1">+{strategy.pnl_annual}%</span>
                    </div>
                    <div className="line6">PNL Annual
                        <div className="loss0">Risk management:</div>
                        <div className="loss1">Pyramiding: <span>{strategy.pyramiding === 1 ? 'Yes' : 'No'}</span></div>
                        <div className="loss1">Drawdown: <span>{strategy.draw_down}%</span></div>
                        <div className="loss2">Loss series: <span>{strategy.max_loss_orders_session}</span></div>
                    </div>
                    <div className="line7 flex-container">
                        <div className="first">Win rate: <span className="line2n1">{strategy.win_rate}%</span></div>
                        <div className="second">Profit Factor: <span
                            className="line1n2">{strategy.profit_factor}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StrategyCard;