import React, {useEffect, useState} from "react";
import API from "../../../api/user";
import {Badge, Button, Card, Col, ConfigProvider, Image, Modal, Row, Skeleton} from "antd";
import {TinyColor} from '@ctrl/tinycolor';
import {mobXStore} from "../../../store";
import {AreaChartOutlined, LineChartOutlined, LinkOutlined, RobotOutlined} from "@ant-design/icons";
import {showModalDemoWarning} from "../../../Helpers";
import StrategyCardLite from "../../components/StrategyCardLite";

const content_msg = {
    "How to set up copying trades": {
        "en": "How to set up copying trades from a strategy to a crypto exchange?",
        "ua": "Як налаштувати копіювання угод з стратегії на криптобіржу?",
        "ru": "Как настроить копирование сделок из стратегии на криптобиржу?"
    },
    // "How to connect all crypto-strategies": {
    //     "en": "How to connect all crypto-strategies to exchange copy-trading?",
    //     "ua": "Як підключити всі крипто-стратегії до копіювання угод на біржі?",
    //     "ru": "Как подключить все крипто-стратегии к копированию сделок на бирже?"
    // },
    "Telegram channel with signals": {
        "en": "Telegram channel with signals",
        "ua": "Телеграм канал з сигналами",
        "ru": "Телеграм канал с сигналами"
    }
}

const TG_Channel_with_signals = 'https://t.me/+dOh0ycP9XgJkMTVi';

function Strategy(props) {

    const language = mobXStore.getKey('language');

    const colorsButton1 = ['#0f0fa8', '#617af8'];

    const apiUser = new API();

    const [showModal, setShowModal] = useState(false);

    const [loading, setLoading] = useState(false);

    const [strategyList, setStrategyList] = useState([]);

    const [search, setSearch] = useState([]);

    const [searchValue, setSearchValue] = useState('')

    const [demoMode, setDemoMode] = useState(false);

    const format_text = (text) => {
        // 1 -> 0001
        // 10 -> 0010
        // 100 -> 0100
        // 1000 -> 1000
        return text.toString().padStart(4, '0');
    }

    const getHoverColors = (colors) =>
        colors.map((color) => new TinyColor(color).lighten(5).toString());
    const getActiveColors = (colors) =>
        colors.map((color) => new TinyColor(color).darken(5).toString());

    const onSearch = (e) => {
        const value = e.target.value;
        setSearchValue(value);
        setSearch(strategyList.filter(module => {
            return (
                module.name.toLowerCase().includes(value.toLowerCase()) ||
                (module.direction.toLowerCase().includes(value.toLowerCase()) ||
                    (module.direction.toLowerCase() === 'both' && (value.toLowerCase() === 'long' ||
                        value.toLowerCase() === 'short'))) ||
                module.tags.toLowerCase().includes(value.toLowerCase()) ||
                module.asset_type.toLowerCase().includes(value.toLowerCase()) ||
                value.toLowerCase() === 'trader' && !module.is_investing ||
                value.toLowerCase() === 'investor' && module.is_investing ||
                value.toLowerCase() === 'working' && module.is_worked ||
                value.toLowerCase() === 'hold' && !module.is_worked ||
                value.toLowerCase() === 'free' && module.is_free ||
                value.toLowerCase() === 'pro' && !module.is_free ||
                value.toLowerCase() === 'crypto' && module.market === 'CRYPTO' ||
                value.toLowerCase() === 'stocks' && module.market !== 'CRYPTO' ||
                format_text(module.id).includes(value.toLowerCase())
            )
        }));
    }

    const onClickAddStrategyCrypto = (strategy) => {
        const strategyId = strategy.id;
        mobXStore.setKey('addStrategyID', strategyId);
        props.changeMenu('bots');
    }

    const onClickAddStrategyStock = () => {
        props.changeMenu('connectors');
        setTimeout(() => {
            props.changeMenu('gateways');
        }, 500);
    }

    useEffect(() => {
        setDemoMode(mobXStore.getKey('demo_mode'));
        setLoading(true);
        apiUser.getStrategy().then((response) => {
            if (response.result) {
                const res = JSON.parse(response.data).filter((item) => item.is_free === 1);
                setStrategyList(res);
                setSearch(res);
                // console.log(response.data);
            } else {
                // console.log(response.error);
                apiUser.logout();
            }
            setLoading(false);
        });
    }, []);

    /*
    {
    "ID": 1,
    "user_id": 1,
    "is_worked": 0,
    "name": "BTCUSDT futures ST1",
    "market": "CRYPTO",
    "symbol": "BTCUSDT.P",
    "direction": "LONG",
    "time_frame": "1h",
    "pyramiding": 0,
    "pnl_annual": 77,
    "win_rate": 81,
    "profit_factor": 5.6,
    "draw_down": 6.3,
    "max_loss_orders_session": 2,
    "tv_url": "https://www.tradingview.com/chart/J5Lx6PD5/",
    "photo_url": "https://cdzv.com/strategy_img/BTCUSDT.P_67413.1__0.81_Example4_state_machine_priv_2024-03-08_12-29-06.png",
    "leverage": 1,
    "max_leverage": 10,
    "risk_level": 1,
    "tags": "BTC",
    "is_public": 1
}
     */

    return (
        <>
            {/*<Button*/}
            {/*    // type="primary"*/}
            {/*    style={{*/}
            {/*        backgroundColor: 'rgb(222,223,250)',*/}
            {/*        position: 'fixed',*/}
            {/*        // bottom: 70,*/}
            {/*        top: 10,*/}
            {/*        right: 10,*/}
            {/*        zIndex: 1000,*/}
            {/*        borderRadius: 50,*/}
            {/*        fontWeight: 700,*/}
            {/*        fontFamily: 'Terminal, monospace',*/}
            {/*        boxShadow: '0 0 10px 0 rgba(0,0,0,0.5)',*/}
            {/*    }}*/}
            {/*    onClick={() => setShowModal(true)}*/}
            {/*>*/}
            {/*    <InfoCircleOutlined/> {content_msg["How to set up copying trades"][language]}*/}
            {/*</Button>*/}
            {/*<Button*/}
            {/*    // type="primary"*/}
            {/*    style={{*/}
            {/*        backgroundColor: 'rgb(222,223,250)',*/}
            {/*        position: 'fixed',*/}
            {/*        top: 50,*/}
            {/*        right: 10,*/}
            {/*        zIndex: 1000,*/}
            {/*        borderRadius: 50,*/}
            {/*        fontWeight: 700,*/}
            {/*        fontFamily: 'Terminal, monospace',*/}
            {/*        boxShadow: '0 0 10px 0 rgba(0,0,0,0.5)',*/}
            {/*    }}*/}
            {/*    onClick={() => {*/}
            {/*        if (language === 'en') {*/}
            {/*            window.open('https://docs.cdzv.com/code-zero-visual-trading_en/user-guide/how-to-connect-copy-trading', '_blank');*/}
            {/*        } else if (language === 'ua') {*/}
            {/*            window.open('https://docs.cdzv.com/code-zero-visual-trading_ua/posibnik-koristuvacha/yak-pidklyuchiti-copy-trading', '_blank');*/}
            {/*        } else if (language === 'ru') {*/}
            {/*            window.open('https://docs.cdzv.com/code-zero-visual-trading_ru/rukovodstvo-polzovatelya/kak-podklyuchit-copy-trading', '_blank');*/}
            {/*        }*/}

            {/*    }}*/}
            {/*>*/}
            {/*    <InfoCircleOutlined/> {content_msg["How to connect all crypto-strategies"][language]}*/}
            {/*</Button>*/}
            {/*<Button*/}
            {/*    // type="primary"*/}
            {/*    style={{*/}
            {/*        backgroundColor: 'rgb(130,217,118)',*/}
            {/*        position: 'fixed',*/}
            {/*        top: 10,*/}
            {/*        left: 210,*/}
            {/*        zIndex: 1000,*/}
            {/*        borderRadius: 50,*/}
            {/*        fontWeight: 700,*/}
            {/*        fontFamily: 'Terminal, monospace',*/}
            {/*        boxShadow: '0 0 10px 0 rgba(0,0,0,0.5)',*/}
            {/*    }}*/}
            {/*    onClick={e => {*/}
            {/*        e.preventDefault();*/}
            {/*        if (!demoMode) {*/}
            {/*            window.open(TG_Channel_with_signals, '_blank');*/}
            {/*        } else {*/}
            {/*            showModalDemoWarning();*/}
            {/*        }*/}
            {/*    }}>*/}
            {/*    <Tooltip*/}
            {/*        placement="top"*/}
            {/*        // overlayInnerStyle={{*/}
            {/*        //     width: '400px',*/}
            {/*        //     backgroundColor: '#efefefef',*/}
            {/*        //     color: 'red',*/}
            {/*        //     fontSize: '20px'*/}
            {/*        // }}*/}
            {/*        title={"In the 'Profile' section, you need to fill in the section with your Telegram name"}>*/}
            {/*        <InfoCircleOutlined/> {content_msg["Telegram channel with signals"][language]}*/}
            {/*    </Tooltip>*/}
            {/*</Button>*/}
            <Modal
                open={showModal}
                title="How to set up copying trades from a specific strategy to a crypto exchange?"
                width={800}
                onOk={() => setShowModal(false)}
                onCancel={() => setShowModal(false)}
            >
                <div>
                    <iframe width="100%" height="382px"
                            src="https://embed.app.guidde.com/playbooks/3qnW38VbJ4HNsZXXF226m1"
                            title="How to set up copy trade" frameBorder="0" referrerPolicy="unsafe-url"
                            allowFullScreen="true" allow="clipboard-write"
                            sandbox="allow-popups allow-popups-to-escape-sandbox allow-scripts allow-forms allow-same-origin allow-presentation"></iframe>
                    <p style={{display: "none"}}></p>
                </div>
            </Modal>
            <Row gutter={8} align={"middle"} justify={"center"}>
                <Col>
                    <span style={{
                        fontFamily: 'Courier New, monospace',
                        fontSize: '2em',
                        fontWeight: 700,
                    }}>
                            <LineChartOutlined/> Set of strategies created on CDZV Toolkit FREE
                    </span>
                    {/*<Search*/}
                    {/*    size="large"*/}
                    {/*    allowClear*/}
                    {/*    style={{marginTop: "100px", width: "500px", marginBottom: "15px"}}*/}
                    {/*    placeholder="input search text"*/}
                    {/*    onChange={onSearch}*/}
                    {/*    enterButton*/}
                    {/*    value={searchValue}*/}
                    {/*/>*/}
                    {/*<Space.Compact block direction={"horizontal"} wrap>*/}
                    {/*    <Tag key={'t0'}*/}
                    {/*         color={searchValue === "" ? "blue-inverse" : "gray"}*/}
                    {/*         style={{*/}
                    {/*             cursor: "pointer",*/}
                    {/*             fontSize: '16px',*/}
                    {/*             boxShadow: "5px 5px 5px rgb(150 50 100 / 50%)"*/}
                    {/*         }}*/}
                    {/*         onClick={() => {*/}
                    {/*             onSearch({*/}
                    {/*                 target: {*/}
                    {/*                     value: ""*/}
                    {/*                 }*/}
                    {/*             })*/}
                    {/*         }}*/}
                    {/*    >*/}
                    {/*        {searchValue === "" ? <strong>ALL</strong> : "ALL"}*/}
                    {/*    </Tag>*/}
                    {/*    <Tag key={'tfree'}*/}
                    {/*         color={searchValue === "FREE" ? "blue-inverse" : "gray"}*/}
                    {/*         style={{*/}
                    {/*             cursor: "pointer",*/}
                    {/*             fontSize: '16px',*/}
                    {/*             boxShadow: "5px 5px 5px rgb(150 50 100 / 50%)"*/}
                    {/*         }}*/}
                    {/*         onClick={() => {*/}
                    {/*             onSearch({*/}
                    {/*                 target: {*/}
                    {/*                     value: "FREE"*/}
                    {/*                 }*/}
                    {/*             })*/}
                    {/*         }}*/}
                    {/*    >*/}
                    {/*        {searchValue === "FREE" ? <strong>FREE</strong> : "FREE"}*/}
                    {/*    </Tag>*/}
                    {/*    <Tag key={'tpro'}*/}
                    {/*         color={searchValue === "PRO" ? "blue-inverse" : "gray"}*/}
                    {/*         style={{*/}
                    {/*             cursor: "pointer",*/}
                    {/*             fontSize: '16px',*/}
                    {/*             boxShadow: "5px 5px 5px rgb(150 50 100 / 50%)"*/}
                    {/*         }}*/}
                    {/*         onClick={() => {*/}
                    {/*             onSearch({*/}
                    {/*                 target: {*/}
                    {/*                     value: "PRO"*/}
                    {/*                 }*/}
                    {/*             })*/}
                    {/*         }}*/}
                    {/*    >*/}
                    {/*        {searchValue === "PRO" ? <strong>PRO</strong> : "PRO"}*/}
                    {/*    </Tag>*/}
                    {/*    <Tag key={'t1'}*/}
                    {/*         color={searchValue === "TRADER" ? "blue-inverse" : "gray"}*/}
                    {/*         style={{*/}
                    {/*             cursor: "pointer",*/}
                    {/*             fontSize: '16px',*/}
                    {/*             boxShadow: "5px 5px 5px rgb(150 50 100 / 50%)"*/}
                    {/*         }}*/}
                    {/*         onClick={() => {*/}
                    {/*             onSearch({*/}
                    {/*                 target: {*/}
                    {/*                     value: "TRADER"*/}
                    {/*                 }*/}
                    {/*             })*/}
                    {/*         }}*/}
                    {/*    >*/}
                    {/*        {searchValue === "TRADER" ? <strong>TRADER</strong> : "TRADER"}*/}
                    {/*    </Tag>*/}
                    {/*    <Tag key={'t2'}*/}
                    {/*         color={searchValue === "INVESTOR" ? "blue-inverse" : "gray"}*/}
                    {/*         style={{*/}
                    {/*             cursor: "pointer",*/}
                    {/*             fontSize: '16px',*/}
                    {/*             boxShadow: "5px 5px 5px rgb(150 50 100 / 50%)"*/}
                    {/*         }}*/}
                    {/*         onClick={() => {*/}
                    {/*             onSearch({*/}
                    {/*                 target: {*/}
                    {/*                     value: "INVESTOR"*/}
                    {/*                 }*/}
                    {/*             })*/}
                    {/*         }}*/}
                    {/*    >*/}
                    {/*        {searchValue === "INVESTOR" ? <strong>INVESTOR</strong> : "INVESTOR"}*/}
                    {/*    </Tag>*/}
                    {/*    <Tag key={'t5'}*/}
                    {/*         color={searchValue === "CRYPTO" ? "blue-inverse" : "gray"}*/}
                    {/*         style={{*/}
                    {/*             cursor: "pointer",*/}
                    {/*             fontSize: '16px',*/}
                    {/*             boxShadow: "5px 5px 5px rgb(150 50 100 / 50%)"*/}
                    {/*         }}*/}
                    {/*         onClick={() => {*/}
                    {/*             onSearch({*/}
                    {/*                 target: {*/}
                    {/*                     value: "CRYPTO"*/}
                    {/*                 }*/}
                    {/*             })*/}
                    {/*         }}*/}
                    {/*    >*/}
                    {/*        {searchValue === "CRYPTO" ? <strong>CRYPTO</strong> : "CRYPTO"}*/}
                    {/*    </Tag>*/}
                    {/*    <Tag key={'t6'}*/}
                    {/*         color={searchValue === "STOCKS" ? "blue-inverse" : "gray"}*/}
                    {/*         style={{*/}
                    {/*             cursor: "pointer",*/}
                    {/*             fontSize: '16px',*/}
                    {/*             boxShadow: "5px 5px 5px rgb(150 50 100 / 50%)"*/}
                    {/*         }}*/}
                    {/*         onClick={() => {*/}
                    {/*             onSearch({*/}
                    {/*                 target: {*/}
                    {/*                     value: "STOCKS"*/}
                    {/*                 }*/}
                    {/*             })*/}
                    {/*         }}*/}
                    {/*    >*/}
                    {/*        {searchValue === "STOCKS" ? <strong>STOCKS</strong> : "STOCKS"}*/}
                    {/*    </Tag>*/}
                    {/*    <Tag key={'t7'}*/}
                    {/*         color={searchValue === "SHORT" ? "blue-inverse" : "gray"}*/}
                    {/*         style={{*/}
                    {/*             cursor: "pointer",*/}
                    {/*             fontSize: '16px',*/}
                    {/*             boxShadow: "5px 5px 5px rgb(150 50 100 / 50%)"*/}
                    {/*         }}*/}
                    {/*         onClick={() => {*/}
                    {/*             onSearch({*/}
                    {/*                 target: {*/}
                    {/*                     value: "SHORT"*/}
                    {/*                 }*/}
                    {/*             })*/}
                    {/*         }}*/}
                    {/*    >*/}
                    {/*        {searchValue === "SHORT" ? <strong>SHORT</strong> : "SHORT"}*/}
                    {/*    </Tag>*/}
                    {/*    <Tag key={'t8'}*/}
                    {/*         color={searchValue === "LONG" ? "blue-inverse" : "gray"}*/}
                    {/*         style={{*/}
                    {/*             cursor: "pointer",*/}
                    {/*             fontSize: '16px',*/}
                    {/*             boxShadow: "5px 5px 5px rgb(150 50 100 / 50%)"*/}
                    {/*         }}*/}
                    {/*         onClick={() => {*/}
                    {/*             onSearch({*/}
                    {/*                 target: {*/}
                    {/*                     value: "LONG"*/}
                    {/*                 }*/}
                    {/*             })*/}
                    {/*         }}*/}
                    {/*    >*/}
                    {/*        {searchValue === "LONG" ? <strong>LONG</strong> : "LONG"}*/}
                    {/*    </Tag>*/}
                    {/*</Space.Compact>*/}
                </Col>
            </Row>
            <Skeleton loading={loading}>
                <Row
                    gutter={[12, 12]}
                    align={"middle"}
                    justify={"center"}
                    style={{marginTop: 10, marginBottom: 10}}
                >
                    {search.map((strategy, index) => (
                        <Col xl={10} xs={22} lg={22} md={22} sm={22}>
                            <Card size={"small"}
                                // title={<>#ID_{format_text(strategy.ID)} | {strategy.market}</>}
                            >
                                <Badge.Ribbon
                                    placement="start"
                                    text={strategy.is_free ? "FREE" : "PRO"}
                                    color={strategy.is_free ? "red" : "green"}
                                >
                                    <Badge.Ribbon
                                        placement="end"
                                        text={strategy.is_investing ? "INVESTOR" : "TRADER"}
                                        color={strategy.is_investing ? "blue" : "magenta"}
                                    >
                                        {/*<Tooltip title='Click to go to the ready-made strategy'>*/}
                                        {/*<a href={strategy.tv_url} target="_blank" rel="noreferrer">*/}
                                        <StrategyCardLite strategy={{
                                            'id': strategy.name,
                                            'pnl_annual': strategy.net_profit_percentage,
                                            'leverage': strategy.leverage,
                                            'market': strategy.market,
                                            'direction': strategy.direction,
                                            'symbol': strategy.symbol.replace('USDT.P', '').replace('BINANCE:', ''),
                                            'draw_down': strategy.max_drawdown_percentage,
                                            'max_loss_orders_session': strategy.max_loss_streak,
                                            'win_rate': strategy.percent_profitable_all,
                                            'profit_factor': strategy.profit_factor_all
                                        }}/>
                                        {/*</a>*/}
                                        <ConfigProvider
                                            theme={{
                                                components: {
                                                    Button: {
                                                        colorPrimary: `linear-gradient(135deg, ${colorsButton1.join(', ')})`,
                                                        colorPrimaryHover: `linear-gradient(135deg, ${getHoverColors(colorsButton1).join(', ')})`,
                                                        colorPrimaryActive: `linear-gradient(135deg, ${getActiveColors(colorsButton1).join(', ')})`,
                                                        lineWidth: 0,
                                                    },
                                                },
                                            }}
                                        >
                                            <Button
                                                type="primary"
                                                size="large"
                                                onClick={(e) => {
                                                    Modal.info(
                                                        {
                                                            title: 'Backtest',
                                                            content: (
                                                                <>
                                                                    <Image src={strategy.equity_curve_url}
                                                                           width={"100%"}
                                                                           preview={false}
                                                                    />
                                                                </>
                                                            ),
                                                            width: '95%',
                                                            style: {marginTop: "-90px"},
                                                        }
                                                    )
                                                }}
                                            >
                                                <AreaChartOutlined/> Backtest
                                            </Button> <Button type="primary"
                                                              size="large"
                                                              style={{marginTop: 10}}
                                                              onClick={e => {
                                                                  e.preventDefault();
                                                                  if (!demoMode || strategy.is_free) {
                                                                      window.open(strategy.tv_url, '_blank');
                                                                  } else {
                                                                      showModalDemoWarning();
                                                                  }
                                                              }}>
                                            <LinkOutlined/> TradingView
                                        </Button>
                                            {(strategy.is_copy_trade === 1 && strategy.market === 'CRYPTO') &&
                                                (<>
                                                        &nbsp;<Button type="primary"
                                                                      size="large"
                                                                      style={{marginTop: 10}}
                                                                      onClick={e => {
                                                                          e.preventDefault();
                                                                          onClickAddStrategyCrypto(strategy)
                                                                      }}>
                                                        <RobotOutlined/> Copy trades
                                                    </Button>
                                                    </>
                                                )}
                                            {(strategy.is_copy_trade === 1 && strategy.market !== 'CRYPTO') &&
                                                (<>
                                                        &nbsp;<Button type="primary"
                                                                      size="large"
                                                                      style={{marginTop: 10}}
                                                                      onClick={e => {
                                                                          e.preventDefault();
                                                                          onClickAddStrategyStock();
                                                                      }}>
                                                        <RobotOutlined/> Copy trades
                                                        {/*({strategy.market === 'CRYPTO' ? <><span*/}
                                                        {/*style={{color: "#8fef1b", fontWeight: 700}}>E</span> | <span*/}
                                                        {/*style={{color: "#ffabad", fontWeight: 700}}>W</span></> : <span*/}
                                                        {/*style={{color: "#ffabad", fontWeight: 700}}>W</span>})*/}
                                                    </Button>
                                                    </>
                                                )}
                                        </ConfigProvider>
                                        {/*</Tooltip>*/}
                                    </Badge.Ribbon>
                                </Badge.Ribbon>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Skeleton>
        </>
    );
}

export default Strategy;