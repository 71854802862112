import React, {useEffect, useState} from 'react';
import {ConfigProvider, Table,} from 'antd';

import {CaretDownOutlined, CaretUpOutlined, ClockCircleOutlined, FullscreenExitOutlined,} from "@ant-design/icons";
import API from "../../../api/user";
import {mobXStore} from "../../../store";

// SignalLogs component
function TradesList({strategy_id, show_extended = false, period_in_days = 9999}) {

    if (!strategy_id || strategy_id.length === 0) return null;

    const language = mobXStore.getKey('language');

    const apiUser = new API();

    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState(false);

    const sortIcon = (sorted) => {
        // alert(JSON.stringify(sorted))
        if (sorted['sortOrder'] === 'descend') {
            return (<CaretDownOutlined
                style={{
                    color: '#1677ff'
                }}
            />)
        } else if (sorted['sortOrder'] === 'ascend') {
            return (<CaretUpOutlined
                style={{
                    color: '#1677ff'
                }}
            />)
        } else {
            return (<FullscreenExitOutlined
                style={{
                    color: 'white',
                }}
            />)
        }
    }

    function getTradesList(strategy_id) {
        setLoading(true);
        setDataSource([]);
        apiUser.getTradesByStrategyID({strategy_id: strategy_id, period_in_days: period_in_days}).then((response) => {
            if (response.result) {
                // console.log(response);
                setDataSource(JSON.parse(response.data));
            }
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setLoading(false);
        });
    }

    useEffect(() => {
        getTradesList(strategy_id);
    }, [strategy_id, period_in_days]);

    let columns = [
        {
            title: 'N',
            dataIndex: 'trade_number',
            align: 'center',
            width: '170px',
            // fixed: 'left',
            // defaultSortOrder: 'descend',
            sortIcon: (sorted) => sortIcon(sorted),
            sorter: (a, b) => a.trade_number - b.trade_number,
            // ...getColumnSearchProps('trade_number', 'Search by Trade number'),
        },
        {
            title: 'Type',
            dataIndex: 'entry_type',
            // width: '15%',
            align: 'center',
            render: (text) => {
                if (text.toLowerCase().includes('sell')) {
                    //  red
                    return <><CaretDownOutlined style={{color: 'red', fontSize: 18}}/> {text}</>
                } else {
                    //  green
                    return <><CaretUpOutlined style={{color: 'green', fontSize: 18}}/> {text}</>
                }
            },
            // fixed: 'left',
        },
        {
            title: 'Entry Date/Time',
            dataIndex: 'entry_datetime',
            // width: '15%',
            align: 'center',
            sortIcon: (sorted) => sortIcon(sorted),
            sorter: (a, b) => a.entry_datetime.localeCompare(b.entry_datetime)
        },

        {
            title: 'Entry Price',
            dataIndex: 'entry_price',
            // width: '15%',
            align: 'center',
        },
        {
            title: 'Exit Date/Time',
            dataIndex: 'exit_datetime',
            align: 'center',
            sortIcon: (sorted) => sortIcon(sorted),
            sorter: (a, b) => a.exit_datetime.localeCompare(b.exit_datetime),
            render: (text) => {
                if (text === '0000-00-00 00:00') {
                    return <><ClockCircleOutlined style={{fontSize: 20, color: '#b21a2d'}}/></>
                } else {
                    return <>{text}</>
                }
            }
        },
        {
            title: 'Exit Price',
            dataIndex: 'exit_price',
            align: 'center',
            render: (text, rec) => {
                if (rec.exit_datetime === '0000-00-00 00:00') {
                    return <><ClockCircleOutlined style={{fontSize: 20, color: '#b21a2d'}}/></>
                } else {
                    return <>{text}</>
                }
            }
        },
        {
            title: 'Profit, %',
            dataIndex: 'profit_percent',
            align: 'center',
            // width: '100px',
            render: (text, rec) => {
                if (rec.exit_datetime === '0000-00-00 00:00') {
                    return <><ClockCircleOutlined style={{fontSize: 20, color: '#b21a2d'}}/></>
                } else {
                    if (text < 0) {
                        //  red
                        return <><strong style={{color: 'red'}}>{text}%</strong></>
                    } else {
                        //  green
                        return <><strong style={{color: 'green'}}>{text}%</strong></>
                    }
                }
            }
        },
        {
            title: 'Drawdown, %',
            dataIndex: 'drawdown_percent',
            align: 'center',
            render: (text, rec) => {
                if (rec.exit_datetime === '0000-00-00 00:00') {
                    return <><ClockCircleOutlined style={{fontSize: 20, color: '#b21a2d'}}/></>
                } else {
                    return <>{text}</>
                }
            }
        },
    ];

    if (show_extended) {
        columns = [
            {
                title: 'Strategy',
                dataIndex: 'strategy_id',
                align: 'center',
                render: (text, record) => {
                    return <>{text} | {record.strategy_name}</>
                }
                // width: '170px',
                // fixed: 'left',
                // defaultSortOrder: 'descend',
                // sortIcon: (sorted) => sortIcon(sorted),
                // sorter: (a, b) => a.trade_number - b.trade_number,
                // ...getColumnSearchProps('trade_number', 'Search by Trade number'),
            },
            {
                title: 'Symbol',
                dataIndex: 'symbol',
                align: 'center',
                render: (text) => {
                    return <>{text.replace('BINANCE:', '').replace('.P', '').replace('USDT', '/USDT')}</>
                }
                // width: '170px',
                // fixed: 'left',
                // defaultSortOrder: 'descend',
                // sortIcon: (sorted) => sortIcon(sorted),
                // sorter: (a, b) => a.trade_number - b.trade_number,
                // ...getColumnSearchProps('trade_number', 'Search by Trade number'),
            },
            // {
            //     title: 'N',
            //     dataIndex: 'trade_number',
            //     align: 'center',
            //     width: '170px',
            //     // fixed: 'left',
            //     // defaultSortOrder: 'descend',
            //     sortIcon: (sorted) => sortIcon(sorted),
            //     sorter: (a, b) => a.trade_number - b.trade_number,
            //     // ...getColumnSearchProps('trade_number', 'Search by Trade number'),
            // },
            {
                title: 'Type',
                dataIndex: 'entry_type',
                // width: '15%',
                align: 'center',
                render: (text) => {
                    if (text.toLowerCase().includes('sell')) {
                        //  red
                        return <><CaretDownOutlined style={{color: 'red', fontSize: 18}}/> {text}</>
                    } else {
                        //  green
                        return <><CaretUpOutlined style={{color: 'green', fontSize: 18}}/> {text}</>
                    }
                },
                // fixed: 'left',
            },
            {
                title: 'Entry Date/Time',
                dataIndex: 'entry_datetime',
                // width: '15%',
                align: 'center',
                sortIcon: (sorted) => sortIcon(sorted),
                sorter: (a, b) => a.entry_datetime.localeCompare(b.entry_datetime),
            },

            {
                title: 'Entry Price',
                dataIndex: 'entry_price',
                // width: '15%',
                align: 'center',
            },
            {
                title: 'Exit Date/Time',
                dataIndex: 'exit_datetime',
                align: 'center',
                sortIcon: (sorted) => sortIcon(sorted),
                sorter: (a, b) => a.exit_datetime.localeCompare(b.exit_datetime),
                render: (text) => {
                    if (text === '0000-00-00 00:00') {
                        return <><ClockCircleOutlined style={{fontSize: 20, color: '#b21a2d'}}/></>
                    } else {
                        return <>{text}</>
                    }
                }
            },
            {
                title: 'Exit Price',
                dataIndex: 'exit_price',
                align: 'center',
                render: (text, rec) => {
                    if (rec.exit_datetime === '0000-00-00 00:00') {
                        return <><ClockCircleOutlined style={{fontSize: 20, color: '#b21a2d'}}/></>
                    } else {
                        return <>{text}</>
                    }
                }
            },
            {
                title: 'Profit, %',
                dataIndex: 'profit_percent',
                align: 'center',
                // width: '100px',
                render: (text, rec) => {
                    if (rec.exit_datetime === '0000-00-00 00:00') {
                        return <><ClockCircleOutlined style={{fontSize: 20, color: '#b21a2d'}}/></>
                    } else {
                        if (text < 0) {
                            //  red
                            return <><strong style={{color: 'red'}}>{text}%</strong></>
                        } else {
                            //  green
                            return <><strong style={{color: 'green'}}>{text}%</strong></>
                        }
                    }
                }
            },
            {
                title: 'Drawdown, %',
                dataIndex: 'drawdown_percent',
                align: 'center',
                render: (text, rec) => {
                    if (rec.exit_datetime === '0000-00-00 00:00') {
                        return <><ClockCircleOutlined style={{fontSize: 20, color: '#b21a2d'}}/></>
                    } else {
                        return <>{text}</>
                    }
                }
            },
        ];
    }

    return (
        <ConfigProvider
            theme={{
                components: {
                    Table: {
                        // fontSize: "16px",
                        // fontFamily: 'Courier New',
                        headerBorderRadius: '10px',
                        headerColor: '#ffffff',
                        headerBg: '#272762',
                        bodySortBg: '#ffffff',
                        headerSortHoverBg: '#272762',
                        headerSortActiveBg: '#272762',
                        cellFontSizeSM: "14px",
                        cellPaddingInline: '10px',
                        cellPaddingBlockSM: '5px',
                        cellPaddingInlineSM: '5px',
                        colorLinkActive: '#b21a2d',
                    },
                },
            }}
        >
            <Table
                scroll={{x: 'max-content'}}
                rowKey={record => record.key}
                size="small"
                pagination={{
                    pageSizeOptions: show_extended ? [5, 10, 20, 50, 100, 500, 1000] : [9999],
                    hideOnSinglePage: true,
                    defaultPageSize: show_extended ? 20 : 9999,
                }}
                loading={loading}
                style={{
                    marginTop: '0%',
                    marginLeft: '0%',
                    marginRight: '0%',
                }}
                bordered={true}
                dataSource={dataSource}
                columns={columns}
                onRow={(record) => {

                    return {
                        style: {
                            backgroundColor: record.exit_datetime === '0000-00-00 00:00' ? '#cfe7f1' : (record.profit_percent > 0 ? '#d9f6d7' : '#f5dfdf')
                        }
                        // onClick: () => handleEdit(record.key), // click row
                        // onDoubleClick: () => alert(record.id), // double click row
                        // onContextMenu: event => {
                        // }, // right button click row
                        // onMouseEnter: event => {
                        // }, // mouse enter row
                        // onMouseLeave: event => {
                        // }, // mouse leave row
                    };
                }}
            />
        </ConfigProvider>
    );
}

export default TradesList;