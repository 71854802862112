const exchanges = [
    {
        name: "Binance",
        ccxt_name: "binance",
        description: "BINANCE crypto exchange",
        logo: "https://panel.cdzv.com/logos/Binance.svg",
        manual: "https://docs.cdzv.com/code-zero-visual-trading_en/user-guide/how-to-create-api-keys-on-binance",
        password: false,
    },
    {
        name: "OKX",
        ccxt_name: "okx",
        description: "OKX crypto exchange",
        logo: "https://panel.cdzv.com/logos/okx.svg",
        manual: "https://docs.cdzv.com/code-zero-visual-trading_en/user-guide/how-to-create-api-keys-on-okx",
        password: true,
    },
    {
        name: "ByBit (your account must be in UTA mode)",
        ccxt_name: "bybit",
        description: "ByBit crypto exchange",
        logo: "https://panel.cdzv.com/logos/Bybit.svg",
        manual: "https://docs.cdzv.com/code-zero-visual-trading_en/user-guide/how-to-create-api-keys-on-bybit",
        password: false,
    },
    {
        name: "HTX (soon)",
        ccxt_name: "htx",
        description: "HTX crypto exchange",
        logo: "https://panel.cdzv.com/logos/HTX.svg",
        manual: "",
        password: false,
    },
    {
        name: "Kucoin (soon)",
        ccxt_name: "kucoin",
        description: "Kucoin crypto exchange",
        logo: "https://panel.cdzv.com/logos/Kucoin.svg",
        manual: "",
        password: true,
    },
];

export default exchanges;