import "./index.css"

function StrategyCardLite({strategy}) {

    if (!strategy) return null;

    const pnl_with_leverage = strategy.pnl_annual > 1 ? Math.floor(strategy.pnl_annual) : Math.ceil(strategy.pnl_annual);
    const win_rate = strategy.win_rate > 1 ? Math.floor(strategy.win_rate) : Math.ceil(strategy.win_rate);
    const draw_down = strategy.draw_down > 1 ? Math.ceil(strategy.draw_down) : Math.ceil(strategy.draw_down);
    const profit_factor = strategy.profit_factor > 1 ? Math.floor(strategy.profit_factor * 100) / 100 : Math.ceil(strategy.profit_factor * 100) / 100;

    const format_text = (text) => {
        // 1 -> 0001
        // 10 -> 0010
        // 100 -> 0100
        // 1000 -> 1000
        return text.toString().padStart(4, '0');
    }

    return (
        <div id={"reset-this-root"}>
            <div id="mainbanner">
                <div className="inside">
                    <div className="line1">
                        <span className="line1n0">#{format_text(strategy.id)}</span> | <span
                        className="line1n1">{strategy.market}</span>
                    </div>
                    <div className="line2">
                        {strategy.direction === 'LONG' && <div className="long">LONG</div>}
                        {strategy.direction === 'SHORT' && <div className="short">SHORT</div>}
                        {strategy.direction === 'BOTH' && <div className="both">BOTH</div>}
                        <div className="line8">
                            {/*<div className="line1n2"> | x{strategy.leverage} |</div>*/}
                            {/*<div className="line1n2">|</div>*/}
                            {/*<div className="line3">Leverage</div>*/}
                        </div>
                        <div className="line2n3"> | {strategy.symbol}</div>
                    </div>
                    <div className="line4"><span className="line1n2">Leverage</span> <span
                        className="line3n2">{strategy.leverage}</span>
                    </div>
                    {strategy.pnl_annual > 0 ?
                        <div className="line5"><span className="line5n1">+{pnl_with_leverage}%</span></div> :
                        <div className="line5"><span className="line5n1red">{pnl_with_leverage}%</span></div>
                    }
                    <div className="line6">Net Profit
                        <div className="loss0">Risk management:</div>
                        {/*<div className="loss1">Pyramiding: <span>{strategy.pyramiding === 1 ? 'Yes' : 'No'}</span></div>*/}
                        <div className="loss1">Drawdown: <span>{draw_down}%</span></div>
                        {strategy.max_loss_orders_session ?
                            <div className="loss2">Loss series: <span>{strategy.max_loss_orders_session}</span>
                            </div> : null}
                    </div>
                    <div className="line7 flex-container">
                        <div className="first">Win rate: <span className="line2n1">{win_rate}%</span></div>
                        <div className="second">Profit Factor: <span
                            className="line1n2">{profit_factor}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StrategyCardLite;