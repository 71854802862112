import React, {useEffect, useRef, useState} from 'react';
import {
    Button,
    Card,
    Col,
    ConfigProvider,
    Divider,
    Image,
    Input,
    Modal,
    notification,
    Popconfirm,
    Row,
    Select,
    Space,
    Spin,
    Switch,
    Table,
    Tooltip
} from 'antd';
import {Content} from "antd/es/layout/layout";

import exchanges from "./exchanges";
import {
    AlertOutlined,
    CaretDownOutlined,
    CaretUpOutlined,
    ClearOutlined,
    CloseOutlined,
    ControlOutlined,
    CopyOutlined,
    DeleteOutlined,
    EditOutlined,
    FullscreenExitOutlined,
    InfoCircleOutlined, OrderedListOutlined,
    PlusCircleOutlined, ProfileFilled,
    QuestionCircleOutlined,
    SearchOutlined
} from "@ant-design/icons";
import API from "../../../api/user";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {Link} from "react-router-dom";
import {mobXStore} from "../../../store";
import {showModalLimitWarning} from "../../../Helpers";
import {runInAction} from "mobx";

const LIMIT_CONNECTIONS = 1;

const content_msg = {
    "How do i add an exchange": {
        "en": "How to set up a connection to the exchange?",
        "ua": "Як налаштувати підключення до біржі?",
        "ru": "Как настроить подключение к бирже?"
    }
}

const url_msg = {
    "How do i add an exchange": {
        "en": "https://youtu.be/PDKPZ_9Vehg",
        "ua": "https://youtu.be/dwvNdXdyOfo",
        "ru": "https://youtu.be/dwvNdXdyOfo"
    }
}

const filterOption = (input, option) =>
    (option?.search_label ?? '').toLowerCase().includes(input.toLowerCase());

// SignalLogs component
function Connectors(props) {

    const language = mobXStore.getKey('language');

    const apiUser = new API();

    const exchanges_list = exchanges.map((exchange, index) => {
        return {
            value: exchange.ccxt_name,
            search_label: exchange.name,
            label: <span>{exchange.name}</span>
        }
    })

    const inputName = useRef(null);
    const inputNameChange = useRef(null);
    const inputPassword = useRef(null);
    const inputPubApiKey = useRef(null);
    const inputSecApiKey = useRef(null);
    const inputLongLimit = useRef(null);
    const inputShortLimit = useRef(null);

    // const [onlyOneAssets, setOnlyOneAssets] = useState(0);

    const [demoMode, setDemoMode] = useState(false);

    const [spin_connection, setSpinConnection] = useState(false);

    const [showPasswordInput, setShowPasswordInput] = useState(false);

    const [showAddConnectionModal, setShowAddConnectionModal] = useState(false);

    const [typeExchange, setTypeExchange] = useState('spot');
    const [ccxtExchange, setCcxtExchange] = useState(exchanges_list[0].value);
    const [ccxtExchangeManual, setCcxtExchangeManual] = useState('');

    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState(false);

    // const [showModal, setShowModal] = useState(false);

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const [apiNotification, contextHolder] = notification.useNotification();

    const openErrorNotification = (message) => {
        apiNotification.error({
            type: 'error',
            message: 'Error',
            description: message,
            placement: 'topRight',
            duration: 3,
        });
    };

    const openSuccessNotification = (message) => {
        apiNotification.open({
            type: 'success',
            message: 'Success',
            description: message,
            placement: 'topRight',
            duration: 3,
        });
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const sortIcon = (sorted) => {
        // alert(JSON.stringify(sorted))
        if (sorted['sortOrder'] === 'descend') {
            return (<CaretDownOutlined
                style={{
                    color: '#1677ff'
                }}
            />)
        } else if (sorted['sortOrder'] === 'ascend') {
            return (<CaretUpOutlined
                style={{
                    color: '#1677ff'
                }}
            />)
        } else {
            return (<FullscreenExitOutlined
                style={{
                    color: 'white',
                }}
            />)
        }
    }

    const getColumnSearchProps = (dataIndex, tooltip_text = '') => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (
            <div
                style={{
                    // backgroundColor: '#0f0fa8',
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Space direction={'horizontal'}>
                    <Input
                        ref={searchInput}
                        placeholder={tooltip_text}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{
                            marginBottom: 8,
                            width: 200,
                            display: 'block',
                        }}
                    />
                    <Button
                        type="button"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        <CloseOutlined/>
                    </Button>
                </Space>
                <br/>
                <Space direction={'horizontal'}>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined/>}
                        size="small"
                        style={{
                            width: 115,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && (handleReset(clearFilters) || handleSearch(selectedKeys, confirm, dataIndex) || close())}
                        size="small"
                        style={{
                            width: 115,
                        }}
                    >
                        <ClearOutlined/> Reset
                    </Button>
                    {/*<Button*/}
                    {/*    type="link"*/}
                    {/*    size="small"*/}
                    {/*    onClick={() => {*/}
                    {/*        confirm({*/}
                    {/*            closeDropdown: false,*/}
                    {/*        });*/}
                    {/*        setSearchText(selectedKeys[0]);*/}
                    {/*        setSearchedColumn(dataIndex);*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    Filter*/}
                    {/*</Button>*/}
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#f6adb5' : 'white',
                }}
            />
        ),
        sortIcon: (sorted) => sortIcon(sorted),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        // render: (text) =>
        //     searchedColumn === dataIndex ? (
        //         <Highlighter
        //             highlightStyle={{
        //                 backgroundColor: '#ffc069',
        //                 padding: 0,
        //             }}
        //             searchWords={[searchText]}
        //             autoEscape
        //             textToHighlight={text ? text.toString() : ''}
        //         />
        //     ) : (
        //         text
        //     ),
    });

    useEffect(() => {
    }, [typeExchange, ccxtExchange]);

    useEffect(() => {
        mobXStore.setKey('only_one_asset', 0);
    }, [setShowAddConnectionModal]);

    function handleDeleteConnection(key) {

        // if (demoMode) {
        //     showModalDemoWarning();
        //     return;
        // }

        setLoading(true);
        apiUser.deleteUserExchange({id: key}).then((response) => {
            if (response.result) {
                getUserExchange();
                openSuccessNotification('Connection deleted successfully');
                // Modal.success({
                //     title: 'Success',
                //     content: 'Connection deleted successfully',
                // });
            } else {
                // console.log(response.error);
                Modal.error({
                    title: 'Error',
                    content: 'Connection delete error: ' + response.error,
                });
            }
            setLoading(false);
        });
    }

    function handleChangeActive(key, is_active) {
        // if (demoMode) {
        //     showModalDemoWarning();
        //     return;
        // }

        setLoading(true);
        apiUser.changeActiveExchangeConnector({id: key, is_active: is_active}).then((response) => {
            if (response.result) {
                getUserExchange();
                openSuccessNotification('Connection status changed successfully');
                // Modal.success({
                //     title: 'Success',
                //     content: 'Connection status changed successfully',
                // });
            } else {
                // console.log(response.error);
                Modal.error({
                    title: 'Error',
                    content: 'Connection status change error: ' + response.error,
                });
            }
            setLoading(false);
        });
    }

    // Save new connection to DB
    const saveNewConnectToDB = () => {

        // if (demoMode) {
        //     showModalDemoWarning();
        //     return;
        // }

        const name = inputName.current.input.value;
        const pubKey = inputPubApiKey.current.input.value;
        const secKey = inputSecApiKey.current.input.value;
        const password = inputPassword.current.input.value || '';
        const exchange = ccxtExchange;
        const type = typeExchange;
        const longLimit = inputLongLimit.current.input.value || 10;
        const shortLimit = inputShortLimit.current.input.value || 10;

        if (pubKey && secKey) {
            if (pubKey.length < 10 || secKey.length < 10) {
                Modal.error({
                    title: 'Error',
                    content: 'API keys too short',
                });
                return;
            }
        } else {
            Modal.error({
                title: 'Error',
                content: 'All fields must be completed',
            });
            return;
        }

        setLoading(true);
        setSpinConnection(true);

        // test for development
        if (pubKey === 'testtesttest' && secKey === 'testtesttest') {
            // Create connection to the exchange in DB
            apiUser.createExchangeConnector({
                exchange: exchange,
                market: type,
                name: name,
                api_key: pubKey,
                api_secret_key: secKey,
                password: password,
                max_long_positions_count: longLimit,
                max_short_positions_count: shortLimit,
                only_one_asset: mobXStore.getKey('only_one_asset')
            }).then((response) => {
                if (response.result) {
                    getUserExchange();
                    setShowAddConnectionModal(false);
                    openSuccessNotification('Connection created successfully');
                } else {
                    // console.log(response.error);
                    Modal.error({
                        title: 'Error',
                        content: 'Connection created error: ' + response.error,
                    });
                }
                setLoading(false);
            });
            return;
        }

        // Test connection to the exchange (user data)
        apiUser.testConnection({
            exchange: exchange,
            market: type,
            api_key: pubKey,
            secret_key: secKey,
            password: password
        }).then((response) => {

            if (response.result === true) {
                setSpinConnection(false);
                // Create connection to the exchange in DB
                apiUser.createExchangeConnector({
                    exchange: exchange,
                    market: type,
                    name: name,
                    api_key: pubKey,
                    api_secret_key: secKey,
                    password: password,
                    max_long_positions_count: longLimit,
                    max_short_positions_count: shortLimit,
                    only_one_asset: mobXStore.getKey('only_one_asset')
                }).then((response) => {
                    if (response.result) {
                        getUserExchange();
                        setShowAddConnectionModal(false);
                        openSuccessNotification('Connection created successfully');
                        // Modal.success({
                        //     title: 'Success',
                        //     content: 'Connection created successfully',
                        // });
                    } else {
                        // console.log(response.error);
                        Modal.error({
                            title: 'Error',
                            content: 'Connection created error: ' + response.error,
                        });
                    }
                    setLoading(false);
                });
            } else {
                setSpinConnection(false);
                Modal.error({
                    title: 'Error',
                    content: 'Connection to the exchange failed. Check the correctness of the entered data.'
                });
                setLoading(false);
            }
        }).catch((error) => {
            setSpinConnection(false);
            Modal.error({
                title: 'Error',
                content: 'Connection to the exchange failed. Check the correctness of the entered data.'
            });
            setLoading(false);
        });
    }

    // Save new name to DB
    const saveNewSettingsToDB = (ID) => {

        // if (demoMode) {
        //     showModalDemoWarning();
        //     return;
        // }

        const name = inputNameChange.current.input.value;

        if (name && name.length >= 5) {
            setLoading(true);

            apiUser.changeNameExchangeConnector({id: ID, name: name}).then((response) => {
                if (response.result) {
                    setLoading(false);
                } else {
                    // console.log(response.error);
                    Modal.error({
                        title: 'Error',
                        content: 'Connection settings change error: ' + response.error,
                    });
                }
                setLoading(false);
            });

            apiUser.setLimitExchangeConnector({
                id: ID,
                max_long_positions_count: inputLongLimit.current.input.value,
                max_short_positions_count: inputShortLimit.current.input.value,
                only_one_asset: mobXStore.getKey('only_one_asset')
            }).then((response) => {
                if (response.result) {
                    getUserExchange();
                    openSuccessNotification('Connection settings changed successfully');
                    // Modal.success({
                    //     title: 'Success',
                    //     content: 'Connection settings changed successfully',
                    // });
                } else {
                    // console.log(response.error);
                    Modal.error({
                        title: 'Error',
                        content: 'Connection settings change error: ' + response.error,
                    });
                }
                setLoading(false);
            });
        } else {
            Modal.error({
                title: 'Error',
                content: 'Connection name must be at least 5 characters',
            });
        }
    }

    // Show modal for edit connection name
    function handleEdit(record) {

        // if (demoMode) {
        //     showModalDemoWarning();
        //     return;
        // }

        Modal.confirm({
            title: <>
                Change settings: <span style={{color: '#617af8'}}>{record.name}</span>
            </>,
            content: <>
                <Input.Group compact>
                    <br/>
                    <Input disabled value="Connector name"
                           style={{width: '26%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                    <Input
                        ref={inputNameChange}
                        placeholder="Enter New Connection name"
                        style={{width: '69%'}}
                        defaultValue={record.name}
                    />
                </Input.Group>
                <Input.Group compact style={record.type === 'future' ? {display: 'unset'} : {display: 'none'}}>
                    <br/>
                    <Input disabled value="LONG position limit"
                           style={{width: '26%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                    <Input
                        ref={inputLongLimit}
                        placeholder="number from -1 to 1000"
                        style={{width: '69%'}}
                        onChange={(e) => {
                            if (e.target.value < 0) {
                                e.target.value = -1;
                            }
                        }}
                        defaultValue={record.max_long_positions_count}
                    />
                    <Tooltip
                        title={<>
                            Maximum Open LONG Positions<br/><br/>
                            Values:<br/>
                            -1 : Unlimited positions<br/>
                            0 : LONG trading disabled<br/>
                            > 0 : Maximum number of positions<br/><br/>

                            Default: 10
                        </>}
                    >
                        &nbsp;<QuestionCircleOutlined
                        style={{
                            marginTop: '5px',
                            fontSize: '22px',
                            cursor: 'pointer',
                        }}
                    />
                    </Tooltip>
                </Input.Group>
                <Input.Group compact style={record.type === 'future' ? {display: 'unset'} : {display: 'none'}}>
                    <br/>
                    <Input disabled value="SHORT position limit"
                           style={{width: '26%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                    <Input
                        ref={inputShortLimit}
                        placeholder="number from -1 to 1000"
                        style={{width: '69%'}}
                        onChange={(e) => {
                            if (e.target.value < 0) {
                                e.target.value = -1;
                            }
                        }}
                        defaultValue={record.max_short_positions_count}
                    />
                    <Tooltip
                        title={<>
                            Maximum Open SHORT Positions<br/><br/>
                            Values:<br/>
                            -1 : Unlimited positions<br/>
                            0 : SHORT trading disabled<br/>
                            > 0 : Maximum number of positions<br/><br/>

                            Default: 10
                        </>}
                    >
                        &nbsp;<QuestionCircleOutlined
                        style={{
                            marginTop: '5px',
                            fontSize: '22px',
                            cursor: 'pointer',
                        }}
                    />
                    </Tooltip>
                </Input.Group>
                <div
                    style={
                        record.type === 'future' ? {
                            // display: 'unset',
                            marginTop: 20
                        } : {
                            display: 'none',
                            marginTop: 20
                        }
                    }
                >
                    <Switch size="default"
                            defaultChecked={record.only_one_asset === 1}
                            onChange={() => {
                                runInAction(() => {
                                    mobXStore.setKey('only_one_asset', record.only_one_asset === 1 ? 0 : 1)
                                })
                            }}
                    ></Switch>
                    &nbsp;One asset per trade&nbsp;
                    <Tooltip
                        title={<>
                            You can trade only one asset at a time.
                            Do not use this feature if you are running bots with averaging or grids.
                        </>}
                    >
                        &nbsp;<QuestionCircleOutlined
                        style={{
                            marginTop: '0px',
                            fontSize: '22px',
                            cursor: 'pointer',
                        }}
                    />
                    </Tooltip>
                </div>
                <br/>
            </>,
            width: 700,
            onOk() {
                saveNewSettingsToDB(record.key);
            },
        });
    }

    useEffect(() => {
        setDemoMode(mobXStore.getKey('demo_mode'));
        getUserExchange();
    }, []);

    function getUserExchange() {
        setLoading(true);
        apiUser.getUserExchange().then((response) => {
            if (response.result) {
                // console.log(response);
                setDataSource(JSON.parse(response.data));
                // console.log(response.data);
            } else {
                // console.log(response.error);
                if (response.error !== undefined) {
                    openErrorNotification(response.error);
                } else {
                    openErrorNotification("Connection error. You must log in again.");
                }
                apiUser.logout();
            }
            setLoading(false);
        });
    }

    // show modal for add new connection
    const modalAddConnection = () => {
        return (
            <Modal
                open={showAddConnectionModal}
                title={<strong>🆕 New connection to the exchange</strong>}
                style={{top: 20}}
                height={600}
                width={700}
                onOk={() => saveNewConnectToDB()}
                onCancel={() => {
                    setShowAddConnectionModal(false)
                }}
            >
                <Card>
                    <Space
                        direction="vertical"
                        size="middle"
                        style={{
                            display: 'flex',
                        }}>
                        <p style={{marginTop: "0px", marginBottom: "0px"}}><strong>
                            Connect to <Link to={"https://accounts.binance.com/en/register?ref=OSHDFY9J"}
                                             target={"_blank"}>Binance</Link>, <Link
                            to={"https://www.okx.com/ua/join/53775592"} target={"_blank"}>OKX</Link>, <Link
                            to={"https://www.bybit.com/uk-UA/invite/?ref=0NN1MN"}
                            target={"_blank"}>ByBit</Link>, <Link
                            to={"https://www.htx.com/en-us/v/register/double-invite/web/?inviter_id=11345710&invite_code=rc2c5223"}
                            target={"_blank"}>HTX/Huobi</Link>, <Link
                            to={"https://ibkr.com/referral/ievgenii739"} target={"_blank"}>Interactive
                            Brokers</Link>
                        </strong>
                        </p>
                        <p style={{marginTop: "0px", marginBottom: "0px"}}>
                            <strong>Add this IP to the WhiteList on the exchange:</strong>
                            &nbsp;&nbsp;
                            <Tooltip title="Copy to clipboard (delimiter: COMMA)">
                                <CopyToClipboard
                                    text="35.159.150.45,18.158.109.198,18.192.16.1,18.194.182.121,18.195.146.52,18.196.51.174,3.120.18.53,3.126.1.162,3.67.127.144,3.67.248.49,3.72.134.34,3.73.186.33,3.76.243.37,3.76.136.15"
                                    onCopy={() => {
                                        openSuccessNotification('IP address list copied to clipboard');
                                        // Modal.success({
                                        //     title: 'Success',
                                        //     content: 'IP address list copied to clipboard',
                                        // })
                                    }}
                                >
                                    <CopyOutlined/>
                                </CopyToClipboard>
                            </Tooltip>
                            &nbsp;&nbsp;
                            <Tooltip title="Copy to clipboard (delimiter: SPACE)">
                                <CopyToClipboard
                                    text="35.159.150.45 18.158.109.198 18.192.16.1 18.194.182.121 18.195.146.52 18.196.51.174 3.120.18.53 3.126.1.162 3.67.127.144 3.67.248.49 3.72.134.34 3.73.186.33 3.76.243.37 3.76.136.15"
                                    onCopy={() => {
                                        openSuccessNotification('IP address list copied to clipboard');
                                        // Modal.success({
                                        //     title: 'Success',
                                        //     content: 'IP address list copied to clipboard',
                                        // })
                                    }}
                                >
                                    <CopyOutlined/>
                                </CopyToClipboard>
                            </Tooltip>
                            <pre style={{color: "#b21a2d", fontSize: 12}}>
                                35.159.150.45 18.158.109.198 18.192.16.1 18.194.182.121 18.195.146.52 18.196.51.174 <br/>
                                3.120.18.53 3.126.1.162 3.67.127.144 3.67.248.49 3.72.134.34 3.73.186.33 3.76.243.37 <br/>
                                3.76.136.15
                            </pre>
                            <Divider/>
                        </p>
                        <Input.Group compact>
                            <Input disabled value="Connector name"
                                   style={{width: '26%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                            <Input
                                ref={inputName}
                                placeholder="Connector name"
                                style={{width: '74%'}}
                                defaultValue=""
                            />
                        </Input.Group>
                        <Input.Group compact>
                            <Input disabled value="Exchange"
                                   style={{width: '26%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>

                            <Select
                                showSearch
                                onChange={(value) => {
                                    setShowPasswordInput(exchanges.find(exchange => exchange.ccxt_name === value).password);
                                    setCcxtExchange(value);
                                    setCcxtExchangeManual(exchanges.find(exchange => exchange.ccxt_name === value).manual);
                                }}
                                style={{width: '74%'}}
                                // value={ccxtExchange}
                                options={exchanges_list}
                                filterOption={filterOption}
                                // defaultValue={exchanges_list[0].value}
                            />
                        </Input.Group>
                        <Input.Group compact>
                            <Input disabled value="Account type"
                                   style={{width: '26%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>

                            <Select
                                onChange={(value) => setTypeExchange(value)}
                                style={{width: '74%'}}
                                value={typeExchange}
                                options={[
                                    {value: 'spot', label: <span>SPOT</span>},
                                    {value: 'future', label: <span>FUTURES</span>}
                                ]}
                                defaultValue={'spot'}
                            />
                        </Input.Group>
                        <Input.Group compact>
                            <Input disabled value="Public API Key"
                                   style={{width: '26%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                            <Input
                                ref={inputPubApiKey}
                                placeholder="Public Key"
                                style={{width: '74%'}}
                                defaultValue=""
                            />
                        </Input.Group>
                        <Input.Group compact>
                            <Input disabled value="Secret API Key"
                                   style={{width: '26%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                            <Input
                                ref={inputSecApiKey}
                                placeholder="Secret Key"
                                style={{width: '74%'}}
                                defaultValue=""
                            />
                        </Input.Group>
                        <Input.Group compact style={showPasswordInput ? {display: 'unset'} : {display: 'none'}}>
                            <Input disabled value="API Password"
                                   style={{width: '26%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                            <Input
                                ref={inputPassword}
                                placeholder="API Password"
                                style={{width: '74%'}}
                                defaultValue=""
                            />
                        </Input.Group>
                        <Input.Group compact style={typeExchange === 'future' ? {display: 'unset'} : {display: 'none'}}>
                            <Input disabled value="LONG position limit"
                                   style={{width: '26%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                            <Input
                                ref={inputLongLimit}
                                placeholder="number from -1 to 1000"
                                style={{width: '69%'}}
                                onChange={(e) => {
                                    if (e.target.value < 0) {
                                        e.target.value = -1;
                                    }
                                }}
                                defaultValue={10}
                            />
                            <Tooltip
                                title={<>
                                    Maximum Open LONG Positions<br/><br/>
                                    Values:<br/>
                                    -1 : Unlimited positions<br/>
                                    0 : LONG trading disabled<br/>
                                    > 0 : Maximum number of positions<br/><br/>

                                    Default: 10
                                </>}
                            >
                                &nbsp;<QuestionCircleOutlined
                                style={{
                                    marginTop: '5px',
                                    fontSize: '22px',
                                    cursor: 'pointer',
                                }}
                            />
                            </Tooltip>
                        </Input.Group>
                        <Input.Group compact style={typeExchange === 'future' ? {display: 'unset'} : {display: 'none'}}>
                            <Input disabled value="SHORT position limit"
                                   style={{width: '26%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                            <Input
                                ref={inputShortLimit}
                                placeholder="number from -1 to 1000"
                                style={{width: '69%'}}
                                onChange={(e) => {
                                    if (e.target.value < 0) {
                                        e.target.value = -1;
                                    }
                                }}
                                defaultValue={10}
                            />
                            <Tooltip
                                title={<>
                                    Maximum Open SHORT Positions<br/><br/>
                                    Values:<br/>
                                    -1 : Unlimited positions<br/>
                                    0 : SHORT trading disabled<br/>
                                    > 0 : Maximum number of positions<br/><br/>

                                    Default: 10
                                </>}
                            >
                                &nbsp;<QuestionCircleOutlined
                                style={{
                                    marginTop: '5px',
                                    fontSize: '22px',
                                    cursor: 'pointer',
                                }}
                            />
                            </Tooltip>
                        </Input.Group>
                        <div
                            style={
                                typeExchange === 'future' ? {
                                    // display: 'unset',
                                    // marginTop: 20
                                } : {
                                    display: 'none',
                                    marginTop: 20
                                }
                            }
                        >
                            <Switch size="default"
                                    defaultChecked={false}
                                    onChange={() => {
                                        mobXStore.setKey('only_one_asset', mobXStore.getKey('only_one_asset') === 1 ? 0 : 1)
                                    }}
                            ></Switch>
                            &nbsp;One asset per trade&nbsp;
                            <Tooltip
                                title={<>
                                    You can trade only one asset at a time.
                                </>}
                            >
                                &nbsp;<QuestionCircleOutlined
                                style={{
                                    marginTop: '0px',
                                    fontSize: '22px',
                                    cursor: 'pointer',
                                }}
                            />
                            </Tooltip>
                        </div>
                        {ccxtExchangeManual !== '' && <>
                            <strong>
                                <Link to={ccxtExchangeManual} target={"_blank"}>📖 API Key setup manual</Link>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Link
                                    to={"https://docs.cdzv.com/code-zero-visual-trading_en/user-guide/how-to-enable-hedge-mode"}
                                    target={"_blank"}>🔗 Hedge mode setup manual</Link>
                            </strong>
                        </>
                        }
                    </Space>
                    <Spin spinning={spin_connection} size="large">
                        {spin_connection && <span>exchange connection verification...</span>}
                    </Spin>
                </Card>
                <strong style={{color: "#d05f6d"}}>* Fill in all fields and click <strong>'OK'</strong> to
                    create the connection.</strong>
                <p style={{marginTop: "5px", fontWeight: 600}}>
                    <AlertOutlined style={{color: 'red'}}/> IMPORTANT: For correct work of the system with deals in LONG
                    and SHORT it is necessary to set up an account on the exchange in <Link
                    to={"https://docs.cdzv.com/code-zero-visual-trading_en/user-guide/how-to-enable-hedge-mode"}
                    target={"_blank"}>Hedge mode</Link>.
                </p>
            </Modal>
        )
    }

    const columns = [
        {
            title: 'Active',
            dataIndex: 'is_active',
            align: 'center',
            width: '80px',
            render: (_, record) => (<Switch size="middle" checked={record.is_active}
                                            onChange={() => handleChangeActive(record.key, !record.is_active)}/>),
            // defaultSortOrder: 'descend',
            sortIcon: (sorted) => sortIcon(sorted),
            sorter: (a, b) => a.is_active - b.is_active
        },
        {
            title: 'Name',
            dataIndex: 'name',
            align: 'center',
            // width: '25%',
            render: (text) => {
                return <><strong>{text}</strong></>
            },
            defaultSortOrder: 'ascend',
            sortIcon: (sorted) => sortIcon(sorted),
            sorter: (a, b) => a.name.localeCompare(b.name),
            ...getColumnSearchProps('name', 'Search by Connector name'),
        },
        {
            title: 'Exchange',
            dataIndex: 'exchange',
            width: '220px',
            align: 'center',
            render: (text, record) => {
                const exchange = exchanges.find(exchange => exchange.ccxt_name === text);
                return (
                    <Image height={"25px"} src={exchange.logo} preview={false}/>
                )
            },
            // defaultSortOrder: 'descend',
            sortIcon: (sorted) => sortIcon(sorted),
            sorter: (a, b) => a.exchange.localeCompare(b.exchange),
            // defaultSortOrder: 'descend',
            // sorter: (a, b) => a.exchange.localeCompare(b.exchange)
            ...getColumnSearchProps('exchange', 'Search by Exchange name'),
        },
        {
            title: 'Type',
            dataIndex: 'type',
            align: 'center',
            // width: '100px',
            render: (text) => {
                if (text.toLowerCase() === 'spot') {
                    return <><strong style={{color: '#009700'}}>{text.toUpperCase()}</strong></>
                } else {
                    return <><strong style={{color: '#617af8'}}>{text.toUpperCase()}</strong></>
                }
            },
            sortIcon: (sorted) => sortIcon(sorted),
            // defaultSortOrder: 'descend',
            sorter: (a, b) => a.type.localeCompare(b.type),
            ...getColumnSearchProps('type', 'Search by Account type'),
        },
        {
            title: 'Limits',
            dataIndex: 'max_long_positions_count',
            align: 'center',
            // width: '100px',
            render: (text, record) => {
                if (record.type === 'future') {
                    const is_once = record.only_one_asset === 0 ? <ProfileFilled style={{color: '#1fa80d'}}/> :
                        <ProfileFilled style={{color: '#b21a2d'}}/>;
                    return <>
                        <Tooltip title="Max Long and Short positions count">
                            <strong
                                style={{color: '#b21a2d'}}>L:{record.max_long_positions_count === -1 ? 'NO' : record.max_long_positions_count} |
                                S:{record.max_short_positions_count === -1 ? 'NO' : record.max_short_positions_count}
                            </strong>
                        </Tooltip> <Tooltip title="Entering the same position with one asset multiple times">
                        <strong>
                            | {is_once}
                        </strong>
                    </Tooltip>
                    </>
                } else {
                    return <>
                        <Tooltip title="No limits for SPOT account">
                            <strong style={{color: '#617af8'}}>No</strong>
                        </Tooltip>
                    </>
                }
            }
        },
        {
            title: 'Pub Key',
            dataIndex: 'pub_key',
            // width: '15%',
            align: 'center',
            // defaultSortOrder: 'descend',
            sortIcon: (sorted) => sortIcon(sorted),
            sorter: (a, b) => a.pub_key.localeCompare(b.pub_key),
            ...getColumnSearchProps('pub_key', 'Search by Public API Key'),
        },
        {
            title: '',
            dataIndex: 'operation',
            align: 'center',
            width: '50px',
            render: (_, record) =>
                dataSource.length >= 1 ? (
                    <>
                        <Space direction={"horizontal"}>
                            <Button style={{background: '#d1d9da'}}
                                    size={"middle"}
                                    onClick={() => {
                                        handleEdit(record)
                                    }}
                            >
                                <EditOutlined/>
                            </Button>
                            <Button type="text" style={{background: '#e80e19', color: "white"}}
                                    size={"middle"}>
                                <Popconfirm title="Sure to delete connection?"
                                            placement={"left"}
                                            onConfirm={() => handleDeleteConnection(record.key)}>
                                    <DeleteOutlined/>
                                </Popconfirm>
                            </Button>
                        </Space>
                    </>
                ) : null,
        },
    ];

    return (
        <>
            {contextHolder}
            {modalAddConnection()}
            <Button
                // type="primary"
                style={{
                    backgroundColor: 'rgb(222,223,250)',
                    position: 'fixed',
                    // bottom: 70,
                    top: 10,
                    left: 210,
                    zIndex: 1000,
                    borderRadius: 50,
                    fontWeight: 700,
                    fontFamily: 'Terminal, monospace',
                    boxShadow: '0 0 10px 0 rgba(0,0,0,0.5)',
                }}
                onClick={() => {
                    window.open(url_msg["How do i add an exchange"][language], '_blank');
                }}
            >
                <InfoCircleOutlined/> {content_msg["How do i add an exchange"][language]}
            </Button>
            {/*<Modal*/}
            {/*    open={showModal}*/}
            {/*    title={content_msg["How do i add an exchange"][language]}*/}
            {/*    width={800}*/}
            {/*    onOk={() => setShowModal(false)}*/}
            {/*    onCancel={() => setShowModal(false)}*/}
            {/*>*/}
            {/*    <div>*/}
            {/*        <iframe width="100%" height="423px"*/}
            {/*                src="https://embed.app.guidde.com/playbooks/axKVwn8V5xQw4FTNktBmiu"*/}
            {/*                title="How to set up copy trade" frameBorder="0" referrerPolicy="unsafe-url"*/}
            {/*                allowFullScreen="true" allow="clipboard-write"*/}
            {/*                sandbox="allow-popups allow-popups-to-escape-sandbox allow-scripts allow-forms allow-same-origin allow-presentation"></iframe>*/}
            {/*        <p style={{display: "none"}}></p>*/}
            {/*    </div>*/}
            {/*</Modal>*/}
            <Content>
                <Row gutter={8} justify={"center"} align={"top"}>
                    <Col span={24}>
                        <span style={{
                            fontFamily: 'Courier New, monospace',
                            fontSize: '2em',
                            fontWeight: 700,
                        }}>
                                <ControlOutlined/> Connection to exchanges
                        </span>
                        <ConfigProvider
                            theme={{
                                components: {
                                    Table: {
                                        // fontSize: "16px",
                                        fontFamily: 'Courier New',
                                        headerBorderRadius: '10px',
                                        headerColor: '#ffffff',
                                        headerBg: '#272762',
                                        bodySortBg: '#ffffff',
                                        headerSortHoverBg: '#272762',
                                        headerSortActiveBg: '#272762',
                                        cellFontSizeSM: "14px",
                                        cellPaddingInline: '10px',
                                        cellPaddingBlockSM: '5px',
                                        cellPaddingInlineSM: '5px',
                                        colorLinkActive: '#b21a2d',
                                    },
                                },
                            }}
                        >
                            <Table
                                rowKey={record => record.key}
                                size="small"
                                pagination={{
                                    pageSizeOptions: [5, 10, 15, 20, 50, 100],
                                    hideOnSinglePage: true,
                                    showSizeChanger: true, // Включает выбор количества записей на странице
                                    defaultPageSize: 10,
                                    showTotal: (total) => `Total ${total} records`, // Показывает общее количество записей,
                                }}

                                loading={loading}
                                title={() =>
                                    <Row align={"middle"} justify={"end"}>
                                        <Button
                                            onClick={() => {
                                                if (demoMode && dataSource.length >= LIMIT_CONNECTIONS) {
                                                    showModalLimitWarning();
                                                    return;
                                                }
                                                setShowAddConnectionModal(true);
                                            }}
                                            type="primary"
                                            style={{
                                                marginTop: '0%',
                                                marginBottom: '0%',
                                            }}
                                        >
                                            <PlusCircleOutlined/> Connect Exchange Account
                                        </Button>
                                    </Row>
                                }
                                style={{
                                    marginLeft: '5%',
                                    marginRight: '5%',
                                }}
                                bordered
                                dataSource={dataSource}
                                columns={columns}
                                onRow={(record) => {
                                    return {
                                        // onClick: () => handleEdit(record.key), // click row
                                        onDoubleClick: () => {
                                            handleEdit(record); // double click row
                                        }
                                        // onContextMenu: event => {
                                        // }, // right button click row
                                        // onMouseEnter: event => {
                                        // }, // mouse enter row
                                        // onMouseLeave: event => {
                                        // }, // mouse leave row
                                    };
                                }}
                            />
                        </ConfigProvider>
                    </Col>
                </Row>
            </Content>
        </>
    );
}

export default Connectors;