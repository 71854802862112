import AreaChart from "../AreaChart/AreaChart";
import React from "react";
import {Card, Col, Row, Statistic, Tooltip} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";

function EquityStats({
                         title = '',
                         equityCurveData = [],
                         max_loss_streak_count = 0,
                         max_drawdown_in_series = 0,
                         max_single_trade_loss = 0,
                         max_open_trades_long = 0,
                         max_open_trades_short = 0,
                         max_open_trades_both = 0,
                     } = {}) {

    if (!equityCurveData || !equityCurveData.length) {
        return null;
    }

    if (!max_loss_streak_count) max_loss_streak_count = 0;
    if (!max_drawdown_in_series) max_drawdown_in_series = 0;
    if (!max_single_trade_loss) max_single_trade_loss = 0;

    return (
        <>
            {title}
            <Row gutter={[8, 8]} justify={'center'}>
                <Col span={8}>
                    <Card bordered={false}>
                        <Statistic
                            title={<>
                                Maximum series of losing trades <Tooltip
                                title="Number of losing trades in a row in date-ordered and aggregated trades of all analyzed strategies.">
                                <InfoCircleOutlined/>
                            </Tooltip>
                            </>}
                            value={max_loss_streak_count}
                            precision={0}
                            valueStyle={{
                                color: '#030342',
                            }}
                            // prefix={<ArrowUpOutlined/>}
                            // suffix="%"
                        />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card bordered={false}>
                        <Statistic
                            title={<>
                                Maximum loss in the series <Tooltip
                                title="This is the maximum sum of all trades in a losing series of strategies in percent. IMPORTANT: this is not the maximum drawdown, but the sum of losses on closed trades.">
                                <InfoCircleOutlined/>
                            </Tooltip>
                            </>}
                            value={max_drawdown_in_series}
                            precision={2}
                            valueStyle={{
                                color: '#030342',
                            }}
                            // prefix={<ArrowDownOutlined/>}
                            suffix="%"
                        />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card bordered={false}>
                        <Statistic
                            title={<>
                                Maximum losing trade <Tooltip
                                title="The largest losing trade in all analyzed strategies.">
                                <InfoCircleOutlined/>
                            </Tooltip>
                            </>}
                            value={max_single_trade_loss}
                            precision={2}
                            valueStyle={{
                                color: '#030342',
                            }}
                            // prefix={<ArrowDownOutlined/>}
                            suffix="%"
                        />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card bordered={false}>
                        <Statistic
                            title={<>
                                Maximum number of open trades <Tooltip
                                title="Number of simultaneously opened trades on all analyzed strategies in both directions.">
                                <InfoCircleOutlined/>
                            </Tooltip>
                            </>}
                            value={max_open_trades_both}
                            precision={0}
                            valueStyle={{
                                color: '#030342',
                            }}
                            // prefix={<ArrowDownOutlined/>}
                            // suffix="%"
                        />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card bordered={false}>
                        <Statistic
                            title={<>
                                Maximum number of open trades in <span style={{color: 'green'}}>LONG</span> <Tooltip
                                title="Number of simultaneous open trades in all analyzed strategies in LONG.">
                                <InfoCircleOutlined/>
                            </Tooltip>
                            </>}
                            value={max_open_trades_long}
                            precision={0}
                            valueStyle={{
                                color: '#030342',
                            }}
                            // prefix={<ArrowDownOutlined/>}
                            // suffix="%"
                        />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card bordered={false}>
                        <Statistic
                            title={<>
                                Maximum number of open trades in <span style={{color: 'red'}}>SHORT</span> <Tooltip
                                title="Number of simultaneous open trades in all analyzed strategies in SHORT.">
                                <InfoCircleOutlined/>
                            </Tooltip>
                            </>}
                            value={max_open_trades_short}
                            precision={0}
                            valueStyle={{
                                color: '#030342',
                            }}
                            // prefix={<ArrowDownOutlined/>}
                            // suffix="%"
                        />
                    </Card>
                </Col>
                <Col span={24}>
                    <AreaChart
                        value={equityCurveData}
                    />
                </Col>
            </Row>
        </>
    );
}

export default EquityStats;