import React, {useEffect, useRef, useState} from 'react';
import {
    Button,
    Card,
    Col,
    ConfigProvider,
    Input,
    Modal,
    notification,
    Popconfirm,
    Row,
    Select,
    Space,
    Switch,
    Table,
    Tooltip
} from 'antd';
import {Content} from "antd/es/layout/layout";
import {
    CaretDownOutlined,
    CaretUpOutlined,
    ClearOutlined,
    CloseOutlined,
    CopyOutlined,
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    FullscreenExitOutlined,
    InfoCircleOutlined,
    SearchOutlined
} from "@ant-design/icons";
import API from "../../../api/user";
import {CopyToClipboard} from "react-copy-to-clipboard";
import TextArea from "antd/es/input/TextArea";
import StrategyCard from "../../components/StrategyCard";
import {mobXStore, observer} from "../../../store/";
import {showModalDemoWarning} from "../../../Helpers";

const filterOption = (input, option) =>
    (option?.search_label ?? '').toLowerCase().includes(input.toLowerCase());

const webhookMessageExample = `{
  "name": "Example message format",
  "secret": "this_is_secret_key",
  "strategy_id": "ID_0001",
  "side": "{{strategy.order.action}}",
  "positionSide": "{{strategy.market_position}}",
  "symbol": "{{ticker}}",
  "price": "{{close}}",
  "amount": "{{strategy.position_size}}"
}`

function get_strategy_id(strategy_id) {
    if (strategy_id > 1000) {
        return '#ID_' + strategy_id;
    } else if (strategy_id > 100) {
        return '#ID_0' + strategy_id;
    } else if (strategy_id > 10) {
        return '#ID_00' + strategy_id;
    } else {
        return '#ID_000' + strategy_id;
    }
}

// BotsExchange component
function BotsWebhook(props) {

    const apiUser = new API();

    const [apiNotification, contextHolder] = notification.useNotification();

    const openErrorNotification = (message) => {
        apiNotification.error({
            type: 'error',
            message: 'Error',
            description: message,
            placement: 'topRight',
            duration: 3,
        });
    };

    const openSuccessNotification = (message) => {
        apiNotification.open({
            type: 'success',
            message: 'Success',
            description: message,
            placement: 'topRight',
            duration: 3,
        });
    };

    const [demoMode, setDemoMode] = useState(mobXStore.getKey('demo_mode'));

    const [showAddBotModal, setShowAddBotModal] = useState(false);
    const [showEditBotModal, setShowEditBotModal] = useState(false);

    const [editRecordId, setEditRecordId] = useState(0);
    const [inputBotName, setInputBotName] = useState('');
    const [inputStrategyId, setInputStrategyId] = useState(0);
    const [inputOrderAmount, setInputOrderAmount] = useState(100);
    const [inputWebhookUrl, setInputWebhookUrl] = useState('');
    const [inputWebhookMessage, setInputWebhookMessage] = useState(webhookMessageExample);
    const [inputClosePositionMode, setInputClosePositionMode] = useState('CLOSE');

    const [strategyList, setStrategyList] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState(false);

    const [selectedStrategy, setSelectedStrategy] = useState(null);

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const sortIcon = (sorted) => {
        // alert(JSON.stringify(sorted))
        if (sorted['sortOrder'] === 'descend') {
            return (<CaretDownOutlined
                style={{
                    color: '#1677ff'
                }}
            />)
        } else if (sorted['sortOrder'] === 'ascend') {
            return (<CaretUpOutlined
                style={{
                    color: '#1677ff'
                }}
            />)
        } else {
            return (<FullscreenExitOutlined
                style={{
                    color: 'white',
                }}
            />)
        }
    }

    const getColumnSearchProps = (dataIndex, tooltip_text = '') => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (
            <div
                style={{
                    // backgroundColor: '#0f0fa8',
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Space direction={'horizontal'}>
                    <Input
                        ref={searchInput}
                        placeholder={tooltip_text}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{
                            marginBottom: 8,
                            width: 200,
                            display: 'block',
                        }}
                    />
                    <Button
                        type="button"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        <CloseOutlined/>
                    </Button>
                </Space>
                <br/>
                <Space direction={'horizontal'}>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined/>}
                        size="small"
                        style={{
                            width: 115,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && (handleReset(clearFilters) || handleSearch(selectedKeys, confirm, dataIndex) || close())}
                        size="small"
                        style={{
                            width: 115,
                        }}
                    >
                        <ClearOutlined/> Reset
                    </Button>
                    {/*<Button*/}
                    {/*    type="link"*/}
                    {/*    size="small"*/}
                    {/*    onClick={() => {*/}
                    {/*        confirm({*/}
                    {/*            closeDropdown: false,*/}
                    {/*        });*/}
                    {/*        setSearchText(selectedKeys[0]);*/}
                    {/*        setSearchedColumn(dataIndex);*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    Filter*/}
                    {/*</Button>*/}
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#f6adb5' : 'white',
                }}
            />
        ),
        sortIcon: (sorted) => sortIcon(sorted),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        // render: (text) =>
        //     searchedColumn === dataIndex ? (
        //         <Highlighter
        //             highlightStyle={{
        //                 backgroundColor: '#ffc069',
        //                 padding: 0,
        //             }}
        //             searchWords={[searchText]}
        //             autoEscape
        //             textToHighlight={text ? text.toString() : ''}
        //         />
        //     ) : (
        //         text
        //     ),
    });

    useEffect(() => {
        setDemoMode(mobXStore.getKey('demo_mode'));
        getStrategy();
        getUserBots();
    }, []);

    function getStrategy() {
        setLoading(true);
        apiUser.getStrategy().then((response) => {
            if (response.result) {
                setStrategyList(JSON.parse(response.data));
                // console.log(response.data);
                // Show Add Bot Modal

                const stID = parseInt(mobXStore.getKey('addStrategyID'))
                if (stID > 0) {
                    setInputStrategyId(stID);
                    setSelectedStrategy(getStrategyById(stID));
                    setShowAddBotModal(true);
                    mobXStore.setKey('addStrategyID', 0);
                }
                setLoading(false);
            } else {
                // console.log(response.error);
                setLoading(false);
                if (response.error !== undefined) {
                    openErrorNotification(response.error);
                } else {
                    openErrorNotification("Connection error. You must log in again.");
                }
                apiUser.logout();
            }
        });
    }

    function getUserBots() {
        setLoading(true);
        apiUser.getNewBotsWebhook().then((response) => {
            if (response.result) {
                // console.log(response);
                let res = JSON.parse(response.data);
                setDataSource(res);
                // console.log(response.data);
                setLoading(false);
            } else {
                setLoading(false);
                if (response.error !== undefined) {
                    openErrorNotification(response.error);
                } else {
                    openErrorNotification("Connection error. You must log in again.");
                }
                apiUser.logout();
            }
        });
    }

    // get Strategy by ID
    function getStrategyById(id = null) {
        if (!id) {
            return strategyList.filter((item) => item.ID === inputStrategyId)[0];
        } else {
            return strategyList.filter((item) => item.ID === id)[0];
        }
    }

    useEffect(() => {
        setSelectedStrategy(getStrategyById());
    }, [inputStrategyId]);

    function handleChangeActive(key, is_active) {
        if (demoMode) {
            showModalDemoWarning();
            return;
        }

        setLoading(true);
        apiUser.changeActiveBotWebhook({id: key, is_active: is_active}).then((response) => {
            if (response.result) {
                getUserBots();
                openSuccessNotification('Bot status changed successfully');
                // Modal.success({
                //     title: 'Success',
                //     content: 'Bot status changed successfully',
                // });
            } else {
                // console.log(response.error);
                Modal.error({
                    title: 'Error',
                    content: 'Bot status change error: ' + response.error,
                });
            }
            setLoading(false);
        });
    }

    function handleDeleteBoot(key) {
        if (demoMode) {
            showModalDemoWarning();
            return;
        }

        setLoading(true);
        apiUser.deleteBotWebhook({id: key}).then((response) => {
            if (response.result) {
                getUserBots();
                openSuccessNotification('Bot deleted successfully');
                // Modal.success({
                //     title: 'Success',
                //     content: 'Bot deleted successfully',
                // });
            } else {
                // console.log(response.error);
                Modal.error({
                    title: 'Error',
                    content: 'Bot delete error: ' + response.error,
                });
            }
            setLoading(false);
        });
    }

    // Show modal for edit Bot properties
    function handleEdit(record) {
        setEditRecordId(record.ID);
        setInputBotName(record.name);
        setInputOrderAmount(record.order_amount_usd);
        setInputClosePositionMode(record.close_position_mode);
        setInputWebhookUrl(record.webhook_url);
        setInputWebhookMessage(record.message);

        setShowEditBotModal(true);
    }

    // saveNewBotToDB
    function saveNewBotToDB() {
        if (demoMode) {
            showModalDemoWarning();
            return;
        }

        if (inputBotName === '') {
            Modal.error({
                title: 'Error',
                content: 'Please enter Bot name',
            });
            return;
        }

        if (inputStrategyId === 0) {
            Modal.error({
                title: 'Error',
                content: 'Please select Strategy',
            });
            return;
        }

        if (inputWebhookUrl === '') {
            Modal.error({
                title: 'Error',
                content: 'Please enter Webhook URL',
            });
            return;
        }

        if (inputWebhookMessage === '') {
            Modal.error({
                title: 'Error',
                content: 'Please enter Webhook Message',
            });
            return;
        }

        setShowAddBotModal(false);

        const data = {
            name: inputBotName,
            strategy_id: inputStrategyId,
            order_amount_usd: inputOrderAmount,
            webhook_url: inputWebhookUrl,
            message: inputWebhookMessage,
            close_position_mode: inputClosePositionMode
        }

        apiUser.addNewBotWebhook(data).then((response) => {
            if (response.result) {
                getUserBots();
                openSuccessNotification('Bot created successfully');
                // Modal.success({
                //     title: 'Success',
                //     content: 'Bot created successfully',
                // });

                mobXStore.setKey('addStrategyID', 0);
            } else {
                // console.log(response.error);
                Modal.error({
                    title: 'Error',
                    content: 'Bot create error: ' + response.error,
                });
            }
            setLoading(false);
        });
    }

    // editBotToDB
    function editBotToDB() {
        if (demoMode) {
            showModalDemoWarning();
            return;
        }

        setShowEditBotModal(false);

        const data = {
            id: editRecordId,
            name: inputBotName,
            order_amount_usd: inputOrderAmount,
            webhook_url: inputWebhookUrl,
            message: inputWebhookMessage,
            close_position_mode: inputClosePositionMode
        }

        apiUser.updateBotWebhook(data).then((response) => {
            if (response.result) {
                getUserBots();
                openSuccessNotification('Bot update successfully');
                // Modal.success({
                //     title: 'Success',
                //     content: 'Bot update successfully',
                // });
            } else {
                // console.log(response.error);
                Modal.error({
                    title: 'Error',
                    content: 'Bot update error: ' + response.error,
                });
            }
            setLoading(false);
        });
    }

    // show modal for add new Bot
    const modalAddBot = () => {

        if (!strategyList || strategyList.length === 0) {
            return <></>;
        }

        let strategy_list = strategyList.map((item) => {
            return {
                value: item.ID,
                search_label: get_strategy_id(item.ID) + ' ' + item.name + ' ' + item.market + ' ' + (item.is_investing === 1 ? 'INVESTOR' : 'TRADER'),
                label: get_strategy_id(item.ID) + ' | ' + (item.is_investing === 1 ? 'INVESTOR' : 'TRADER') + ' | ' + item.market + ': ' + item.symbol
            }
        });

        return (
            <Modal
                open={showAddBotModal}
                title={<strong>🆕 Create Copy Bot <span
                    style={{color: "#f17c8b"}}>to external Webhook URL</span></strong>}
                height={600}
                width={800}
                style={{top: 10}}
                onOk={() => saveNewBotToDB()}
                onCancel={() => setShowAddBotModal(false)}
            >
                <Card>
                    <Space
                        direction="vertical"
                        size="large"
                        style={{
                            display: 'flex',
                        }}>
                        <Input.Group compact>
                            <Input disabled value="Bot name"
                                   style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                            <Input
                                onChange={(e) => setInputBotName(e.target.value)}
                                placeholder="Bot name"
                                value={inputBotName}
                                style={{width: '75%'}}
                                defaultValue={inputBotName}
                            />
                        </Input.Group>
                        <Input.Group compact>
                            <Input disabled value="Strategy"
                                   style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                            <Select
                                showSearch
                                onChange={(value) => {
                                    setInputStrategyId(value);
                                }}
                                placeholder="Select Strategy"
                                value={inputStrategyId !== 0 ? inputStrategyId : null}
                                style={{width: '75%'}}
                                options={strategy_list}
                                filterOption={filterOption}
                                // defaultValue={strategy_list[0].value}

                            />
                        </Input.Group>
                        <Input.Group compact>
                            <Input disabled value="Order amount, USD"
                                   style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                            <Input
                                onChange={(e) => setInputOrderAmount(e.target.value)}
                                value={inputOrderAmount}
                                placeholder="Order amount, USD"
                                style={{width: '75%'}}
                                defaultValue='100'
                            />
                        </Input.Group>
                        <Input.Group compact>
                            <Input disabled value="Close position mode"
                                   style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                            <Select
                                onChange={(value) => setInputClosePositionMode(value)}
                                style={{width: '70%'}}
                                value={inputClosePositionMode}
                                options={[
                                    {value: 'CLOSE', label: <span>CLOSE</span>},
                                    {value: 'REDUCE', label: <span>REDUCE</span>}
                                ]}
                                defaultValue={"CLOSE"}
                            />
                            &nbsp;&nbsp;&nbsp;
                            <Tooltip
                                placement="top"
                                overlayInnerStyle={{
                                    width: '400px',
                                    backgroundColor: '#d1d9da',
                                    color: 'black',
                                }}
                                title={
                                    <>
                                        Select the mode of closing positions when the bot receives a signal to close a
                                        position.<br/>
                                        <strong>CLOSE</strong> - close all open positions on this instrument,<br/>
                                        <strong>REDUCE</strong> - reduce the position size by the order amount
                                    </>
                                }
                            >
                                <InfoCircleOutlined style={{fontSize: '20px'}}/>
                            </Tooltip>
                        </Input.Group>
                        <Input.Group compact>
                            <Input disabled value="Webhook URL"
                                   style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                            <Input
                                onChange={(e) => setInputWebhookUrl(e.target.value)}
                                value={inputWebhookUrl}
                                placeholder="Webhook URL"
                                style={{width: '75%'}}
                                defaultValue=''
                            />
                        </Input.Group>
                        <Input.Group compact>
                            <Input disabled value="Webhook Message"
                                   style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                            &nbsp;&nbsp;&nbsp;
                            <Tooltip
                                placement="top"
                                overlayInnerStyle={{
                                    width: '600px',
                                    backgroundColor: '#d1d9da',
                                    color: 'black',
                                }}
                                title={
                                    <>
                                        You can use variables in the message, which will be replaced by the values.<br/>
                                        This list can be found in the TradingView documentation at the link:<br/>
                                        <a href="https://www.tradingview.com/support/solutions/43000481368-strategy-alerts/"
                                           target={"_blank"}>https://www.tradingview.com/support/solutions/43000481368-strategy-alerts/</a>
                                    </>
                                }
                            >
                                <InfoCircleOutlined style={{fontSize: '20px'}}/>
                            </Tooltip>
                            <TextArea
                                onChange={(e) => setInputWebhookMessage(e.target.value)}
                                value={inputWebhookMessage}
                                placeholder="Webhook Message"
                                style={{width: '100%', height: '250px'}}
                                defaultValue=''
                            />
                        </Input.Group>
                        {selectedStrategy !== null && <StrategyCard strategy={selectedStrategy}/>}
                    </Space>
                </Card>
                <strong style={{color: "#ff0811"}}>* Fill in all fields and click <strong>'OK'</strong> to
                    create the Copy Bot.</strong>
            </Modal>
        )
    }

    // show modal for Edit Bot
    const modalEditBot = () => {

        return (
            <Modal
                open={showEditBotModal}
                title={<strong>📝 Edit Copy Bot <span
                    style={{color: "#f17c8b"}}>to external Webhook URL</span></strong>}
                height={600}
                width={800}
                style={{top: 20}}
                onOk={() => editBotToDB()}
                onCancel={() => setShowEditBotModal(false)}
            >
                <Card>
                    <Space
                        direction="vertical"
                        size="small"
                        style={{
                            display: 'flex',
                        }}
                    >
                        <Input.Group compact>
                            <Input disabled value="Bot name"
                                   style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                            <Input
                                onChange={(e) => setInputBotName(e.target.value)}
                                placeholder="Bot name"
                                value={inputBotName}
                                style={{width: '75%'}}
                                defaultValue={inputBotName}
                            />
                        </Input.Group>
                        <Input.Group compact>
                            <Input disabled value="Order amount, USD"
                                   style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                            <Input
                                onChange={(e) => setInputOrderAmount(e.target.value)}
                                value={inputOrderAmount}
                                placeholder="Order amount, USD"
                                style={{width: '75%'}}
                                defaultValue={inputOrderAmount}
                            />
                        </Input.Group>
                        <Input.Group compact>
                            <Input disabled value="Close position mode"
                                   style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                            <Select
                                onChange={(value) => setInputClosePositionMode(value)}
                                style={{width: '70%'}}
                                value={inputClosePositionMode}
                                options={[
                                    {value: 'CLOSE', label: <span>CLOSE</span>},
                                    {value: 'REDUCE', label: <span>REDUCE</span>}
                                ]}
                                defaultValue={"CLOSE"}
                            />
                            &nbsp;&nbsp;&nbsp;
                            <Tooltip
                                placement="top"
                                overlayInnerStyle={{
                                    width: '400px',
                                    backgroundColor: '#d1d9da',
                                    color: 'black',
                                }}
                                title={
                                    <>
                                        Select the mode of closing positions when the bot receives a signal to close a
                                        position.<br/>
                                        <strong>CLOSE</strong> - close all open positions on this instrument,<br/>
                                        <strong>REDUCE</strong> - reduce the position size by the order amount
                                    </>
                                }
                            >
                                <InfoCircleOutlined style={{fontSize: '20px'}}/>
                            </Tooltip>
                        </Input.Group>
                        <Input.Group compact>
                            <Input disabled value="Webhook URL"
                                   style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                            <Input
                                onChange={(e) => setInputWebhookUrl(e.target.value)}
                                value={inputWebhookUrl}
                                placeholder="Webhook URL"
                                style={{width: '75%'}}
                                defaultValue='100'
                            />
                        </Input.Group>
                        <Input.Group compact>
                            <Input disabled value="Webhook Message"
                                   style={{width: '25%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto'}}/>
                            &nbsp;&nbsp;&nbsp;
                            <Tooltip
                                placement="top"
                                overlayInnerStyle={{
                                    width: '600px',
                                    backgroundColor: '#d1d9da',
                                    color: 'black',
                                }}
                                title={
                                    <>
                                        You can use variables in the message, which will be replaced by the values.<br/>
                                        This list can be found in the TradingView documentation at the link:<br/>
                                        <a href="https://www.tradingview.com/support/solutions/43000481368-strategy-alerts/"
                                           target={"_blank"}>https://www.tradingview.com/support/solutions/43000481368-strategy-alerts/</a>
                                    </>
                                }
                            >
                                <InfoCircleOutlined style={{fontSize: '20px'}}/>
                            </Tooltip>
                            <TextArea
                                onChange={(e) => setInputWebhookMessage(e.target.value)}
                                value={inputWebhookMessage}
                                placeholder="Webhook Message"
                                style={{width: '100%', height: '300px'}}
                                defaultValue='100'
                            />
                        </Input.Group>
                    </Space>
                </Card>
                <strong style={{color: "#ff0811"}}>* Fill in all fields and click <strong>'OK'</strong> to
                    edit the Copy Bot.</strong>
            </Modal>
        )
    }

    const columns = [
        {
            title: 'Active',
            dataIndex: 'is_active',
            align: 'center',
            width: '60px',
            // defaultSortOrder: 'descend',
            sortIcon: (sorted) => sortIcon(sorted),
            sorter: (a, b) => a.is_active - b.is_active,
            render: (_, record) => (<Switch size="large" checked={record.is_active}
                                            onChange={() => {
                                                if (!record.is_active) {
                                                    Modal.confirm({
                                                        width: 600,
                                                        title: 'Activate Trading Signals',
                                                        icon: <ExclamationCircleOutlined/>,
                                                        content: (<>
                                                                <h4>To enable trading signals you must:</h4>
                                                                1. Agree to our <a
                                                                href={"https://docs.cdzv.com/code-zero-visual-trading_en/support/term-of-use"}
                                                                target={"_blank"}>Terms of use</a> and assume
                                                                all risks associated with cryptocurrency trading.<br/>
                                                                2. Confirm that you are not a US citizen.<br/><br/>
                                                            </>
                                                        ),
                                                        okText: 'I agree',
                                                        cancelText: 'I don\'t agree',
                                                        onOk() {
                                                            handleChangeActive(record.ID, !record.is_active)
                                                        },
                                                        onCancel() {
                                                            // console.log('Cancel');
                                                        },
                                                    });
                                                } else {
                                                    handleChangeActive(record.ID, !record.is_active)
                                                }
                                            }}
            />)
        },
        {
            title: 'Name',
            dataIndex: 'name',
            width: '200px',
            align: 'center',
            render: (text) => {
                return <><strong>{text}</strong></>
            },
            sortIcon: (sorted) => sortIcon(sorted),
            // defaultSortOrder: 'descend',
            sorter: (a, b) => a.name.localeCompare(b.name),
            ...getColumnSearchProps('name', 'Search by Bot name'),
        },
        {
            title: 'Strategy',
            dataIndex: 'strategy_id',
            width: '120px',
            render: (text) => {
                return <>
                    <Tooltip
                        placement={"right"}
                        title={<StrategyCard strategy={getStrategyById(text)}/>}
                        overlayInnerStyle={{
                            width: '550px',
                            backgroundColor: '#d1d9da',
                            color: 'black',
                        }}
                    >
                        <strong>{get_strategy_id(text)}</strong> <InfoCircleOutlined/>
                    </Tooltip>
                </>
            },
            defaultSortOrder: 'ascend',
            sortIcon: (sorted) => sortIcon(sorted),
            sorter: (a, b) => a.strategy_id - b.strategy_id,
            ...getColumnSearchProps('strategy_id', 'Search by ID (e.g. 1, 25, etc.)'),
        },
        {
            title: 'Webhook URL',
            dataIndex: 'webhook_url',
            align: 'center',
            width: '140px',
            // ellipsis: {
            //     showTitle: true,
            // },
            render: (address) => (
                <Tooltip
                    placement="bottom"
                    overlayInnerStyle={{
                        width: address.length * 8 + 'px',
                        backgroundColor: '#d1d9da',
                        color: 'black',
                    }}
                    title={address}>
                    <InfoCircleOutlined/> <CopyToClipboard
                    text={address}
                    onCopy={() => {
                        openSuccessNotification('Webhook URL copied to clipboard');
                        // Modal.success({
                        //     title: 'Success',
                        //     content: 'Webhook URL copied to clipboard',
                        // })
                    }}
                >
                    <CopyOutlined/>
                </CopyToClipboard>
                </Tooltip>
            ),
            ...getColumnSearchProps('webhook_url', 'Search by Webhook URL')
        },
        {
            title: 'Webhook Message',
            dataIndex: 'message',
            align: 'center',
            width: '140px',
            // ellipsis: {
            //     showTitle: true,
            // },
            render: (message) => (
                <Tooltip
                    placement="topLeft"
                    overlayInnerStyle={{
                        width: '500px',
                        backgroundColor: '#d1d9da',
                        color: 'black',
                    }}
                    title={() => {
                        return <pre>{message}</pre>
                    }}>
                    <InfoCircleOutlined/> <CopyToClipboard
                    text={message}
                    onCopy={() => {
                        openSuccessNotification('Webhook Message copied to clipboard');
                        // Modal.success({
                        //     title: 'Success',
                        //     content: 'Webhook Message copied to clipboard',
                        // })
                    }}
                >
                    <CopyOutlined/>
                </CopyToClipboard>
                </Tooltip>
            ),
        },
        {
            title: 'Close Position Mode',
            dataIndex: 'close_position_mode',
            align: 'center',
            width: '180px',
            // defaultSortOrder: 'descend',
            sortIcon: (sorted) => sortIcon(sorted),
            sorter: (a, b) => a.close_position_mode.localeCompare(b.close_position_mode)
        },
        {
            title: 'Order Amount, USD',
            dataIndex: 'order_amount_usd',
            align: 'center',
            width: '100x',
            // defaultSortOrder: 'descend',
            sortIcon: (sorted) => sortIcon(sorted),
            sorter: (a, b) => a.order_amount_usd - b.order_amount_usd,
            render: (text, record) => {
                return <span style={{fontSize: 16, fontWeight: 700}}>{text}</span>
            }
            // render: (text, record) => {
            //     return <>
            //         {text} | {record.close_position_mode}
            //     </>
            // }
        },
        {
            title: '',
            dataIndex: 'operation',
            align: 'center',
            width: '150px',
            render: (_, record) =>
                dataSource.length >= 1 ? (
                    <>
                        <Space direction={"horizontal"}>
                            <Button style={{background: '#d1d9da'}}
                                    onClick={() => handleEdit(record)}>
                                <EditOutlined/>
                            </Button>
                            <Button type="text" style={{background: '#e80e19', color: "white"}}>
                                <Popconfirm title="Sure to delete Copy Bot?"
                                            onConfirm={() => handleDeleteBoot(record.ID)}>
                                    <DeleteOutlined/>
                                </Popconfirm>
                            </Button>
                        </Space>
                    </>
                ) : null,
        },
    ];

    return (
        <>
            {modalAddBot()}
            {modalEditBot()}
            <Content>
                <Row gutter={8} justify={"center"} align={"top"}>
                    <Col span={24}>
                        <ConfigProvider
                            theme={{
                                components: {
                                    Table: {
                                        // fontSize: "16px",
                                        fontFamily: 'Courier New',
                                        headerBorderRadius: '10px',
                                        headerColor: '#ffffff',
                                        headerBg: '#272762',
                                        bodySortBg: '#ffffff',
                                        headerSortHoverBg: '#272762',
                                        headerSortActiveBg: '#272762',
                                        cellFontSizeSM: "12px",
                                        cellPaddingInline: '10px',
                                        cellPaddingBlockSM: '5px',
                                        cellPaddingInlineSM: '5px',
                                        colorLinkActive: '#b21a2d',
                                    },
                                },
                            }}
                        >
                            <Table
                                rowKey={record => record.ID}
                                size="small"
                                pagination={{
                                    pageSizeOptions: [5, 10, 20, 50, 100],
                                    hideOnSinglePage: true,
                                    defaultPageSize: 10,
                                }}
                                loading={loading}
                                title={() =>
                                    <Row align={"middle"} justify={"end"}>
                                        <Button
                                            onClick={() => {
                                                getStrategy();
                                                setInputBotName('');
                                                setInputStrategyId(0);
                                                setInputOrderAmount(100)
                                                setInputClosePositionMode('CLOSE');
                                                setInputWebhookUrl('');
                                                setInputWebhookMessage(webhookMessageExample);
                                                setShowAddBotModal(true);
                                            }}
                                            type="primary"
                                            style={{
                                                marginTop: '0%',
                                                marginBottom: '0%',
                                            }}
                                        >
                                            Create Copy Bot (<span
                                            style={{color: "#fccece"}}>to external Webhook URL</span>)
                                        </Button>
                                    </Row>
                                }
                                style={{
                                    marginTop: '2%',
                                    marginLeft: '5%',
                                    marginRight: '5%',
                                }}
                                bordered
                                dataSource={dataSource}
                                columns={columns}
                                onRow={(record) => {
                                    return {
                                        // onClick: () => handleEdit(record.ID), // click row
                                        onDoubleClick: () => handleEdit(record), // double click row
                                        // onContextMenu: event => {
                                        // }, // right button click row
                                        // onMouseEnter: event => {
                                        // }, // mouse enter row
                                        // onMouseLeave: event => {
                                        // }, // mouse leave row
                                    };
                                }}
                            />
                        </ConfigProvider>
                    </Col>
                </Row>
            </Content>
        </>
    );
}

export default observer(BotsWebhook);