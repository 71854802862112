import {LockOutlined, UserOutlined,} from '@ant-design/icons';

import {LoginFormPage, ProConfigProvider, ProFormText,} from '@ant-design/pro-components';

import {Button, theme} from 'antd';
import React, {useEffect, useState} from 'react';

import API from "../../../api/user";

import {mobXStore, observer} from "../../../store";

import Logo from '../../../assets/images/logo_1844_1844.png';
import Video from '../../../assets/videos/video_background.mp4';
import {useNavigate, useSearchParams} from "react-router-dom";
import SpinLoader from "../../components/SpinLoader";

const LoginPage = () => {

    const apiUser = new API();

    const [queryParameters] = useSearchParams()

    const [userEmail, setUserEmail] = useState('');

    useEffect(() => {
        if (queryParameters.has('email')) {
            const email_on_url = queryParameters.get('email');
            if (email_on_url) {
                setUserEmail(email_on_url);
                sendOTP(email_on_url);
            }
        }
    }, [queryParameters]);

    useEffect(() => {
        console.log('userEmail: ', userEmail);
    }, [userEmail]);

    const navigate = useNavigate();

    const {token} = theme.useToken();

    const [enableEmailInput, setEnableEmailInput] = useState(true);
    const [enableOTPInput, setEnableOTPInput] = useState(false)

    const [codeOTPErr, setCodeOTPErr] = useState(false);
    const [codeOTPErrMsg, setCodeOTPErrMsg] = useState('');

    const [timer, setTimer] = useState(0);

    const [userLogin, setUserLogin] = useState(false);

    const [loading, setLoading] = useState(false);

    const [timerInterval, setTimerInterval] = useState(null);

    // Send OTP code to user's email
    const sendOTP = (email) => {

        if (email && email !== '') {
            setUserEmail(email);
        }

        if (enableEmailInput === true) {

            setLoading(true);

            if (timerInterval !== null && timerInterval !== undefined) {
                clearInterval(timerInterval);
            }

            apiUser.userSendOTP(userEmail === '' ? email : userEmail).then((res) => {

                if (res.result) {
                    setCodeOTPErrMsg('');
                    setCodeOTPErr(false);

                    setEnableEmailInput(false);
                    setEnableOTPInput(true);

                    setTimer(300);

                    setTimerInterval(setInterval(() => {
                        setTimer(prev => prev - 1);
                    }, 1000));

                } else {
                    // alert('OTP not sent! Error: ' + res.error);
                    // console.log('OTP not sent. Error: ' + res.error);
                    setCodeOTPErrMsg(res.error);
                    setCodeOTPErr(true);
                }

                setLoading(false);
            });

        }
    }

    useEffect(() => {
        resetTimer();
    }, []);

    useEffect(() => {
        if (timer <= 0) {
            resetTimer();
        }
    }, [timer]);

    useEffect(() => {
        if (userLogin === true) {
            navigate('/', {replace: true})
        }
    }, [userLogin]);

    const resetTimer = () => {
        setCodeOTPErrMsg('');
        setCodeOTPErr(false);

        timerInterval && clearInterval(timerInterval);

        setEnableEmailInput(true);
        setEnableOTPInput(false);
    }

    const validOTP = (otp, demo = false) => {

        if (!userEmail || userEmail === '') {
            setCodeOTPErrMsg('E-Mail is required!');
            return;
        }

        if (!otp) {
            setCodeOTPErrMsg('One-Time-Password (OTP) is required!');
            return;
        }

        setLoading(true);

        apiUser.userLogin(userEmail, otp).then((res) => {
            if (res.result === true) {
                mobXStore.setKey('login', true);
                mobXStore.setKey('demo_mode', demo);

                setCodeOTPErrMsg('');
                setCodeOTPErr(false);

                setUserLogin(true);
                // navigate('/', {replace: true})
                window.js_track(userEmail);

                apiUser.getUserProfile().then((response) => {
                    if (response.result) {
                        // console.log(response);
                        const profile = JSON.parse(response.data);
                        // mobXStore.setKey('ref_user_id', profile.ref_user_id);
                        mobXStore.setKey('language', profile.language);
                        mobXStore.setKey('free_mode', profile.code === 'FREE');
                        mobXStore.setKey('demo_mode', profile.code === 'FREE');
                        // console.log(response.data);
                    }
                });

            } else {
                setCodeOTPErrMsg('The OTP is not valid!');
                setCodeOTPErr(true);

                mobXStore.setKey('login', false);
                mobXStore.setKey('demo_mode', false);
                mobXStore.setKey('free_mode', false);
                setUserLogin(false);
            }

            setLoading(false);
        });

    }

    const GoToCDZV = (e) => {
        e.preventDefault();
        return (
            window.open('https://cdzv.com/index#pricing', '_blank')
        );
    }

    return (
        <div
            style={{
                backgroundColor: 'white',
                height: '100vh',
            }}
        >
            <LoginFormPage
                submitter={{
                    searchConfig: {
                        submitText: enableEmailInput ? 'Send the OTP-code to my e-mail' : 'Login',
                    },
                    render: (_, dom) => dom.pop(),
                    submitButtonProps: {
                        style: {
                            width: '100%',
                            borderRadius: 20,
                            background: token.colorPrimary,
                            color: token.colorText,
                        },
                        // onClick: () => {
                        // },
                    }
                }}

                onFinish={async (values) => {
                    if (enableEmailInput) {
                        resetTimer();
                        setUserEmail(values.email);
                        sendOTP(values.email);
                    } else if (enableOTPInput) {
                        validOTP(values.password, false)
                    }
                }}

                // backgroundImageUrl="https://cdzv.com/assets/images/bg/01_2.png"
                logo={Logo}
                // backgroundVideoUrl={"https://gw.alipayobjects.com/v/huamei_gcee1x/afts/video/jXRBRK_VAwoAAAAAAAAAAAAAK4eUAQBr"}
                backgroundVideoUrl={Video}
                title=""
                containerStyle={{
                    backgroundColor: 'rgba(0, 0, 0,0.65)',
                    backdropFilter: 'blur(4px)',
                }}
                subTitle={<>
                    {/*<Button*/}
                    {/*    type={'primary'}*/}
                    {/*    style={{*/}
                    {/*        width: '100%',*/}
                    {/*        marginTop: 10,*/}
                    {/*        marginBottom: 24,*/}
                    {/*        boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.2)',*/}
                    {/*        color: token.colorTextHeading,*/}
                    {/*        borderRadius: 15,*/}
                    {/*        // backgroundColor: 'rgba(255,255,255,0.1)',*/}
                    {/*        backdropFilter: 'blur(4px)',*/}
                    {/*    }}*/}
                    {/*    onClick={(e) => {*/}
                    {/*        validOTP('support@cdzv.com', 'demofordemo1', true)*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    Click for Demo Access*/}
                    {/*</Button>*/}
                    <h3> Code Zero Visual Trading</h3>
                </>}
                activityConfig={{
                    style: {
                        boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.2)',
                        color: token.colorTextHeading,
                        borderRadius: 8,
                        backgroundColor: 'rgba(255,255,255,0.1)',
                        backdropFilter: 'blur(4px)',
                    },
                    title: '',
                    subTitle: 'Register account at',
                    action: (
                        <Button
                            size="large"
                            style={{
                                borderRadius: 20,
                                background: token.colorBgElevated,
                                color: token.colorPrimary,
                                width: '100%',
                            }}
                            onClick={(e) => GoToCDZV(e)}
                        > cdzv.com </Button>
                    ),
                }}
                actions={
                    <>
                        <div style={{
                            marginTop: 24,
                            color: token.colorText,
                            textAlign: 'center',
                            textJustify: 'inter-word',
                            width: '100%'
                        }}>
                            {enableOTPInput &&
                                <>
                                    {codeOTPErr ?
                                        <h3 style={{color: token.colorError}}>{codeOTPErrMsg}</h3> :
                                        <span style={{color: "#f54c4c"}}>
                                            The password has been sent to your e-mail address. Check your email.
                                        </span>
                                    }
                                    {/*<br/>*/}
                                    <h3>Time remaining: {timer} seconds.</h3>
                                </>
                            }
                            {(!enableOTPInput && codeOTPErr) ?
                                <h3 style={{color: token.colorError}}>{codeOTPErrMsg}</h3> : null}
                            <SpinLoader tip={''} loading={loading}/>
                        </div>
                    </>
                }
            >
                <ProFormText
                    name="email"
                    disabled={!enableEmailInput}
                    value={userEmail}
                    fieldProps={{
                        size: 'large',
                        // value: userEmail,
                        prefix: (
                            <UserOutlined
                                style={{
                                    color: token.colorText,
                                }}
                                className={'prefixIcon'}
                            />
                        ),
                    }}
                    placeholder={'enter your e-mail'}
                    rules={[
                        {
                            required: true,
                            message: 'E-Mail is required! ',
                            validator: async (rule, value) => {
                                if (userEmail !== '') value = userEmail;
                                if (!value) {
                                    resetTimer();
                                    return Promise.reject('E-Mail is required! ');
                                }
                                if (!value.includes('@') || !value.includes('.')) {
                                    resetTimer();
                                    return Promise.reject('E-Mail is not valid! ');
                                }
                            }
                        },
                    ]}
                />
                <ProFormText
                    name="password"
                    disabled={!enableOTPInput}
                    fieldProps={{
                        size: 'large',
                        prefix: (
                            <LockOutlined
                                style={{
                                    color: token.colorText,
                                }}
                                className={'prefixIcon'}
                            />
                        ),
                    }}
                    placeholder={'One-Time-Password (OTP)'}
                    onChange={() => setCodeOTPErr(false)}
                    defaultValue={''}
                    // rules={[
                    //     {
                    //         required: true,
                    //         message: 'One-Time-Password (OTP) is required！',
                    //     },
                    // ]}
                />
                <div
                    style={{
                        marginBlockEnd: 24,
                    }}
                >
                    {/*<ProFormCheckbox noStyle name="term_agree"*/}
                    {/*                 onClick={() => alert('111')}>*/}
                    {/*    I agree to the Terms of Use*/}
                    {/*</ProFormCheckbox>*/}
                    <span style={{color: token.colorText}}> I agree to the Terms of Use:</span>
                    <a
                        style={{
                            float: 'right'
                        }}
                        href={'https://docs.cdzv.com/code-zero-visual-trading_en/term-of-use'}
                        target={'_blank'}
                    >
                        Terms of Use
                    </a>

                </div>
            </LoginFormPage>
        </div>
    );
};

function Login() {
    return (
        <ProConfigProvider dark>
            <LoginPage/>
        </ProConfigProvider>
    );
}

export default observer(Login);