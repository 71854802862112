import {Alert} from "antd";
import React from "react";

function Disclaimer(props) {
    return <Alert
        style={{
            fontSize: 10,
            width: '80%',
            textAlign: 'center',
        }}
        // message="Disclaimer"
        description={<>
            Disclaimer: The performance outcomes presented on cdzv.com are theoretical and subject to many
            limitations. It should not be assumed that any accounts will or can replicate the profits or
            losses similar to those depicted. Theoretical performance is often created looking back,
            which does not account for all the variables that can impact trading outcomes. Various
            market dynamics or the execution of specific trading strategies may introduce discrepancies
            that are not reflected in these theoretical results, potentially influencing actual trading
            negatively. Historical success does not guarantee future returns. Market conditions evolve,
            suggesting that the effectiveness of these strategies may diminish over time, necessitating
            new approaches. Additionally, performance can vary significantly among different brokers,
            and there is no expectation for backtested results to align precisely with actual market
            performance.
            <br/><br/>
            Charting is displayed using TradingView's technology, a platform, where you can track the <a
            href={"https://www.tradingview.com/economic-calendar/"} target={"_blank"}>latest events in
            the Economic calendar</a>, watch live prices, and more.
        </>}
        type="warning"
        // showIcon
        closable
    />
}

export default Disclaimer;