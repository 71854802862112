const INITIAL_STATE = {
    uid: 0,
    token: '',
    language: 'en',
    addStrategyID: [],
    currentMenu: 'strategy',
    login: false,
    active: false,
    loading: false,
    demo_mode: false,
    free_mode: false,
    only_one_asset: 0,
};

export default INITIAL_STATE;
