import {runInAction} from 'mobx';
import React, {useEffect, useRef, useState} from 'react';
import {
    Button,
    Checkbox,
    Col,
    Drawer,
    Image,
    Input,
    notification,
    Row,
    Select,
    Space,
    Switch,
    Tag,
    Tooltip,
} from 'antd';
import {Content} from "antd/es/layout/layout";

import {
    AreaChartOutlined,
    CaretDownOutlined,
    CaretUpOutlined,
    ClearOutlined,
    CloseOutlined,
    FullscreenExitOutlined,
    InfoCircleOutlined,
    InfoCircleTwoTone,
    LinkOutlined, OrderedListOutlined,
    RobotOutlined,
    SearchOutlined
} from "@ant-design/icons";
import API from "../../../api/user";
import {mobXStore, observer} from "../../../store";
import {showModalDemoWarning} from "../../../Helpers";
import StrategyCardLite from "../../components/StrategyCardLite";
import CustomTable from "../../components/CustomTable";
import EquityStats from "../../components/EquityStats/EquityStats";
import {getEquityStats} from "../../../api_backend/utils/sql";
import TradesList from "../../components/TradesList";

const timeframes = {
    "60": '1 hour',
    "120": '2 hours',
    "240": '4 hours',
    "480": '8 hours',
    "720": '12 hours',
    "1440": '1 day',
}

const TG_Channel_with_signals = 'https://t.me/+dOh0ycP9XgJkMTVi';

const content_msg = {
    "How to set up copying trades": {
        "en": "How to set up copying trades from a strategy to a crypto exchange?",
        "ua": "Як налаштувати копіювання угод з стратегії на криптобіржу?",
        "ru": "Как настроить копирование сделок из стратегии на криптобиржу?"
    },
    // "How to connect all crypto-strategies": {
    //     "en": "How to connect all crypto-strategies to exchange copy-trading?",
    //     "ua": "Як підключити всі крипто-стратегії до копіювання угод на біржі?",
    //     "ru": "Как подключить все крипто-стратегии к копированию сделок на бирже?"
    // },
    "Telegram channel with signals": {
        "en": "Telegram channel with all signals",
        "ua": "Телеграм канал зі всіма сигналами",
        "ru": "Телеграм канал со всеми сигналами"
    }
}

// SignalLogs component
function StrategyStats(props) {

    const tableRef = useRef();

    const language = mobXStore.getKey('language');

    const apiUser = new API();

    const [demoMode, setDemoMode] = useState(false);

    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState(false);

    const [is_active_strategies, setIsActiveStrategies] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const [tradesListOpen, setTradesListOpen] = useState(false);
    const [selectedStrategyId, setSelectedStrategyId] = useState([]);
    const [selectedStrategyName, setSelectedStrategyName] = useState('');

    const [equityStatsPeriod, setEquityStatsPeriod] = useState(1825);
    const [equityCurve, setEquityCurve] = useState(null);

    const [maxLossStreakCount, setMaxLossStreakCount] = useState(0);
    const [maxDrawdownInSeries, setMaxDrawdownInSeries] = useState(0);
    const [maxSingleTradeLoss, setMmxSingleTradeLoss] = useState(0);
    const [maxOpenTradesLong, setMaxOpenTradesLong] = useState(0);
    const [maxOpenTradesShort, setMaxOpenTradesShort] = useState(0);
    const [maxOpenTradesBoth, setMaxOpenTradesBoth] = useState(0);

    const [checkedStrategy, setCheckedStrategy] = useState([]);
    const [onChangeModeSelection, setOnChangeModeSelection] = useState(false);

    const [apiNotification, contextHolder] = notification.useNotification();

    const openErrorNotification = (message) => {
        apiNotification.error({
            type: 'error',
            message: 'Error',
            description: message,
            placement: 'topRight',
            duration: 3,
        });
    };

    const openSuccessNotification = (message) => {
        apiNotification.open({
            type: 'success',
            message: 'Success',
            description: message,
            placement: 'topRight',
            duration: 3,
        });
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const sortIcon = (sorted) => {
        // alert(JSON.stringify(sorted))
        if (sorted['sortOrder'] === 'descend') {
            return (<CaretDownOutlined
                style={{
                    color: '#1677ff'
                }}
            />)
        } else if (sorted['sortOrder'] === 'ascend') {
            return (<CaretUpOutlined
                style={{
                    color: '#1677ff'
                }}
            />)
        } else {
            return (<FullscreenExitOutlined
                style={{
                    color: 'white',
                }}
            />)
        }
    }

    const getColumnSearchProps = (dataIndex, tooltip_text = '') => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (
            <div
                style={{
                    // backgroundColor: '#0f0fa8',
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Space direction={'horizontal'}>
                    <Input
                        ref={searchInput}
                        placeholder={tooltip_text}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{
                            marginBottom: 8,
                            width: 200,
                            display: 'block',
                        }}
                    />
                    <Button
                        type="button"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        <CloseOutlined/>
                    </Button>
                </Space>
                <br/>
                <Space direction={'horizontal'}>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined/>}
                        size="small"
                        style={{
                            width: 115,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && (handleReset(clearFilters) || handleSearch(selectedKeys, confirm, dataIndex) || close())}
                        size="small"
                        style={{
                            width: 115,
                        }}
                    >
                        <ClearOutlined/> Reset
                    </Button>
                    {/*<Button*/}
                    {/*    type="link"*/}
                    {/*    size="small"*/}
                    {/*    onClick={() => {*/}
                    {/*        confirm({*/}
                    {/*            closeDropdown: false,*/}
                    {/*        });*/}
                    {/*        setSearchText(selectedKeys[0]);*/}
                    {/*        setSearchedColumn(dataIndex);*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    Filter*/}
                    {/*</Button>*/}
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#f6adb5' : 'white',
                }}
            />
        ),
        sortIcon: (sorted) => sortIcon(sorted),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        // render: (text) =>
        //     searchedColumn === dataIndex ? (
        //         <Highlighter
        //             highlightStyle={{
        //                 backgroundColor: '#ffc069',
        //                 padding: 0,
        //             }}
        //             searchWords={[searchText]}
        //             autoEscape
        //             textToHighlight={text ? text.toString() : ''}
        //         />
        //     ) : (
        //         text
        //     ),
    });

    useEffect(() => {
        setDemoMode(mobXStore.getKey('demo_mode'));
        getStrategyStats();
        getEquityCurve([]);
    }, []);

    useEffect(() => {
        // getStrategyStats();
        if (onChangeModeSelection && checkedStrategy.length > 0) {
            getEquityCurve(checkedStrategy);
        } else {
            getEquityCurve([]);
        }
    }, [equityStatsPeriod, checkedStrategy, onChangeModeSelection]);

    useEffect(() => {
        setDemoMode(mobXStore.getKey('demo_mode'));
        getStrategyStats();
        if (onChangeModeSelection && checkedStrategy.length > 0) {
            getEquityCurve(checkedStrategy);
        } else {
            getEquityCurve([]);
        }
    }, [is_active_strategies]);

    useEffect(() => {
        console.log('...');
    }, [dataSource, checkedStrategy, onChangeModeSelection]);

    const openOrFocusWindow = () => {
        // Получаем сохраненный id окна
        const windowName = 'copyWindow_' + Date.now();

        try {
            const existingWindow = window.open('', 'copyWindowName');

            // Проверяем существует ли окно и не закрыто ли оно
            if (existingWindow && !existingWindow.closed && existingWindow.location.href !== 'about:blank') {
                existingWindow.focus();
                existingWindow.location.href = '/?copy';
            } else {
                // Создаем новое окно с именем
                const newWindow = window.open('/?copy', 'copyWindowName', 'noopener,noreferrer');
                if (newWindow) {
                    newWindow.opener = null;
                }
            }
        } catch (e) {
            console.error('Error handling window:', e);
            // Если произошла ошибка, создаем новое окно
            const newWindow = window.open('/?copy', 'copyWindowName', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null;
        }
    };

    const onClickAddStrategyCrypto = (strategy) => {
        runInAction(() => {
            try {
                mobXStore.setKey('addStrategyID', [strategy.id]);
                openOrFocusWindow();
            } catch (e) {
                console.error(e);
            }
        });
    };

    function getStrategyStats() {
        setLoading(true);
        apiUser.getStrategyStats({
            'data': 'getStrategyStats',
            'is_active': is_active_strategies
        }).then((response) => {
            if (response.result) {
                // console.log(response);
                const ss = JSON.parse(response.data);

                apiUser.getAllStrategiesStats({
                    'data': 'getAllStrategiesStats',
                    'is_active': is_active_strategies
                }).then((response) => {
                    if (response.result) {
                        // console.log(response);
                        const rr = JSON.parse(response.data);
                        let result = ss.map((item) => {
                            let strategy = rr.find((strategy) => strategy.strategy_id === item.id);
                            return {
                                ...item,
                                ...strategy,
                            }
                        });

                        setDataSource(result);
                        // console.log(result);
                    }
                    setLoading(false);
                });
            } else {
                setDataSource([]);
                setLoading(false);
                openErrorNotification('Error loading data. Please re-login.');
            }
        });
    }

    function getEquityCurve(strategy_ids = []) {
        // setLoading(true);
        apiUser.getEquity({
            'data': 'getEquityCurve',
            'strategy_ids': strategy_ids,
            'period_in_days': equityStatsPeriod,
            'is_active': is_active_strategies
        }).then((response) => {
            if (response.result) {
                setEquityCurve(JSON.parse(response.data));
                // console.log(JSON.parse(response.data));
            } else {
                setEquityCurve([]);
                openErrorNotification('Error loading data. Please re-login.');
            }
            // setLoading(false);
        });

        apiUser.getEquityStats({
            'data': 'getEquityStats',
            'strategy_ids': strategy_ids,
            'period_in_days': equityStatsPeriod,
            'is_active': is_active_strategies
        }).then((response) => {
            if (response.result) {
                const rez = JSON.parse(response.data)[0];
                if (rez) {
                    setMaxLossStreakCount(rez.max_loss_streak_count);
                    setMaxDrawdownInSeries(rez.max_drawdown_in_series);
                    setMmxSingleTradeLoss(rez.max_single_trade_loss);
                } else {
                    setMaxLossStreakCount(0);
                    setMaxDrawdownInSeries(0);
                    setMmxSingleTradeLoss(0);
                    openErrorNotification('Error loading data. Please re-login.');
                }
            }
            // setLoading(false);
        });

        apiUser.getMaxOpenTrades({
            'data': 'getMaxOpenTrades',
            'strategy_ids': strategy_ids,
            'period_in_days': equityStatsPeriod,
            'is_active': is_active_strategies
        }).then((response) => {
            if (response.result) {
                // alert(JSON.stringify(response.data));
                const rez = JSON.parse(response.data)[0];
                if (rez) {
                    setMaxOpenTradesLong(rez.max_concurrent_long);
                    setMaxOpenTradesShort(rez.max_concurrent_short);
                    setMaxOpenTradesBoth(rez.max_concurrent_both);
                } else {
                    setMaxOpenTradesLong(0);
                    setMaxOpenTradesShort(0);
                    setMaxOpenTradesBoth(0);
                    openErrorNotification('Error loading data. Please re-login.');
                }
            }
        });
    }

    const columns = [
        {
            key: 'name',
            title: 'Name',
            dataIndex: 'name',
            align: 'left',
            width: '170px',
            fixed: 'left',
            onCell: () => ({
                style: {
                    backgroundColor: '#eff0fc',
                }
            }),
            render: (text, record) => {
                return <>
                    <Tooltip title="Open Trades List">
                        <Button
                            type={"primary"}
                            size={"small"}
                            onClick={(e) => {
                                e.preventDefault();
                                setSelectedStrategyId([record.id]);
                                setSelectedStrategyName(record.name + '-' + record.id + ' | ' + record.symbol.replace('BINANCE:', '').replace('.P', '').replace('USDT', '/USDT') + ' | ' + ' TF: ' + record.timeframe);
                                setTradesListOpen(true);
                            }}>
                            <OrderedListOutlined/>
                        </Button>&nbsp;
                    </Tooltip>
                    <strong><span style={{fontSize: 16}}>{text}</span></strong>&nbsp;
                    <Tooltip
                        placement="right"
                        title={<StrategyCardLite
                            strategy={{
                                'id': record.name + '-' + record.id,
                                'pnl_annual': record.net_profit_percentage,
                                'leverage': record.leverage,
                                'market': record.market,
                                'direction': record.direction,
                                'symbol': record.symbol.replace('BINANCE:', ''),
                                'draw_down': record.max_drawdown_percentage,
                                'max_loss_orders_session': record.max_loss_streak,
                                'win_rate': record.percent_profitable_all,
                                'profit_factor': record.profit_factor_all
                            }}
                        />}
                        overlayInnerStyle={{
                            width: '550px',
                            backgroundColor: '#efefefef',
                        }}
                    >
                        <InfoCircleTwoTone style={{cursor: 'pointer'}}/>
                    </Tooltip>
                    <br/>
                    <span style={{fontSize: 10}}>
                        Updated: {Math.round((new Date().getTime() - new Date(record.created_at).getTime()) / 1000 / 60 / 60)} hours ago
                    </span>
                </>
            },
            // defaultSortOrder: 'ascend',
            sortIcon: (sorted) => sortIcon(sorted),
            sorter: (a, b) => a.name.localeCompare(b.name),
            ...getColumnSearchProps('name', 'Search by Name'),
        },
        {
            key: 'symbol',
            title: 'Symbol',
            dataIndex: 'symbol',
            // width: '15%',
            align: 'center',
            fixed: 'left',
            onCell: () => ({
                style: {
                    backgroundColor: '#eff0fc',
                }
            }),
            render: (text, record) => {
                const s = text.replace('USDT.P', '').replace('BINANCE:', '');
                return <>
                    <strong>{s}</strong>&nbsp;
                    {/*{record.market === 'CRYPTO' ?*/}
                    {/*    <Tag color={"green"}>{record.market}</Tag> :*/}
                    {/*    <Tag color={"blue"}>{record.market}</Tag>*/}
                    {/*}*/}
                </>
            },
            // defaultSortOrder: 'none',
            sortIcon: (sorted) => sortIcon(sorted),
            sorter: (a, b) => a.symbol.localeCompare(b.symbol),
            // defaultSortOrder: 'descend',
            // sorter: (a, b) => a.exchange.localeCompare(b.exchange)
            ...getColumnSearchProps('symbol', 'Search by Symbol name'),
        },
        {
            key: 'id',
            title: 'ID',
            dataIndex: 'id',
            // width: '15%',
            align: 'center',
            hideable: true,
            // defaultSortOrder: 'descend',
            sortIcon: (sorted) => sortIcon(sorted),
            sorter: (a, b) => a.id - b.id,
            // defaultSortOrder: 'descend',
            // sorter: (a, b) => a.exchange.localeCompare(b.exchange)
            ...getColumnSearchProps('id', 'Search by ID'),
        },
        {
            key: 'loss_series_flag',
            title: 'Stability',
            dataIndex: 'loss_series_flag',
            // width: '15%',
            align: 'center',
            hideable: true,
            render: (text) => {
                return <>
                    {text === 0 ?
                        <Tooltip title={"HIGH Stability: The current losing streak is lower than the backtest."}><Tag
                            color={"#2cab1d"}>HIGH</Tag></Tooltip> : text === 1 ?
                            <Tooltip
                                title={"NORMAL Stability: The current losing streak is approaching the maximum losing streak with the backtest."}><Tag
                                color={"#0f0fa8"}>NORMAL</Tag></Tooltip> :
                            <Tooltip
                                title={"LOW Stability: The current losing streak has exceeded the maximum losing streak on the backtest. It is worth considering the option of disabling the strategy."}><Tag
                                color={"#b21a2d"}>LOW</Tag></Tooltip>
                    }
                </>
            },
            filterIcon: (filtered) => (
                <SearchOutlined
                    style={{
                        color: filtered ? '#f6adb5' : 'white',
                    }}
                />
            ),
            filters: [
                {text: 'ALL', value: '-1'},
                {text: 'HIGH', value: '0'},
                {text: 'NORMAL', value: '1'},
                {text: 'LOW', value: '2'},
            ],
            onFilter: (value, record) => value === '-1' || record.loss_series_flag + '' === value + '',
            // Optional: change how filter dropdown looks
            filterMultiple: false, // allows multiple selection
            // You can customize the dropdown style
            filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
                <div style={{padding: 8}}>
                    <Select
                        style={{width: 188, marginBottom: 8, display: 'block'}}
                        placeholder="Select Stability Level"
                        value={selectedKeys[0]}
                        onChange={(value) => {
                            setSelectedKeys(value ? [value] : []);
                            confirm();
                        }}
                        options={[
                            {label: <Tag color={"#2cab1d"}>HIGH</Tag>, value: '0'},
                            {label: <Tag color={"#0f0fa8"}>NORMAL</Tag>, value: '1'},
                            {label: <Tag color={"#b21a2d"}>LOW</Tag>, value: '2'},
                            {label: <Tag color={"black"}>ALL</Tag>, value: '-1'},
                        ]}
                        dropdownStyle={{minWidth: 188}}
                    />
                </div>
            ),
            // defaultSortOrder: 'descend',
            sortIcon: (sorted) => sortIcon(sorted),
            sorter: (a, b) => a.loss_series_flag - b.loss_series_flag,
            // defaultSortOrder: 'descend',
            // sorter: (a, b) => a.exchange.localeCompare(b.exchange)
            // ...getColumnSearchProps('direction', 'Search by Direction'),
        },
        {
            key: 'exchange',
            title: 'Market',
            dataIndex: 'market',
            // width: '15%',
            align: 'center',
            // hideable: true,
            render: (text) => {
                return <>
                    {/*<strong>{s}</strong>&nbsp;*/}
                    {text === 'CRYPTO' ?
                        <Tag color={"cyan-inverse"}>{text}</Tag> :
                        <Tag color={"blue-inverse"}>{text}</Tag>
                    }
                </>
            },
            filterIcon: (filtered) => (
                <SearchOutlined
                    style={{
                        color: filtered ? '#f6adb5' : 'white',
                    }}
                />
            ),
            filters: [
                {text: 'ALL', value: ''},
                {text: 'STOCKS', value: 'STOCKS'},
                {text: 'CRYPTO', value: 'CRYPTO'},
            ],
            onFilter: (value, record) => value === '' || record.market === value,
            // Optional: change how filter dropdown looks
            filterMultiple: false, // allows multiple selection
            // You can customize the dropdown style
            filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
                <div style={{padding: 8}}>
                    <Select
                        style={{width: 188, marginBottom: 8, display: 'block'}}
                        placeholder="Select Side"
                        value={selectedKeys[0]}
                        onChange={(value) => {
                            setSelectedKeys(value ? [value] : []);
                            confirm();
                        }}
                        options={[
                            {label: <Tag color={"cyan-inverse"}>CRYPTO</Tag>, value: 'CRYPTO'},
                            {label: <Tag color={"blue-inverse"}>STOCKS</Tag>, value: 'STOCKS'},
                            {label: <Tag color={"black"}>ALL</Tag>, value: ''},
                        ]}
                        dropdownStyle={{minWidth: 188}}
                    />
                </div>
            ),
            // defaultSortOrder: 'descend',
            sortIcon: (sorted) => sortIcon(sorted),
            sorter: (a, b) => a.market.localeCompare(b.market),
            // defaultSortOrder: 'descend',
            // sorter: (a, b) => a.exchange.localeCompare(b.exchange)
            // ...getColumnSearchProps('market', 'Search by Market'),
        },
        {
            key: 'direction',
            title: 'Side',
            dataIndex: 'direction',
            // width: '15%',
            align: 'center',
            render: (text) => {
                return <>
                    {text === 'LONG' ?
                        <Tooltip title={"LONG"}><Tag color={"green"}>L</Tag></Tooltip> : text === 'BOTH' ?
                            <Tooltip title={"LONG / SHORT"}><Tag color={"blue"}>B</Tag></Tooltip> :
                            <Tooltip title={"SHORT"}><Tag color={"red"}>S</Tag></Tooltip>
                    }
                </>
            },
            filterIcon: (filtered) => (
                <SearchOutlined
                    style={{
                        color: filtered ? '#f6adb5' : 'white',
                    }}
                />
            ),
            filters: [
                {text: 'BOTH', value: 'B'},
                {text: 'LONG', value: 'L'},
                {text: 'SHORT', value: 'S'},
                {text: 'ALL', value: ''},
            ],
            onFilter: (value, record) => value === '' || record.direction === value,
            // Optional: change how filter dropdown looks
            filterMultiple: true, // allows multiple selection
            // You can customize the dropdown style
            filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
                <div style={{padding: 8}}>
                    <Select
                        style={{width: 188, marginBottom: 8, display: 'block'}}
                        placeholder="Select Side"
                        value={selectedKeys[0]}
                        onChange={(value) => {
                            setSelectedKeys(value ? [value] : []);
                            confirm();
                        }}
                        options={[
                            {label: <Tag color={"black"}>ALL</Tag>, value: ''},
                            {label: <Tag color={"blue"}>BOTH</Tag>, value: 'BOTH'},
                            {label: <Tag color={"green"}>LONG</Tag>, value: 'LONG'},
                            {label: <Tag color={"red"}>SHORT</Tag>, value: 'SHORT'},
                        ]}
                        dropdownStyle={{minWidth: 188}}
                    />
                </div>
            ),
            // defaultSortOrder: 'descend',
            sortIcon: (sorted) => sortIcon(sorted),
            sorter: (a, b) => a.direction.localeCompare(b.direction),
            // defaultSortOrder: 'descend',
            // sorter: (a, b) => a.exchange.localeCompare(b.exchange)
            // ...getColumnSearchProps('direction', 'Search by Direction'),
        },
        {
            key: 'timeframe',
            title: 'TimeFrame',
            dataIndex: 'timeframe',
            align: 'center',
            hideable: true,
            // width: '100px',
            render: (text) => {
                if (timeframes[text]) {
                    return <><strong>{timeframes[text]}</strong></>
                } else if (text < 60) {
                    return <><strong>{text} minutes</strong></>
                } else {
                    return <><strong>{text} days</strong></>
                }
            },
            sortIcon: (sorted) => sortIcon(sorted),
            // defaultSortOrder: 'descend',
            sorter: (a, b) => a.timeframe.localeCompare(b.timeframe),
            ...getColumnSearchProps('timeframe', 'Search by TimeFrame'),
        },
        {
            key: 'leverage',
            title: 'Leverage',
            dataIndex: 'leverage',
            align: 'center',
            hideable: true,
            summaryPrefix: 'Ø ',
            aggregate: 'avg',
            // width: '100px',
            render: (text) => {
                if (text) {
                    return <><strong>{text}</strong></>
                } else {
                    return <><strong>♾️</strong></>
                }
            },
            sortIcon: (sorted) => sortIcon(sorted),
            // defaultSortOrder: 'descend',
            sorter: (a, b) => a.leverage - b.leverage
        },
        {
            key: 'net_profit_percentage',
            title: 'Net Profit',
            dataIndex: 'net_profit_percentage',
            align: 'center',
            // width: '100px',
            render: (text) => {
                if (text < 0) {
                    //  red
                    return <><strong style={{color: 'red'}}>{text}%</strong></>
                } else {
                    //  green
                    return <><strong style={{color: 'green'}}>{text}%</strong></>
                }
            },
            summaryPrefix: 'Ø ',
            aggregate: 'avg',
            summarySuffix: '%',
            hideable: true,
            sortIcon: (sorted) => sortIcon(sorted),
            // defaultSortOrder: 'descend',
            sorter: (a, b) => a.net_profit_percentage - b.net_profit_percentage
        },
        {
            key: 'APR',
            title: 'APR',
            dataIndex: 'APR',
            align: 'center',
            width: '100px',
            hideable: true,
            summaryPrefix: 'Ø ',
            aggregate: 'avg',
            summarySuffix: '%',
            render: (text) => {
                if (text <= 0) {
                    return <><strong style={{color: 'red'}}>{text}%</strong></>
                } else {
                    return <><strong>{text}%</strong></>
                }
            },
            sortIcon: (sorted) => sortIcon(sorted),
            // defaultSortOrder: 'descend',
            sorter: (a, b) => a.APR - b.APR
        },
        {
            key: 'percent_profitable_all',
            title: 'Win Rate',
            dataIndex: 'percent_profitable_all',
            align: 'center',
            hideable: true,
            summaryPrefix: 'Ø ',
            aggregate: 'avg',
            summarySuffix: '%',
            // width: '100px',
            render: (text) => {
                return <><strong>{text}%</strong></>
            },
            sortIcon: (sorted) => sortIcon(sorted),
            // defaultSortOrder: 'descend',
            sorter: (a, b) => a.percent_profitable_all - b.percent_profitable_all
        },
        {
            key: 'profit_factor_all',
            title: 'Profit Factor',
            dataIndex: 'profit_factor_all',
            align: 'center',
            // width: '100px',
            hideable: true,
            summaryPrefix: 'Ø ',
            aggregate: 'avg',
            render: (text) => {
                if (text) {
                    return <><strong>{text}</strong></>
                } else {
                    return <><strong>♾️</strong></>
                }
            },
            sortIcon: (sorted) => sortIcon(sorted),
            // defaultSortOrder: 'descend',
            sorter: (a, b) => a.profit_factor_all - b.profit_factor_all
        },
        {
            key: 'win_loss_ratio_all',
            title: 'Risk Reward',
            dataIndex: 'win_loss_ratio_all',
            align: 'center',
            // width: '100px',
            hideable: true,
            summaryPrefix: 'Ø 1:',
            aggregate: 'avg',
            render: (text) => {
                if (text) {
                    return <><strong>1:{Math.round(text * 100) / 100}</strong></>
                } else {
                    return <><strong>1:♾️</strong></>
                }
            },
            sortIcon: (sorted) => sortIcon(sorted),
            // defaultSortOrder: 'descend',
            sorter: (a, b) => a.win_loss_ratio_all - b.win_loss_ratio_all
        },
        {
            key: 'total_closed_trades_all',
            title: 'Trades',
            dataIndex: 'total_closed_trades_all',
            align: 'center',
            hideable: true,
            width: '90px',
            summaryPrefix: '∑ ',
            aggregate: 'sum',
            // width: '100px',
            render: (text, record) => {
                return <><strong>{text + record['total_open_trades_all']}</strong></>
            },
            sortIcon: (sorted) => sortIcon(sorted),
            // defaultSortOrder: 'descend',
            sorter: (a, b) => (a.total_closed_trades_all + a.total_open_trades_all) - (b.total_closed_trades_all + b.total_open_trades_all)
        },
        {
            key: 'max_drawdown_percentage',
            title: 'Max. Drawdown',
            dataIndex: 'max_drawdown_percentage',
            align: 'center',
            hideable: true,
            summaryPrefix: 'Ø ',
            aggregate: 'avg',
            summarySuffix: '%',
            // width: '100px',
            render: (text) => {
                if (text) {
                    return <><strong>{text}%</strong></>
                } else {
                    return <><strong>0</strong></>
                }
            },
            sortIcon: (sorted) => sortIcon(sorted),
            // defaultSortOrder: 'descend',
            sorter: (a, b) => a.max_drawdown_percentage - b.max_drawdown_percentage
        },
        {
            key: 'max_loss_streak',
            title: 'Max. Loss Series',
            dataIndex: 'max_loss_streak',
            align: 'center',
            hideable: true,
            summaryPrefix: 'Ø ',
            aggregate: 'avg',
            // width: '100px',
            render: (text) => {
                if (text > 5) {
                    return <><strong style={{color: 'red'}}>{text}</strong></>
                } else {
                    return <><strong>{text}</strong></>
                }
            },
            sortIcon: (sorted) => sortIcon(sorted),
            // defaultSortOrder: 'descend',
            sorter: (a, b) => a.max_loss_streak - b.max_loss_streak
        },
        {
            key: 'largest_winning_trade_percentage',
            title: 'Max. Winning Trade',
            dataIndex: 'largest_winning_trade_percentage',
            align: 'center',
            hideable: true,
            summaryPrefix: 'Ø ',
            aggregate: 'avg',
            // width: '100px',
            render: (text) => {
                const value = text === null ? 0.0 : text;
                if (value < 1) {
                    return <><strong style={{color: 'red'}}>{value}%</strong></>
                } else {
                    return <><strong>{value}%</strong></>
                }
            },
            sortIcon: (sorted) => sortIcon(sorted),
            // defaultSortOrder: 'descend',
            sorter: (a, b) => a.largest_winning_trade_percentage - b.largest_winning_trade_percentage
        },
        {
            key: 'largest_losing_trade_percentage',
            title: 'Max. Loss Trade',
            dataIndex: 'largest_losing_trade_percentage',
            align: 'center',
            hideable: true,
            summaryPrefix: 'Ø ',
            aggregate: 'avg',
            // width: '100px',
            render: (text) => {
                const value = text === null ? 0.0 : text;
                if (value > 15) {
                    return <><strong style={{color: 'red'}}>{value}%</strong></>
                } else {
                    return <><strong>{value}%</strong></>
                }
            },
            sortIcon: (sorted) => sortIcon(sorted),
            // defaultSortOrder: 'descend',
            sorter: (a, b) => a.largest_losing_trade_percentage - b.largest_losing_trade_percentage
        },
        {
            key: 'profit_last_7_days',
            title: 'Profit last 7 days',
            dataIndex: 'profit_last_7_days',
            align: 'center',
            hideable: true,
            // width: '100px',
            summaryPrefix: '∑ ',
            aggregate: 'sum',
            summarySuffix: '%',
            render: (text) => {
                if (text < 0) {
                    return <><strong style={{color: 'red'}}>{text}%</strong></>
                } else {
                    return <><strong style={{color: 'green'}}>{text}%</strong></>
                }
            },
            sortIcon: (sorted) => sortIcon(sorted),
            // defaultSortOrder: 'descend',
            sorter: {
                compare: (a, b) => a.profit_last_7_days - b.profit_last_7_days,
                // multiple: 1,
            }
        },
        {
            key: 'profit_last_30_days',
            title: 'Profit last 30 days',
            dataIndex: 'profit_last_30_days',
            align: 'center',
            hideable: true,
            summaryPrefix: '∑ ',
            aggregate: 'sum',
            summarySuffix: '%',
            // width: '100px',
            render: (text) => {
                if (text < 0) {
                    return <><strong style={{color: 'red'}}>{text}%</strong></>
                } else {
                    return <><strong style={{color: 'green'}}>{text}%</strong></>
                }
            },
            sortIcon: (sorted) => sortIcon(sorted),
            defaultSortOrder: 'descend',
            sorter: {
                compare: (a, b) => a.profit_last_30_days - b.profit_last_30_days,
                // multiple: 2,
            }
        },
        {
            key: 'profit_last_90_days',
            title: 'Profit last 90 days',
            dataIndex: 'profit_last_90_days',
            align: 'center',
            hideable: true,
            // width: '100px',
            summaryPrefix: '∑ ',
            aggregate: 'sum',
            summarySuffix: '%',
            render: (text) => {
                if (text < 0) {
                    return <><strong style={{color: 'red'}}>{text}%</strong></>
                } else {
                    return <><strong style={{color: 'green'}}>{text}%</strong></>
                }
            },
            sortIcon: (sorted) => sortIcon(sorted),
            // defaultSortOrder: 'descend',
            sorter: {
                compare: (a, b) => a.profit_last_90_days - b.profit_last_90_days,
                // multiple: 3,
            }
        },
        {
            key: 'avg_trades_per_day',
            title: 'Avg. trades per day',
            dataIndex: 'avg_trades_per_day',
            align: 'center',
            hideable: true,
            summaryPrefix: 'Ø ',
            aggregate: 'avg',
            // width: '100px',
            render: (text) => {
                return <><strong>{Math.round(text * 100) / 100}</strong></>
            },
            sortIcon: (sorted) => sortIcon(sorted),
            // defaultSortOrder: 'descend',
            sorter: (a, b) => a.avg_trades_per_day - b.avg_trades_per_day
        },
        {
            key: 'avg_trades_per_week',
            title: 'Avg. trades per week',
            dataIndex: 'avg_trades_per_week',
            align: 'center',
            hideable: true,
            summaryPrefix: 'Ø ',
            aggregate: 'avg',
            // width: '100px',
            render: (text) => {
                return <><strong>{Math.round(text * 100) / 100}</strong></>
            },
            sortIcon: (sorted) => sortIcon(sorted),
            // defaultSortOrder: 'descend',
            sorter: (a, b) => a.avg_trades_per_week - b.avg_trades_per_week
        },
        {
            key: 'avg_trades_per_month',
            title: 'Avg. trades per month',
            dataIndex: 'avg_trades_per_month',
            align: 'center',
            hideable: true,
            summaryPrefix: 'Ø ',
            aggregate: 'avg',
            // width: '100px',
            render: (text) => {
                return <><strong>{Math.round(text * 100) / 100}</strong></>
            },
            sortIcon: (sorted) => sortIcon(sorted),
            // defaultSortOrder: 'descend',
            sorter: (a, b) => a.avg_trades_per_month - b.avg_trades_per_month
        },
        {
            key: 'started_at',
            title: 'Started at',
            dataIndex: 'started_at',
            align: 'center',
            hideable: true,
            summaryPrefix: 'Ø ',
            aggregate: 'avg',
            // width: '100px',
            render: (text) => {
                // Convert to date format dd.mm.yyyy
                return <><strong>{new Date(text).toLocaleDateString()}</strong></>
            },
            sortIcon: (sorted) => sortIcon(sorted),
            // defaultSortOrder: 'descend',
            sorter: (a, b) => a.started_at.localeCompare(b.started_at)
        },
        {
            key: 'total_open_trades_all',
            title: 'Equity curve',
            dataIndex: 'operation1',
            align: 'center',
            fixed: 'right',
            onCell: () => ({
                style: {
                    backgroundColor: '#eff0fc',
                }
            }),
            // width: '50px',
            render: (_, record) =>
                dataSource.length >= 1 ? (
                    <>
                        <Image
                            src={record.equity_curve_url}
                            width={100}
                            height={50}
                            style={{borderRadius: 10}}
                            preview={true}
                        />
                    </>
                ) : null,
        },
        {
            key: 'tv_url',
            title: '',
            dataIndex: 'operation2',
            align: 'center',
            // width: '50px',
            fixed: 'right',
            onCell: () => ({
                style: {
                    backgroundColor: '#eff0fc',
                }
            }),
            render: (_, record) =>
                dataSource.length >= 1 ? (
                    <>
                        <Button
                            type={"primary"}
                            size={'small'}
                            style={{
                                width: 140,
                                // backgroundColor: 'blue',
                            }}
                            // icon={<LinkOutlined/>}
                            onClick={(e) => {
                                e.preventDefault();
                                if (!demoMode) {
                                    window.open(record.tv_url, '_blank');
                                } else {
                                    showModalDemoWarning();
                                }
                            }}>
                            <LinkOutlined/> TradingView
                        </Button>
                        <br/>
                        <Tooltip
                            key={record.id}
                            title={record.market !== 'CRYPTO' ? 'Copying of trades is only allowed for cryptocurrencies' : ''}>
                            {(!onChangeModeSelection && is_active_strategies === 1) &&
                                <Button
                                    type={"primary"}
                                    disabled={record.market !== 'CRYPTO'}
                                    size={'small'}
                                    style={{
                                        backgroundColor: record.market !== 'CRYPTO' ? '#cececece' : '#468a3c',
                                        marginTop: 3,
                                        width: 140
                                    }}
                                    onClick={e => {
                                        e.preventDefault();
                                        onClickAddStrategyCrypto(record)
                                    }}>
                                    <RobotOutlined/> Try Copy Bot
                                </Button>
                            }
                            {(onChangeModeSelection && is_active_strategies === 1) && <>
                                <Checkbox
                                    // style={{
                                    //     marginTop: 5,
                                    //     marginLeft: 10
                                    // }}
                                    disabled={!onChangeModeSelection || record.market !== 'CRYPTO'}
                                    key={record.id}
                                    checked={checkedStrategy.includes(record.id)}
                                    onChange={(e) => {
                                        onClickAddStrategyCryptoToMultipleList(e, record)
                                    }}
                                />
                                &nbsp;Select for copy
                            </>
                            }
                        </Tooltip>
                    </>
                ) : null,
        },
    ];

    function onClickAddStrategyCryptoToMultipleList(e, record) {
        runInAction(() => {
            let addStrategyID = mobXStore.getKey('addStrategyID') || [];

            if (e.target.checked) {
                addStrategyID = [...addStrategyID, record.id];
            } else {
                addStrategyID = addStrategyID.filter((item) => item !== record.id);
            }

            mobXStore.setKey('addStrategyID', addStrategyID);
            setCheckedStrategy(addStrategyID);
        });
    }

    const onClickAddStrategyCryptoMultiple = () => {
        runInAction(() => {
            try {
                mobXStore.setKey('addStrategyID', checkedStrategy);
                openOrFocusWindow();
            } catch (e) {
                console.error(e);
            }
        });
    }

    return (
        <>
            {contextHolder}
            <Drawer
                title={
                    <span style={{fontSize: 22}}>
                        <OrderedListOutlined/> {selectedStrategyName}
                    </span>
                }
                placement={"bottom"}
                width={"100%"}
                height={'100%'}
                onClose={() => setTradesListOpen(false)}
                open={tradesListOpen}
            >
                <TradesList
                    strategy_id={selectedStrategyId}
                    show_extended={false}
                />
            </Drawer>
            <Content>
                <Button
                    // type="primary"
                    style={{
                        backgroundColor: 'rgb(130,217,118)',
                        position: 'fixed',
                        top: 10,
                        left: 210,
                        zIndex: 1000,
                        borderRadius: 50,
                        fontWeight: 700,
                        fontFamily: 'Terminal, monospace',
                        boxShadow: '0 0 10px 0 rgba(0,0,0,0.5)',
                    }}
                    onClick={e => {
                        e.preventDefault();
                        if (!demoMode) {
                            window.open(TG_Channel_with_signals, '_blank');
                        } else {
                            showModalDemoWarning();
                        }
                    }}>
                    <Tooltip
                        placement="top"
                        // overlayInnerStyle={{
                        //     width: '400px',
                        //     backgroundColor: '#efefefef',
                        //     color: 'red',
                        //     fontSize: '20px'
                        // }}
                        title={"In the 'Profile' section, you need to fill in the section with your Telegram name"}>
                        <InfoCircleOutlined/> {content_msg["Telegram channel with signals"][language]}
                    </Tooltip>
                </Button>
                <Row gutter={8} justify={"center"} align={"top"}>
                    <Col span={24}>
                        <CustomTable
                            actionRef={tableRef}
                            columns={columns}
                            dataSource={dataSource}
                            scroll={{x: 'max-content'}}
                            rowKey={record => record.key}
                            size="small"
                            pagination={{
                                pageSizeOptions: [5, 8, 10, 15, 20, 50, 100],
                                hideOnSinglePage: true,
                                showSizeChanger: true, // Включает выбор количества записей на странице
                                defaultPageSize: 8,
                                showTotal: (total) => `Total ${total} records`, // Показывает общее количество записей,
                            }}
                            loading={loading}
                            title={() => {
                                return (
                                    <>
                                        <Space direction={'horizontal'}>
                                            <h1><RobotOutlined/> Ready-made strategies</h1>
                                            {is_active_strategies === 1 ? <>Active</> : <>In-Active</>}: <strong
                                            style={{color: 'green', fontSize: 16}}>{dataSource.length}</strong>
                                        </Space>&nbsp;&nbsp;
                                        <Switch
                                            defaultChecked
                                            style={{
                                                width: 100,
                                                fontSize: 34,
                                            }}
                                            checkedChildren="Active"
                                            unCheckedChildren="In-Active"
                                            onClick={() => {
                                                setIsActiveStrategies(is_active_strategies ? 0 : 1);
                                            }}>
                                            {is_active_strategies ? 'Active' : 'Inactive'}
                                        </Switch>

                                        {/*Switch single / multiple selection*/}
                                        {is_active_strategies === 1 &&
                                            <>
                                                <br/>
                                                <div style={{marginBottom: 20}}>
                                                    <strong>Connection mode for copy bots</strong> <Switch
                                                    style={{
                                                        width: 100,
                                                        fontSize: 34,
                                                    }}
                                                    checkedChildren="Multiple"
                                                    unCheckedChildren="Single"
                                                    defaultUnChecked
                                                    value={onChangeModeSelection}
                                                    onChange={() => {
                                                        runInAction(() => {
                                                            if (!onChangeModeSelection) {
                                                                mobXStore.setKey('addStrategyID', []);
                                                                setCheckedStrategy([]);
                                                            }
                                                            setOnChangeModeSelection(!onChangeModeSelection)
                                                        });
                                                    }}
                                                />

                                                    {onChangeModeSelection &&
                                                        <>
                                                            <Button
                                                                disabled={checkedStrategy.length === 0}
                                                                type="primary"
                                                                style={{marginLeft: 10}}
                                                                onClick={onClickAddStrategyCryptoMultiple}
                                                            >
                                                                Start Copy bots
                                                                for {checkedStrategy.length} strategies
                                                            </Button>
                                                            <Button style={{marginLeft: 10}}
                                                                    disabled={checkedStrategy.length !== 0}
                                                                    onClick={() => {
                                                                        // Check all strategies with record.market === 'CRYPTO'
                                                                        runInAction(() => {
                                                                            const filtered = dataSource.map((item) => item.market === 'CRYPTO' ? item.id : null).filter((item) => item !== null);
                                                                            setCheckedStrategy(filtered);
                                                                            mobXStore.setKey('addStrategyID', filtered);
                                                                        });
                                                                    }}>
                                                                Select All
                                                            </Button>
                                                            <Button style={{marginLeft: 10}}
                                                                    disabled={checkedStrategy.length === 0}
                                                                    onClick={() => {
                                                                        runInAction(() => {
                                                                            // Uncheck all strategies
                                                                            setCheckedStrategy([]);
                                                                            mobXStore.setKey('addStrategyID', []);
                                                                        });
                                                                    }}>
                                                                Unselect All
                                                            </Button>
                                                        </>
                                                    }
                                                </div>
                                            </>
                                        }
                                    </>
                                )
                            }}
                            style={{
                                marginTop: '1%',
                                marginLeft: '1%',
                                marginRight: '1%',
                            }}
                            bordered
                            onRow={(record) => {
                                return {
                                    // onClick: () => handleEdit(record.key), // click row
                                    // onDoubleClick: () => alert(record.id), // double click row
                                    // onContextMenu: event => {
                                    // }, // right button click row
                                    // onMouseEnter: event => {
                                    // }, // mouse enter row
                                    // onMouseLeave: event => {
                                    // }, // mouse leave row
                                };
                            }}
                        />
                    </Col>
                    <Col span={22}>
                        <EquityStats
                            title={<span style={{
                                fontSize: 22,
                                fontWeight: 600
                            }}><AreaChartOutlined/> Equity curve over&nbsp;&nbsp;
                                <Select
                                    defaultValue={equityStatsPeriod}
                                    style={{
                                        fontWeight: 700,
                                        width: 120
                                    }}
                                    onChange={(value) => {
                                        setEquityStatsPeriod(value);
                                    }}
                                >
                            <Option value={7}>7 days</Option>
                            <Option value={15}>15 days</Option>
                            <Option value={30}>30 days</Option>
                            <Option value={60}>60 days</Option>
                            <Option value={90}>90 days</Option>
                            <Option value={180}>180 days</Option>
                            <Option value={365}>1 year</Option>
                            <Option value={730}>2 years</Option>
                            <Option value={1095}>3 years</Option>
                            <Option value={1825}>5 years</Option>
                            <Option value={3650}>10 years</Option>
                        </Select>
                        </span>}
                            equityCurveData={equityCurve}
                            max_loss_streak_count={maxLossStreakCount}
                            max_drawdown_in_series={maxDrawdownInSeries}
                            max_single_trade_loss={maxSingleTradeLoss}
                            max_open_trades_long={maxOpenTradesLong}
                            max_open_trades_short={maxOpenTradesShort}
                            max_open_trades_both={maxOpenTradesBoth}
                        />
                    </Col>
                </Row>
            </Content>
        </>
    );
}

export default observer(StrategyStats);